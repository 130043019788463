import React from "react";
import {
  ComposedChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Line,
} from "recharts";
export default function Chart({ accumulatedChart }) {
  const formatYAxis = (tickItem) => {
    return `${tickItem} P`;
  };
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="p-4 bg-white border">
          <p className="text-[#e8aa39] mb-2">{`Borrowed: ${payload[0].value} `}</p>
          <p className="text-[#357e37] mb-2">{`Fused: ${payload[1].value}`}</p>
          <p className="text-[#4c7d8c] mb-2">{`Limited: ${payload[2]?.value}`}</p>
          <p className="text-[#4b4a50] mb-2">{`Regular: ${payload[3]?.value}`}</p>
        </div>
      );
    }

    return null;
  };
  return (
    <div className="w-full h-[400px] my-auto">
      <ResponsiveContainer>
        <ComposedChart data={accumulatedChart}>
          <XAxis dataKey="name" />
          <YAxis tickFormatter={formatYAxis} />
          <Tooltip content={<CustomTooltip />} />
          <Area
            type="monotone"
            dataKey="borrowed_heroes"
            stroke="#e8aa39"
            fill="#f6e3b8"
          />
          <Area
            type="monotone"
            dataKey="fused_heroes"
            stroke="#357e37"
            fill="#a9f0ac"
          />
          <Area
            type="monotone"
            dataKey="limited_heroes"
            stroke="#4c7d8c"
            fill="#aae2f9"
          />
          <Line type="monotone" dataKey="regular_heroes" stroke="#4b4a50" />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

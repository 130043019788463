import { useEffect, useState } from "react";
import NFTStatusTable from "./components/NFTStatusTable";
import { getNFTsSales } from "../../api";
import ListBoxSelect from "./components/ListBoxSelect";
import { BiLoaderCircle } from "react-icons/bi";
import moment from "moment";

const nftTypeList = [
  { title: "Nft Type", value: "" },
  { title: "hero", value: 1 },
  { title: "vpl", value: 2 },
];
const statusList = [
  { title: "BORROWED", value: "BORROWED" },
  {
    title: "READY TO SALE BUT NOT LISTED",
    value: "READY_TO_SALE_BUT_NOT_LISTED",
  },
  { title: "SALE IN PROCESS", value: "SALE_IN_PROCESS" },
  { title: "BORROW IN PROCESS", value: "BORROW_IN_PROCESS" },
  { title: "ASSEMBLED", value: "ASSEMBLED" },
  { title: "FUSE IN PROCESS", value: "FUSE_IN_PROCESS" },
  { title: "LOCKED", value: "LOCKED" },
  { title: "READY TO MINTING", value: "READY_TO_MINTING" },
  { title: "READY TO SELL", value: "READY_TO_SELL" },
  { title: "SOLD", value: "SOLD" },
];

export default function NFTStatus() {
  const [from, setFrom] = useState(
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );
  const [to, setTo] = useState(moment().format("YYYY-MM-DD"));
  const [nftType, setNftType] = useState(nftTypeList[0]);
  const [wallet, setWallet] = useState("");
  const [player, setPlayer] = useState("");
  const [nftName, setNftName] = useState("");
  const [page, setPage] = useState(1);
  const [itemPage, setItemPage] = useState(20);
  const [status, setStatus] = useState("");
  const [nftData, setNftData] = useState([]);
  const [nftSalesData, setNftNftData] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    fetchNFTsSales();
  }, [page]);

  const fetchNFTsSales = async () => {
    try {
      let data = await getNFTsSales(
        from,
        to,
        nftType.value,
        status.value ?? "",
        wallet,
        player,
        nftName,
        page,
        itemPage
      );
      const modifiedData = data?.content?.map((item, index) => ({
        ...item,
        id: index,
      }));
      setNftNftData(data);
      setNftData(modifiedData);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePageClick = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
  };

  const filter = async () => {
    setLoader(true);
    await fetchNFTsSales();
    setLoader(false);
  };
  return (
    <>
      <div className="flex flex-col items-center h-full p-6 gap-6">
        <div className="p-6 rounded-[2px] border bg-white w-full">
          <div className="flex items-center gap-5 mt-2">
            <label>Transfer data, from</label>
            <div className="relative w-[120px]">
              <input
                type="text"
                value={from}
                onChange={(e) => setFrom(e.target.value)}
                className="h-[40px] px-3 border w-full outline-0 border-[#E8E7E4] placeholder:text-white text-black"
              />
              <input
                type="date"
                value={from}
                onChange={(e) => setFrom(e.target.value)}
                className="absolute inset-0 opacity-0 z-1"
              />
            </div>
            <label>to</label>
            <div className="relative w-[120px]">
              <input
                type="text"
                value={to}
                onChange={(e) => setTo(e.target.value)}
                className="h-[40px] px-3 border w-full outline-0 border-[#E8E7E4] placeholder:text-white text-black"
              />
              <input
                type="date"
                value={to}
                onChange={(e) => setTo(e.target.value)}
                className="absolute inset-0 opacity-0 z-1"
              />
            </div>
            <div className="w-[140px]">
              <ListBoxSelect
                options={nftTypeList}
                selected={nftType}
                setSelected={setNftType}
                placeholder={"NFT Type"}
              />
            </div>
            <div className="w-[170px]">
              <ListBoxSelect
                options={statusList}
                selected={status}
                setSelected={setStatus}
                placeholder={"Status"}
              />
            </div>
            <div className="w-[140px]">
              <input
                type="text"
                value={wallet}
                onChange={(e) => setWallet(e.target.value)}
                placeholder="Wallet"
                className="h-[40px] px-3 border w-full outline-0 border-[#E8E7E4] placeholder:text-black"
              />
            </div>
            <div className="w-[140px]">
              <input
                type="text"
                value={player}
                onChange={(e) => setPlayer(e.target.value)}
                placeholder="Player"
                className="h-[40px] px-3 border w-full outline-0 border-[#E8E7E4] placeholder:text-black"
              />
            </div>
            <div className="w-[140px]">
              <input
                type="text"
                value={nftName}
                onChange={(e) => setNftName(e.target.value)}
                placeholder="NFT Name"
                className="h-[40px] px-3 border w-full outline-0 border-[#E8E7E4] placeholder:text-black"
              />
            </div>
            {loader ? (
              <button className="h-[40px] px-8 text-[16px] text-white flex items-center justify-center bg-[#0080A6]">
                <BiLoaderCircle
                  size={22}
                  className="animate-[spin_3s_linear_infinite]"
                />
              </button>
            ) : (
              <button
                onClick={filter}
                className="h-[40px] px-8 text-[16px] text-white flex items-center justify-center bg-[#0080A6]"
              >
                Reload
              </button>
            )}
          </div>
        </div>
        <NFTStatusTable
          nftData={nftData}
          handlePageClick={handlePageClick}
          pagination={nftSalesData?.pagination}
          loader={loader}
        />
      </div>
    </>
  );
}

import { useState, useEffect } from "react";
// import AccountsTable from "../../components/AccountsTable";
import SalesChart from "./components/SalesChart";
import WorkersChart from "./components/WorkersChart";
import UsersChart from "./components/UsersChart";
import AccountsChart from "./components/AccountsChart";
import moment from "moment";
import { BiLoaderCircle } from "react-icons/bi";
import {
  getDailyAccounts,
  getMonthlyAccounts,
  getSalesIncome,
  getNewAccounts,
  getKPIsPeriod,
  // getAccountsTable,
  getWorkersIncome,
  getWorkersSummary,
} from "../../api";

export default function Overview() {
  const [from, setFrom] = useState(
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );
  const [to, setTo] = useState(moment().format("YYYY-MM-DD"));
  const [dailyAccounts, setDailyAccounts] = useState([]);
  const [monthlyAccounts, setMonthlyAccounts] = useState([]);
  const [salesIncome, setSalesIncome] = useState([]);
  const [workersIncome, setWorkersIncome] = useState([]);
  const [workersSummary, setWorkersSummary] = useState([]);
  const [salesProduct, setSalesProduct] = useState([]);
  const [summaryPie, setSummaryPie] = useState([]);
  const [newAccounts, setnewAccounts] = useState([]);
  const [kpisPeriod, setKPIsPeriod] = useState([]);
  // const [accountsTable, setAccountsTable] = useState([]);
  const [kpiAccount, setKpiAccount] = useState({});
  const [loader, setLoader] = useState(false);
  const [filterLoader, setFilterLoader] = useState(false);

  const [activeMatics,setActiveMatics] = useState(false);
  const [activeSogas,setActiveSogas] = useState(false);

  const [activeWorkerMatics,setActiveWorkerMatics] = useState(true);
  const [activeWorkerSogas,setActiveWorkerSogas] = useState(true);
  const [activeCoin,setCoin] = useState(true);

  const [directSales,setDirectSales] = useState(false);
  const [rentals,setRentals] = useState(false);
  const [marketplace,setMarketplace] = useState(false);
  // const [activeKols, setActiveKols] = useState(true);
  useEffect(() => {
    //fetchNewAccounts(directSales,rentals,marketplace);
    //fetchKPIsPeriod(directSales,rentals,marketplace);
    fetchDailyAccounts();
    fetchMonthlyAccounts();
    //fetchSalesIncome(activeMatics,activeSogas);
    // fetchAccountsTable();
    //fetchWorkersIncome();
    //fetchWorkersSummary();
  }, []);

  const fetchDailyAccounts = async () => {
    try {
      let data = await getDailyAccounts(from, to);
      data = data.map((product) => {
        return {
          ...product,
          day: moment(product.day).format("MM/DD"),
          dayName: moment(product.day).format("dddd"),
          "Old active users":
            Number(product.daily_active_users_count) -
            Number(product.daily_playing_users_count),
          "New active users": Number(product.daily_playing_users_count),
        };
      });
      setDailyAccounts(data);
    } catch (error) {
      console.error(error);
    }
  };

  // const fetchAccountsTable = async () => {
  //   try {
  //     let data = await getAccountsTable(from, to, activeKols);
  //     setAccountsTable(data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const fetchMonthlyAccounts = async () => {
    try {
      let data = await getMonthlyAccounts(from, to);
      const maxNumber = Math.max(...data.map((obj) => obj.total_month_count));

      data = data.map((product) => {
        return {
          ...product,
          max:
            Number(product.current_day_count) +
            Number(product.rest_of_month_count),
          max_number: maxNumber,
        };
      });
      setMonthlyAccounts(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSalesIncome = async (matics = false, sogas = false) => {
    setActiveMatics(matics)
    setActiveSogas(sogas)
    try {
      let data = await getSalesIncome(from, to, matics, sogas);

      data = data.map((product) => {
        return {
          ...product,
          day: moment(product.day).format("MM/DD"),
          dayName: moment(product.day).format("dddd"),
          heroes_income: roundToTwoDecimalPlaces(product.heroes_income),
          heroes_amount: roundToTwoDecimalPlaces(product.heroes_amount),
          vpls_income: roundToTwoDecimalPlaces(product.vpls_income),
          vpls_amount: roundToTwoDecimalPlaces(product.vpls_amount),
          supermarket_income: roundToTwoDecimalPlaces(product.supermarket_income),
          supermarket_amount: roundToTwoDecimalPlaces(product.supermarket_amount),
          marketplace_commission_amount: roundToTwoDecimalPlaces(product.marketplace_commission_amount),
          marketplace_commission_income: roundToTwoDecimalPlaces(product.marketplace_commission_income),
          rental_commission_amount: roundToTwoDecimalPlaces(product.rental_commission_amount),
          retnal_commission_income: roundToTwoDecimalPlaces(product.retnal_commission_income),
        };
      });
      setSalesIncome(data);
      const product = data.reduce(
        (accumulator, item) => {
          accumulator.heroes_income += Number(item.heroes_income);
          accumulator.heroes_amount += Number(item.heroes_amount);
          accumulator.vpls_income += Number(item.vpls_income);
          accumulator.vpls_amount += Number(item.vpls_amount);
          accumulator.supermarket_income += Number(item.supermarket_income);
          accumulator.supermarket_amount += Number(item.supermarket_amount);
          accumulator.marketplace_commission_amount += Number(item.marketplace_commission_amount);
          accumulator.marketplace_commission_income += Number(item.marketplace_commission_income);
          accumulator.rental_commission_amount += Number(item.rental_commission_amount);
          accumulator.retnal_commission_income += Number(item.retnal_commission_income);
          return accumulator;
        },
        {
          heroes_income: 0,
          heroes_amount: 0,
          vpls_income: 0,
          vpls_amount: 0,
          supermarket_amount: 0,
          supermarket_income: 0,
          marketplace_commission_amount:0,
          marketplace_commission_income:0,
          rental_commission_amount:0,
          retnal_commission_income:0,
          items: 0,
        }
      );
      
      const PieData = [
        {
          name: "Heroes",
          income: roundToTwoDecimalPlaces(product.heroes_income),
          amount: roundToTwoDecimalPlaces(product.heroes_amount),
        },
        {
          name: "VPLs",
          income: roundToTwoDecimalPlaces(product.vpls_income),
          amount: roundToTwoDecimalPlaces(product.vpls_amount),
        },
        {
          name: "Supermarket",
          income: roundToTwoDecimalPlaces(product.supermarket_income),
          amount: roundToTwoDecimalPlaces(product.supermarket_amount),
        },
        {
          name: "Marketplace",
          income: roundToTwoDecimalPlaces(product.marketplace_commission_income),
          amount: roundToTwoDecimalPlaces(product.marketplace_commission_amount),
        },
        {
          name: "Retnal",
          income: roundToTwoDecimalPlaces(product.retnal_commission_income),
          amount: roundToTwoDecimalPlaces(product.rental_commission_amount),
        },
      ];
      setSalesProduct(PieData);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchWorkersIncome = async (isMatics,isSogas,isCoin) => {
    setActiveWorkerMatics(isMatics);
    setActiveWorkerSogas(isSogas);
    setCoin(isCoin);
    try {
      let data = await getWorkersIncome(from, to,isMatics,isSogas,isCoin);
      data = data.map((product) => {
        return {
          ...product,
          day: moment(product.day).format("MM/DD"),
          dayName: moment(product.day).format("dddd"),
          builder_amount: roundToTwoDecimalPlaces(product.builder_amount),
          builder_income: roundToTwoDecimalPlaces(product.builder_income),
          engineering_amount: roundToTwoDecimalPlaces(
            product.engineering_amount
          ),
          engineering_income: roundToTwoDecimalPlaces(
            product.engineering_income
          ),
          gym_amount: roundToTwoDecimalPlaces(product.gym_amount),
          gym_income: roundToTwoDecimalPlaces(product.gym_income),
          officer_amount: roundToTwoDecimalPlaces(product.officer_amount),
          officer_income: roundToTwoDecimalPlaces(product.officer_income),
          sales_amount: roundToTwoDecimalPlaces(product.sales_amount),
          sales_income: roundToTwoDecimalPlaces(product.sales_income),
          marketplace_income: roundToTwoDecimalPlaces(product.marketplace_income),
          marketplace_amount: roundToTwoDecimalPlaces(product.marketplace_amount),
          rental_pvp_income: roundToTwoDecimalPlaces(product.rental_pvp_income),
          rental_pvp_amount: roundToTwoDecimalPlaces(product.rental_pvp_amount),
        };
      });
     
      const product = data.reduce(
        (accumulator, item) => {
          accumulator.builder_income += Number(item.builder_income);
          accumulator.builder_amount += Number(item.builder_amount);
          accumulator.sales_income += Number(item.sales_income);
          accumulator.sales_amount += Number(item.sales_amount);
          accumulator.gym_income += Number(item.gym_income);
          accumulator.gym_amount += Number(item.gym_amount);
          accumulator.engineering_income += Number(item.engineering_income);
          accumulator.engineering_amount += Number(item.engineering_amount);
          accumulator.officer_income += Number(item.officer_income);
          accumulator.officer_amount += Number(item.officer_amount);
          accumulator.marketplace_income += Number(item.marketplace_income);
          accumulator.marketplace_amount += Number(item.marketplace_amount);
          accumulator.rental_pvp_income += Number(item.rental_pvp_amount);
          accumulator.rental_pvp_amount += Number(item.rental_pvp_income);
          return accumulator;
        },
        {
          builder_income: 0,
          builder_amount: 0,
          sales_income: 0,
          sales_amount: 0,
          gym_amount: 0,
          gym_income: 0,
          engineering_income: 0,
          engineering_amount: 0,
          officer_income: 0,
          officer_amount: 0,
          marketplace_income: 0,
          marketplace_amount: 0,
          rental_pvp_income: 0,
          rental_pvp_amount: 0,
        }
      );

      console.log('asd',product)
      const PieData = [
        {
          name: "Builder",
          income: roundToTwoDecimalPlaces(product?.builder_income),
          amount: roundToTwoDecimalPlaces(product?.builder_amount),
        },
        {
          name: "Sales",
          income: roundToTwoDecimalPlaces(product?.sales_income),
          amount: roundToTwoDecimalPlaces(product?.sales_amount),
        },
        {
          name: "Gym",
          income: roundToTwoDecimalPlaces(product?.gym_income),
          amount: roundToTwoDecimalPlaces(product?.gym_amount),
        },
        {
          name: "Engineering",
          income: roundToTwoDecimalPlaces(product?.engineering_income),
          amount: roundToTwoDecimalPlaces(product?.engineering_amount),
        },
        {
          name: "Officer",
          income: roundToTwoDecimalPlaces(product?.officer_income),
          amount: roundToTwoDecimalPlaces(product?.officer_amount),
        },
        {
          name: "Marketplace",
          income: roundToTwoDecimalPlaces(product?.marketplace_income),
          amount: roundToTwoDecimalPlaces(product?.marketplace_amount),
        },
        {
          name: "Rental",
          income: roundToTwoDecimalPlaces(product?.rental_pvp_income),
          amount: roundToTwoDecimalPlaces(product?.rental_pvp_amount),
        },
      ];
      setWorkersIncome(data);
      setSummaryPie(PieData);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchWorkersSummary = async (isMatics,isSogas,isCoin) => {
    setActiveWorkerMatics(isMatics);
    setActiveWorkerSogas(isSogas);
    setCoin(isCoin);
    try {
      let data = await getWorkersSummary(from, to,isMatics,isSogas,isCoin);
      setWorkersSummary(data);
    } catch (error) {
      console.error(error);
    }
  };

  const roundToTwoDecimalPlaces = (number) => {
    if (typeof number === "number" && !Number.isNaN(number) && number !== 0) {
      return parseFloat(number.toFixed(2));
    }
    return number;
  };

  const fetchNewAccounts = async (directSales = false,rentals  = false,marketplace  = false) => {
    setFilterLoader(true);
    setDirectSales(directSales)
    setRentals(rentals)
    setMarketplace(marketplace)
    try {
      let data = await getNewAccounts(from, to, directSales, rentals, marketplace);
      const kpiAccount = data.reduce(
        (accumulator, item) => {
          accumulator.total_paying_accounts += parseInt(
            item["Total paying accounts"]
          );
          accumulator.total_new_paying_accounts += parseInt(
            item["New paying accounts"]
          );
          accumulator.total_new_accounts += parseInt(item["New Accounts"]);
          return accumulator;
        },
        {
          total_paying_accounts: 0,
          total_new_paying_accounts: 0,
          total_new_accounts: 0,
        }
      );
      setKpiAccount(kpiAccount);
      setnewAccounts(data);
      setFilterLoader(false);
    } catch (error) {
      console.error(error);
      setFilterLoader(false);
    }
  };

  const fetchKPIsPeriod = async (directSales,rentals,marketplace) => {
    try {
      let data = await getKPIsPeriod(from, to, directSales, rentals, marketplace);
      setKPIsPeriod(data);
    } catch (error) {
      console.error(error);
    }
  };

  const filter = async () => {
    setLoader(true);
    // await fetchAccountsTable();
    await fetchNewAccounts(directSales,rentals,marketplace);
    await fetchKPIsPeriod(directSales,rentals,marketplace);
    await fetchDailyAccounts();
    await fetchMonthlyAccounts();
    await fetchSalesIncome(activeMatics,activeSogas);
    await fetchWorkersIncome(activeWorkerMatics,activeWorkerSogas,activeCoin);
    await fetchWorkersSummary(activeWorkerMatics,activeWorkerSogas,activeCoin);
    setLoader(false);
  };

  return (
    <div className="flex flex-col items-center h-full p-6">
      <div className="p-6 rounded-[2px] border bg-white mb-6 w-full">
        <p className="text-[16px] font-medium text-black">
          Select the date and reload the dashboard
        </p>
        <p className="text-[16px] font-medium text-black">
          [Current month]{" "}
          <span className="text-[#5468DB]">
            [Same period int the last month] [last 30 days] [last 60 days] [last
            90 days]
          </span>
        </p>
        <div className="flex items-center gap-5 mt-2">
          <label>From date</label>
          <div className="relative">
            <input
              type="text"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              className="h-[40px] px-3 border border-[#E8E7E4] placeholder:text-white"
            />
            <input
              type="date"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              className="absolute inset-0 opacity-0 z-1"
            />
          </div>
          <label>to date</label>
          <div className="relative">
            <input
              type="text"
              value={to}
              onChange={(e) => setTo(e.target.value)}
              className="h-[40px] px-3 border border-[#E8E7E4] placeholder:text-white"
            />
            <input
              type="date"
              value={to}
              onChange={(e) => setTo(e.target.value)}
              className="absolute inset-0 opacity-0 z-1"
            />
          </div>
          {/* <div className="h-[40px] px-3 border border-[#E8E7E4] flex items-center">
            <label
              htmlFor="active"
              className="cursor-pointer flex items-center text-[15px] font-normal"
            >
              <input
                id="active"
                type="checkbox"
                className="hidden peer"
                value={activeKols}
                checked={activeKols}
                onChange={() => setActiveKols(!activeKols)}
              />
              <span className="mr-3 peer-checked:bg-[#0080A6] peer-checked:border-[#0080A6] bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5.55px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border-[1.5px] border-[#ccc] rounded-sm"></span>
              Only active KOLs
            </label>
          </div> */}
          <button
            onClick={filter}
            className="h-[40px] px-8 text-[16px] text-white flex items-center justify-center bg-[#0080A6]"
          >
            {loader || filterLoader ? (
              <BiLoaderCircle
                size={22}
                className="animate-[spin_3s_linear_infinite]"
              />
            ) : (
              "Reload"
            )}
          </button>
        </div>
      </div>
      {/* <AccountsTable accountsTable={accountsTable} /> */}
      <AccountsChart
        fetchNewAccounts= {fetchNewAccounts}
        fetchKPIsPeriod= {fetchKPIsPeriod}
        newAccounts={newAccounts}
        kpiAccount={kpiAccount}
        kpisPeriod={kpisPeriod}
      />
      <UsersChart
        dailyAccounts={dailyAccounts}
        monthlyAccounts={monthlyAccounts}
      />
      <WorkersChart
        fetchWorkersIncome={fetchWorkersIncome}
        fetchWorkersSummary={fetchWorkersSummary}
        workersIncome={workersIncome}
        workersSummary={workersSummary}
        summaryPie={summaryPie}
      />
      <SalesChart fetchSalesIncome={fetchSalesIncome} salesIncome={salesIncome} salesProduct={salesProduct} />
    </div>
  );
}

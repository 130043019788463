import React from "react";

export default function ProductRank({ countryRank }) {
  const roundToTwoDecimalPlaces = (input) => {
    const number = parseFloat(input);

    if (typeof number === "number" && !Number.isNaN(number) && number !== 0) {
      const roundedNumber = parseFloat(number.toFixed(0));
      return roundedNumber.toLocaleString();
    }

    if (isNaN(number)) {
      return 0;
    }

    return input;
  };
  const prices = countryRank.map((item) => item.price);
  const largestPrice = Math.max(...prices);
  return (
    <div className="flex flex-col gap-1">
      {countryRank?.map((item, key) => (
        <div className="flex items-center mb-2" key={key}>
          <div className="w-6/12 shrink-0">
            <div className="flex gap-3 pr-2 break-word">
              <img
                className="w-[22px]"
                src={item.country_flag}
                alt={item.country_title}
              />
              {item.country_title}
            </div>
          </div>
          <div className="w-6/12 pl-3 relative shrink-0">
            <div className="flex items-center ">
              <div className="absolute left-5">
                ${roundToTwoDecimalPlaces(item.price)}
              </div>
              <div
                className="h-[20px] bg-[#FFBB44]"
                style={{ width: `${(item.price / largestPrice) * 100}%` }}
              ></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

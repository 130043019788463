import { useEffect, useState } from "react";
import TokenomicsTable from "./components/TokenomicsTable";
import { getMission,getPlateformItems,getChallengesMission } from "../../api";

export default function KolManager() {
  const [page, setPage] = useState(1);
  const [itemPage, setItemPage] = useState(20);
  const [tokenomics, setTokenomics] = useState("");
  const [loader, setLoader] = useState(false);
  const [platformItems, setPlatformItems] = useState([]);
  const [challengesMission, setChallengesMission] = useState([]);

  useEffect(() => {
    fetchMissionList();
  }, [page]);

  useEffect(() => {
    fetchPlatformItems();
    fetchChallengesMission();
  }, []);

  const fetchPlatformItems = async () => {
    try {
      const items = await getPlateformItems();
      setPlatformItems(items.content);
    } catch (error) {
      console.error('Failed to fetch platform items:', error);
    }
  };

  const fetchChallengesMission = async () => {
    try {
      const items = await getChallengesMission();
      setChallengesMission(items.content);
    } catch (error) {
      console.error('Failed to fetch platform items:', error);
    }
  };



  const handlePageClick = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
  };

  const fetchMissionList = async () => {
    setLoader(true);
    try {
      let data = await getMission(page, itemPage);
      console.log('test',data);
      setTokenomics(data);
    } catch (error) {
      console.error(error);
    }
    setLoader(false);
  };

  return (
    <div className="flex flex-col items-center h-full p-6 gap-6">
      <TokenomicsTable
        tokenomics={tokenomics?.content}
        pagination={tokenomics?.pagination}
        handlePageClick={handlePageClick}
        loader={loader}
        platformItems={platformItems}
        challengesMission={challengesMission}
        fetchMissionList={fetchMissionList}
      />
    </div>
  );
}

import { useState } from "react";
import { NavLink } from "react-router-dom";
import SocialLogo from "../../assets/images/logo.png";
import LogoSmall from "../../assets/images/logo_small.png";
import { FiMenu } from "react-icons/fi";
import { AiOutlineDashboard } from "react-icons/ai";
import { SiGoogletagmanager } from "react-icons/si";
import { CiStreamOn } from "react-icons/ci";
import { HiOutlineChevronDown } from "react-icons/hi";
export default function Sidebar({ mobile, sidebar, mobileToggler }) {
  const [active, setActive] = useState(0);
  const localStorageUSerObj = JSON.parse(localStorage.getItem("userData"));

  const setAccordian = (e) => {
    sidebar === false
      ? e === active
        ? setActive(0)
        : setActive(e)
      : setActive(0);
  };
  return (
    <>
      <div
        className={`sidebar-overlay fixed left-0 right-0 bottom-0 top-[50px] bg-[#000000]/20 z-0 md:hidden ${
          !mobile && "hidden"
        }`}
      ></div>
      <div
        className={`${sidebar && "!w-[70px]"} ${
          mobile && "left-0 h-screen"
        } fixed w-[250px] bg-white border-r border-[#e7eaee] transition-all duration-300 block md:left-0 -left-[250px] top-0 bottom-0 z-[1] shrink-0`}
      >
        <div className="md:pt-3 flex flex-col h-full relative">
          <div className="flex items-center px-3 overflow-hidden">
            <div className="flex items-center justify-center grow h-[45px]">
              <img
                src={SocialLogo}
                alt="Social Games Logo"
                className={`w-[200px] opacity-80 mx-auto mt-3 transition-all duration-300 ${
                  sidebar ? "hidden" : "block"
                }`}
              />
              <img
                src={LogoSmall}
                alt="Social Games Logo"
                className={`w-[35px] opacity-80 mx-auto transition-all duration-300 mt-3 ${
                  sidebar ? "block" : "hidden"
                }`}
              />
            </div>
            <div
              onClick={mobileToggler}
              className="cursor-pointer md:hidden block"
            >
              <FiMenu size={22} className="fill-[#8390a0]" />
            </div>
          </div>
          <div className="pt-4 grow px-3">
            <div
              className={`${
                sidebar && "[&_span]:w-0 [&_span]:invisible [&_span]:opacity-0"
              }`}
            >
              {(localStorageUSerObj?.admin_permission_bin_code & 256) ===
                256 && (
                <div className={`${sidebar && "group"} relative`}>
                  <div className="relative hover:bg-[#e7eaee] rounded-[6px]">
                    <NavLink
                      to="/dashboard"
                      className={({ isActive, isPending }) =>
                        `${
                          isPending
                            ? "pending"
                            : isActive
                            ? "bg-[#e7eaee] !text-black rounded-[6px]"
                            : ""
                        }  flex items-stretch transition duration-300 hover:text-black `
                      }
                    >
                      <button
                        className={`flex items-center text-[16px] p-2 hover:bg-[#e7eaee] rounded-[6px] w-full transition duration-300 visible opacity-1 overflow-hidden`}
                        type="button"
                        onClick={() => setAccordian(2)}
                      >
                        <AiOutlineDashboard
                          size={sidebar ? 24 : 20}
                          className={`shrink-0 opacity-50 ${
                            !sidebar && "mr-4"
                          }`}
                        />
                        <span className="transition-all duratioan-300">
                          Dashboard
                        </span>
                      </button>
                    </NavLink>
                  </div>
                </div>
              )}
              {(localStorageUSerObj?.admin_permission_bin_code & 256) ===
                256 && (
                <div className={`${sidebar && "group"} relative`}>
                  <div className="relative hover:bg-[#e7eaee] rounded-[6px]">
                    <NavLink
                      to="/mission-manager"
                      className={({ isActive, isPending }) =>
                        `${
                          isPending
                            ? "pending"
                            : isActive
                            ? "bg-[#e7eaee] !text-black rounded-[6px]"
                            : ""
                        }  flex items-stretch transition duration-300 hover:text-black `
                      }
                    >
                      <button
                        className={`flex items-center text-[16px] p-2 hover:bg-[#e7eaee] rounded-[6px] w-full transition duration-300 visible opacity-1 overflow-hidden`}
                        type="button"
                        onClick={() => setAccordian(8)}
                      >
                        <AiOutlineDashboard
                          size={sidebar ? 24 : 20}
                          className={`shrink-0 opacity-50 ${
                            !sidebar && "mr-4"
                          }`}
                        />
                        <span className="transition-all duratioan-300">
                          Mission manager
                        </span>
                      </button>
                    </NavLink>
                  </div>
                </div>
              )}
              <div className="relative">
                {(localStorageUSerObj?.admin_permission_bin_code & 1) === 1 && (
                  <NavLink
                    to="/streamer"
                    className={({ isActive, isPending }) =>
                      `${
                        isPending
                          ? "pending"
                          : isActive
                          ? "bg-[#e7eaee] !text-black"
                          : ""
                      } hover:bg-[#e7eaee] rounded-[6px] block transition duration-300 hover:text-black`
                    }
                  >
                    <button
                      className={`flex items-center text-[16px] p-2 hover:bg-[#e7eaee] rounded-[6px] w-full transition duration-300 visible opacity-1 overflow-hidden`}
                      type="button"
                      onClick={() => setAccordian(3)}
                    >
                      <CiStreamOn
                        size={sidebar ? 24 : 20}
                        className={`shrink-0 opacity-50 ${!sidebar && "mr-4"}`}
                      />
                      <span className="transition-all duratioan-300">
                        KOL stats
                      </span>
                    </button>
                  </NavLink>
                )}

                {(localStorageUSerObj?.admin_permission_bin_code & 256) ===
                  256 && (
                  <>
                    <div className={`${sidebar && "group"} relative`}>
                      <div className="relative">
                        <button
                          className={`flex items-center relative text-[16px] p-2 hover:bg-[#e7eaee] rounded-[6px] w-full transition duration-300 visible opacity-1 overflow-hidden`}
                          type="button"
                          onClick={() => setAccordian(4)}
                        >
                          <SiGoogletagmanager
                            size={sidebar ? 22 : 18}
                            className={`shrink-0 opacity-50 ${
                              !sidebar && "mr-4"
                            }`}
                          />
                          <span className="transition-all duratioan-300">
                            KOL Manager
                          </span>
                          <span
                            className="flex w-5/12 items-center pr-2 cursor-pointer absolute right-0 top-0 h-full"
                            onClick={() => setAccordian(2)}
                          >
                            <HiOutlineChevronDown
                              size={18}
                              className="ml-auto "
                            />
                          </span>
                        </button>
                        <div
                          className={`${active === 4 ? "h-[80px]" : "h-0 "} ${
                            sidebar && "!h-full"
                          } transition-all duration-300 `}
                        >
                          <div
                            className={` ${
                              active !== 4 && " hidden"
                            } group-hover:absolute group-hover:[&_span]:!block left-full top-0 group-hover:!block group-hover:!visible group-hover:bg-white group-hover:rounded-[6px] group-hover:shadow group-hover:min-w-[200px]`}
                          >
                            <div className="pl-[36px] pt-0 group-hover:!p-4">
                              <ul>
                                <li>
                                  <NavLink
                                    to="/kol-manager"
                                    onClick={() =>
                                      active !== 4 && setAccordian(4)
                                    }
                                    className={({ isActive, isPending }) =>
                                      `${
                                        isPending
                                          ? "pending"
                                          : isActive
                                          ? "bg-[#e7eaee] !text-black"
                                          : ""
                                      } text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] block transition duration-300 text-[#8390A0] hover:text-black`
                                    }
                                  >
                                    Accounts
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink
                                    to="/kols-stats"
                                    onClick={() =>
                                      active !== 4 && setAccordian(4)
                                    }
                                    className={({ isActive, isPending }) =>
                                      `${
                                        isPending
                                          ? "pending"
                                          : isActive
                                          ? "bg-[#e7eaee] !text-black"
                                          : ""
                                      } text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] block transition duration-300 text-[#8390A0] hover:text-black`
                                    }
                                  >
                                    Stats
                                  </NavLink>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${sidebar && "group"} relative`}>
                      <div className="relative hover:bg-[#e7eaee] rounded-[6px]">
                        <NavLink>
                          <button
                            className={`flex items-center text-[16px] p-2 hover:bg-[#e7eaee] rounded-[6px] w-full transition duration-300 visible opacity-1 overflow-hidden`}
                            type="button"
                            onClick={() => setAccordian(5)}
                          >
                            <AiOutlineDashboard
                              size={sidebar ? 24 : 20}
                              className={`shrink-0 opacity-50 ${
                                !sidebar && "mr-4"
                              }`}
                            />
                            <span className="transition-all duratioan-300">
                              Tokenomics
                            </span>
                          </button>
                        </NavLink>
                        <span
                          className="flex w-5/12 items-center pr-2 cursor-pointer absolute right-0 top-0 h-full"
                          onClick={() => setAccordian(5)}
                        >
                          <HiOutlineChevronDown
                            size={18}
                            className="ml-auto "
                          />
                        </span>
                      </div>
                      <div
                        className={`${active === 5 ? "h-[240px]" : "h-0 "} ${
                          sidebar && "!h-full"
                        } transition-all duration-300`}
                      >
                        <div
                          className={` ${
                            active !== 5 && " hidden"
                          } group-hover:absolute group-hover:[&_span]:!block left-full top-0 group-hover:!block group-hover:!visible group-hover:bg-white group-hover:rounded-[6px] group-hover:shadow group-hover:min-w-[200px]`}
                        >
                          <div className="pl-[36px] pt-0 group-hover:!p-4">
                            <ul>
                              <li>
                                <NavLink
                                  to="/tokenomics"
                                  onClick={() =>
                                    active !== 5 && setAccordian(5)
                                  }
                                  className={({ isActive, isPending }) =>
                                    `${
                                      isPending
                                        ? "pending"
                                        : isActive
                                        ? "bg-[#e7eaee] !text-black"
                                        : ""
                                    } text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] block transition duration-300 text-[#8390A0] hover:text-black`
                                  }
                                >
                                  Nft Sales log
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/nft-status"
                                  onClick={() =>
                                    active !== 5 && setAccordian(5)
                                  }
                                  className={({ isActive, isPending }) =>
                                    `${
                                      isPending
                                        ? "pending"
                                        : isActive
                                        ? "bg-[#e7eaee] !text-black"
                                        : ""
                                    } text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] block transition duration-300 text-[#8390A0] hover:text-black`
                                  }
                                >
                                  NFTs Status
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/player-progress"
                                  onClick={() =>
                                    active !== 5 && setAccordian(5)
                                  }
                                  className={({ isActive, isPending }) =>
                                    `${
                                      isPending
                                        ? "pending"
                                        : isActive
                                        ? "bg-[#e7eaee] !text-black"
                                        : ""
                                    } text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] block transition duration-300 text-[#8390A0] hover:text-black`
                                  }
                                >
                                  Player Progress
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/circulant-supply"
                                  onClick={() =>
                                    active !== 5 && setAccordian(5)
                                  }
                                  className={({ isActive, isPending }) =>
                                    `${
                                      isPending
                                        ? "pending"
                                        : isActive
                                        ? "bg-[#e7eaee] !text-black"
                                        : ""
                                    } text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] block transition duration-300 text-[#8390A0] hover:text-black`
                                  }
                                >
                                  Circulant Supply
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/tokenomics-sales"
                                  onClick={() =>
                                    active !== 5 && setAccordian(5)
                                  }
                                  className={({ isActive, isPending }) =>
                                    `${
                                      isPending
                                        ? "pending"
                                        : isActive
                                        ? "bg-[#e7eaee] !text-black"
                                        : ""
                                    } text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] block transition duration-300 text-[#8390A0] hover:text-black`
                                  }
                                >
                                  Sales
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/marketplace"
                                  onClick={() =>
                                    active !== 5 && setAccordian(5)
                                  }
                                  className={({ isActive, isPending }) =>
                                    `${
                                      isPending
                                        ? "pending"
                                        : isActive
                                        ? "bg-[#e7eaee] !text-black"
                                        : ""
                                    } text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] block transition duration-300 text-[#8390A0] hover:text-black`
                                  }
                                >
                                  Marketplace
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${sidebar && "group"} relative`}>
                      <div className="relative hover:bg-[#e7eaee] rounded-[6px]">
                        <NavLink>
                          <button
                            className={`flex items-center text-[16px] p-2 hover:bg-[#e7eaee] rounded-[6px] w-full transition duration-300 visible opacity-1 overflow-hidden`}
                            type="button"
                            onClick={() => setAccordian(6)}
                          >
                            <AiOutlineDashboard
                              size={sidebar ? 24 : 20}
                              className={`shrink-0 opacity-50 ${
                                !sidebar && "mr-4"
                              }`}
                            />
                            <span className="transition-all duratioan-300">
                              Tournament Manager
                            </span>
                          </button>
                        </NavLink>
                        <span
                          className="flex w-5/12 items-center pr-2 cursor-pointer absolute right-0 top-0 h-full"
                          onClick={() => setAccordian(6)}
                        >
                          <HiOutlineChevronDown
                            size={18}
                            className="ml-auto "
                          />
                        </span>
                      </div>
                      <div
                        className={`${active === 6 ? "h-[40px]" : "h-0 "} ${
                          sidebar && "!h-full"
                        } transition-all duration-300`}
                      >
                        <div
                          className={` ${
                            active !== 6 && " hidden"
                          } group-hover:absolute group-hover:[&_span]:!block left-full top-0 group-hover:!block group-hover:!visible group-hover:bg-white group-hover:rounded-[6px] group-hover:shadow group-hover:min-w-[200px]`}
                        >
                          <div className="pl-[36px] pt-0 group-hover:!p-4">
                            <ul>
                              <li>
                                <NavLink
                                  to="/manager"
                                  onClick={() =>
                                    active !== 6 && setAccordian(6)
                                  }
                                  className={({ isActive, isPending }) =>
                                    `${
                                      isPending
                                        ? "pending"
                                        : isActive
                                        ? "bg-[#e7eaee] !text-black"
                                        : ""
                                    } text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] block transition duration-300 text-[#8390A0] hover:text-black`
                                  }
                                >
                                  Manager
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${sidebar && "group"} relative`}>
                      <div className="relative hover:bg-[#e7eaee] rounded-[6px]">
                        <NavLink>
                          <button
                            className={`flex items-center text-[16px] p-2 hover:bg-[#e7eaee] rounded-[6px] w-full transition duration-300 visible opacity-1 overflow-hidden`}
                            type="button"
                            onClick={() => setAccordian(7)}
                          >
                            <AiOutlineDashboard
                              size={sidebar ? 24 : 20}
                              className={`shrink-0 opacity-50 ${
                                !sidebar && "mr-4"
                              }`}
                            />
                            <span className="transition-all duratioan-300">
                              Rental
                            </span>
                          </button>
                        </NavLink>
                        <span
                          className="flex w-5/12 items-center pr-2 cursor-pointer absolute right-0 top-0 h-full"
                          onClick={() => setAccordian(7)}
                        >
                          <HiOutlineChevronDown
                            size={18}
                            className="ml-auto "
                          />
                        </span>
                      </div>
                      <div
                        className={`${active === 7 ? "h-[40px]" : "h-0 "} ${
                          sidebar && "!h-full"
                        } transition-all duration-300`}
                      >
                        <div
                          className={` ${
                            active !== 7 && " hidden"
                          } group-hover:absolute group-hover:[&_span]:!block left-full top-0 group-hover:!block group-hover:!visible group-hover:bg-white group-hover:rounded-[6px] group-hover:shadow group-hover:min-w-[200px]`}
                        >
                          <div className="pl-[36px] pt-0 group-hover:!p-4">
                            <ul>
                              <li>
                                <NavLink
                                  to="/rental-heroes"
                                  onClick={() =>
                                    active !== 7 && setAccordian(7)
                                  }
                                  className={({ isActive, isPending }) =>
                                    `${
                                      isPending
                                        ? "pending"
                                        : isActive
                                        ? "bg-[#e7eaee] !text-black"
                                        : ""
                                    } text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] block transition duration-300 text-[#8390A0] hover:text-black`
                                  }
                                >
                                  Heroes
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

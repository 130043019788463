import { Dialog, Transition } from "@headlessui/react";
import { cancelTournament } from "api";
import { Fragment, forwardRef, useImperativeHandle, useState } from "react";

const CancelPopup = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    openModal: openModal,
  }));
  let [isOpen, setIsOpen] = useState(false);
  let [id, setId] = useState("");

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = (tId) => {
    setIsOpen(true);
    setId(tId);
  };

  const stopTournament = async () => {
    try {
      let data = await cancelTournament(id);
      console.log(data);
      if (data?.result?.status === "1") {
        closeModal();
        props.setCreate(false);
        props.fetchTournamentList();
        props.setStatus({ success: true, msg: "Success" });
      }
      if (data?.result?.err_description) {
        props.setStatus({ success: false, msg: data.result.err_description });
        closeModal();
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Are you sure you want to cancel the tournament
                </Dialog.Title>

                <div className="mt-4 flex gap-3 justify-center">
                  <button
                    onClick={closeModal}
                    className="h-[40px] disabled:opacity-70 px-8 text-[16px] text-black flex items-center justify-center bg-gray-300"
                  >
                    No
                  </button>
                  <button
                    onClick={stopTournament}
                    className="h-[40px] disabled:cursor-not-allowed disabled:opacity-70 px-8 text-[16px] text-white flex items-center justify-center bg-red-500"
                  >
                    Yes
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
});

export default CancelPopup;

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function Income({ salesIncome, income }) {
  const CustomTooltip = ({ active, payload, label }) => {
    
    if (active && payload && payload.length) {
      return (
        <div className="p-4 bg-white border">
          <p className="mb-2">
            {label} {payload[0].payload.dayName}
          </p>
          <p className="text-[#5468DB] mb-2">{`Heroes Income: ${payload[0].value} `}</p>
          <p className="text-[#4BC18F] mb-2">{`Vpls Income: ${payload[1].value} `}</p>
          <p className="text-[#FFBB44] mb-2">{`Supermarket Income: ${payload[2]?.value}`}</p>
          <p className="text-[#ff7400] mb-2">{`Marketplace Income: ${payload[3]?.value}`}</p>
          <p className="text-[#b501ef] mb-2">{`Rental Income: ${payload[4]?.value}`}</p>
        </div>
      );
    }

    return null;
  };
  return (
    <div className="h-[300px]">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={salesIncome}
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 0,
          }}
          barSize={20}
        >
          <XAxis dataKey="day" />
          <YAxis />
          <Tooltip content={CustomTooltip} />
          <Legend />
          <Bar
            dataKey={income ? "heroes_income" : "heroes_amount"}
            stackId="a"
            fill="#5468DB"
          />
          <Bar
            dataKey={income ? "vpls_income" : "vpls_amount"}
            stackId="a"
            fill="#4BC18F"
          />
          <Bar
            dataKey={income ? "supermarket_income" : "supermarket_amount"}
            stackId="a"
            fill="#FFBB44"
          />
          <Bar
            dataKey={income ? "marketplace_commission_income" : "marketplace_commission_amount"}
            stackId="a"
            fill="#ff7400"
          />
          <Bar
            dataKey={income ? "retnal_commission_income" : "rental_commission_amount"}
            stackId="a"
            fill="#b501ef"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

import React from "react";
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from "recharts";

const RADIAN = Math.PI / 180;

const COLORS = ["#5468DB", "#4BC18F", "#FFBB44", "#E97300", "#9A00E9", "#0e1fff", "#00ff00"];

export default function Product({ summaryPie, income }) {
  console.log('summaryPie', summaryPie)
  
  const formatPrice = (price) => {
    if (typeof price === "string") {
      price = Math.round(price).toLocaleString()
    }
    if (!isNaN(price)) {
      return Math.round(price).toLocaleString();
    }
    return price;
  };

  const renderCustomTooltip = ({ active, payload }) => {
    if (!active || !payload || !payload[0]) return null;
    const { category, total_value } = payload[0].payload;

    return (
      <div className="flex gap-2 bg-white border p-[10px] border-[#cccccc] whitespace-nowrap">
        <p>{category}</p>
        <p>${formatPrice(total_value)}</p>
      </div>
    );
  };

  return (
    <div className="h-[300px] w-full">
      <ResponsiveContainer>
        <PieChart className="mx-auto !h-[400px] !w-[400px] [&_svg]:!w-full [&_svg]:!h-full -mt-[55px]">
          <Pie
            data={summaryPie}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={80}
            fill="#8884d8"
            dataKey='total_amount'
            label={({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
              const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
              const x = cx + radius * Math.cos(-midAngle * RADIAN);
              const y = cy + radius * Math.sin(-midAngle * RADIAN);

              return (
                <text
                  x={x}
                  y={y}
                  fill="white"
                  textAnchor={x > cx ? "start" : "end"}
                  dominantBaseline="central"
                >
                  {`${(percent * 100).toFixed(0)}%`}
                </text>
              );
            }}
          >
            {summaryPie?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip content={renderCustomTooltip} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

import React from "react";
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from "recharts";

const RADIAN = Math.PI / 180;

const COLORS = ["#5468DB", "#4BC18F", "#FFBB44", "#E97300", "#9A00E9", "#0e1fff", "#00ff00"];

export default function Product({ summaryPie, income }) {
  
  const formatPrice = (price) => {
    if (typeof price === "string") {
      price = parseFloat(price);
    }
    if (!isNaN(price)) {
      return price.toLocaleString();
    }
    return price;
  };

  const renderCustomTooltip = (data) => {
    if (!data.active || !data.payload[0]) return null;
    const { name, value, percent } = data.payload[0];
    const customContent = (
      <div className="flex gap-2 bg-white border p-[10px] border-[#cccccc] whitespace-nowrap">
        <p>{name}</p>
        <p>{formatPrice(value)}</p>
      </div>
    );

    return <div className="custom-tooltip">{customContent}</div>;
  };

  return (
    <div className="h-[300px] w-full">
      <ResponsiveContainer>
        <PieChart className="mx-auto !h-[400px] !w-[400px] [&_svg]:!w-full  [&_svg]:!h-full -mt-[55px]">
          <Pie
            data={summaryPie}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={80}
            fill="#8884d8"
            dataKey={`${income ? "income" : "amount"}`}
            label={({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
              const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
              const x = cx + radius * Math.cos(-midAngle * RADIAN);
              const y = cy + radius * Math.sin(-midAngle * RADIAN);

              return (
                <text
                  x={x}
                  y={y}
                  fill="white"
                  textAnchor={x > cx ? "start" : "end"}
                  dominantBaseline="central"
                >
                  {`${(percent * 100).toFixed(0)}%`}
                </text>
              );
            }}
          >
            {summaryPie?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip content={renderCustomTooltip} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

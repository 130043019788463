import CountrySelector from "../../components/CountrySelector";
import ListBoxSelect from "./components/ListBoxSelect";
import { useEffect, useState } from "react";
import KOLTable from "./components/KOLTable";
import AddKOLDetails from "./components/AddKOLDetails";
import MonthlyFees from "./components/MonthlyFees";
import {
  getNickNameSoga,
  getStreamMedia,
  getKOLType,
  getMonthlyFee,
  getKOLAccountList,
  getKOLAccountId,
} from "../../api";
import { BiLoaderCircle } from "react-icons/bi";

const statusList = [
  { title: "waiting to start", value: 0 },
  { title: "test period", value: 1 },
  { title: "ambassador", value: 10 },
  { title: "cancelled", value: -99 },
  { title: "test / ambassador", value: "test_ambassador" },
];

export default function KolManager() {
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [query, setQuery] = useState("");
  const [callStatus, setCallStatus] = useState(true);
  const [newKOL, setNewKOL] = useState(false);
  const [updateKOL, setUpdateKOL] = useState(false);
  const [nickNameSoga, setNickNameSoga] = useState([]);
  const [stream, setStream] = useState([]);
  const [kolType, setKolType] = useState([]);
  const [kolId, setKolId] = useState(null);
  const [monthlyFee, setMonthlyFee] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [country, setCountry] = useState("");
  const [status, setStatus] = useState("");
  const [selected, setSelected] = useState(statusList[4]);
  const [loader, setLoader] = useState(false);
  const [editAccountList, setEditAccountList] = useState({});

  const handleFilterName = (text) => {
    setQuery(text);
    if ((text.length >= 3) & callStatus) {
      fetchNickNameSoga(text);
    } else if (text.length < 3) {
      setCallStatus(true);
    }
  };

  const fetchNickNameSoga = async (text) => {
    try {
      let data = await getNickNameSoga(text);
      setCallStatus(false);
      setNickNameSoga(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchStreamMedia();
    fetchKOLType();
    fetchKOLAccountList();
  }, []);

  const fetchStreamMedia = async () => {
    try {
      let data = await getStreamMedia();
      setStream(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchKOLType = async () => {
    try {
      let data = await getKOLType();
      setKolType(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchKOLAccountList = async () => {
    try {
      let data = await getKOLAccountList(from, to, country, status);
      setAccountList(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchKOLAccountId = async (id) => {
    setUpdateKOL(true);
    setNewKOL(false);
    try {
      let data = await getKOLAccountId(id);
      setKolId(id);
      let fee = await getMonthlyFee(id);
      setEditAccountList(data);
      setMonthlyFee(fee);
      await fetchNickNameSoga(data.nickname_in_soga);
    } catch (error) {
      console.error(error);
    }
  };

  const selectCountry = async (param) => {
    setCountry(param.value);
  };

  const selectStatus = async (param) => {
    setStatus(param.value);
  };

  const editAccountData = (data) => {
    setEditAccountList(data);
  };

  const filter = async () => {
    setLoader(true);
    await fetchKOLAccountList();
    setLoader(false);
  };

  const filteredName =
    query === ""
      ? nickNameSoga
      : nickNameSoga.filter((name) =>
          name.nickname
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );
  return (
    <>
      <div className="flex flex-col items-center h-full p-6 gap-6">
        <div className="p-6 rounded-[2px] border bg-white w-full">
          <h3 className="text-[18px] font-bold text-black">Filter KOL by</h3>
          <div className="flex items-center gap-5 mt-2">
            <label>Creation date, from</label>
            <div className="relative w-[120px]">
              <input
                type="text"
                value={from}
                onChange={(e) => setFrom(e.target.value)}
                className="h-[40px] px-3 border w-full outline-0 border-[#E8E7E4] placeholder:text-white"
              />
              <input
                type="date"
                value={from}
                onChange={(e) => setFrom(e.target.value)}
                className="absolute inset-0 opacity-0 z-1"
              />
            </div>
            <label>to</label>
            <div className="relative w-[120px]">
              <input
                type="text"
                value={to}
                onChange={(e) => setTo(e.target.value)}
                className="h-[40px] px-3 border w-full outline-0 border-[#E8E7E4] placeholder:text-white"
              />
              <input
                type="date"
                value={to}
                onChange={(e) => setTo(e.target.value)}
                className="absolute inset-0 opacity-0 z-1"
              />
            </div>
            <div className="w-[150px]">
              <CountrySelector selectCountry={selectCountry} />
            </div>
            <div className="w-[200px]">
              <ListBoxSelect
                options={statusList}
                selected={selected}
                onChange={selectStatus}
                setSelected={setSelected}
              />
            </div>
            {loader ? (
              <button className="h-[40px] px-8 text-[16px] text-white flex items-center justify-center bg-[#0080A6]">
                <BiLoaderCircle
                  size={22}
                  className="animate-[spin_3s_linear_infinite]"
                />
              </button>
            ) : (
              <button
                onClick={filter}
                className="h-[40px] px-8 text-[16px] text-white flex items-center justify-center bg-[#0080A6]"
              >
                Reload
              </button>
            )}
            <button
              onClick={() => {
                setNewKOL(true);
                setUpdateKOL(false);
              }}
              className="h-[40px] px-8 text-[16px] text-white flex items-center justify-center bg-[#0080A6]"
            >
              Add a new KOL
            </button>
          </div>
        </div>
        <KOLTable
          accountList={accountList}
          editAccountData={editAccountData}
          fetchKOLAccountId={fetchKOLAccountId}
        />
        {newKOL && (
          <>
            <AddKOLDetails
              stream={stream}
              setQuery={setQuery}
              filteredName={filteredName}
              query={query}
              handleFilterName={handleFilterName}
              kolType={kolType}
              fetchKOLAccountList={fetchKOLAccountList}
            />

            <div className="p-6 rounded-[2px] border bg-white w-full -mt-4">
              <p className="text-[16px] font-normal text-black">
                You will be able to add the monthly fee once the initial
                streamer setup is done
              </p>
            </div>
          </>
        )}
        {updateKOL && (
          <>
            <div className="p-6 rounded-[2px] border bg-white w-full">
              <AddKOLDetails
                stream={stream}
                setQuery={setQuery}
                filteredName={filteredName}
                query={query}
                handleFilterName={handleFilterName}
                kolType={kolType}
                fetchKOLAccountList={fetchKOLAccountList}
                editAccountList={editAccountList}
                type="update"
              />
            </div>
            <div className="p-6 rounded-[2px] border bg-white w-full">
              <MonthlyFees
                monthlyFee={monthlyFee}
                setMonthlyFee={setMonthlyFee}
                kolId={kolId}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

import { AiOutlineCaretDown } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { Tooltip } from "react-tooltip";

const NFTStatusTable = ({
  sortStatusTable,
  circulantPlayers,
  handlePageClick,
  pagination,
  loader,
  sortBy,
  sortDir,
}) => {
  const formatAmount = (amount) => {
    if (amount >= 1000000) {
      return (amount / 1000000).toFixed(1) + "M";
    } else if (amount >= 1000) {
      return (amount / 1000).toFixed(1) + "K";
    } else {
      return amount.toString();
    }
  };
  const roundToTwoDecimalPlaces = (input) => {
    let hasDollarSign,
      cleanedInput = input;
    if (typeof input === "string") {
      hasDollarSign = input.includes("$");
      if (hasDollarSign) {
        cleanedInput = input.replace(/^\$|0+(?=\d)/g, "");
      }
    }
    const number = parseFloat(cleanedInput);
    if (cleanedInput === "0") {
      return "$0";
    }
    if (!isNaN(number) && isFinite(number)) {
      const roundedNumber = parseFloat(number.toFixed(2));
      return `$${roundedNumber}`;
    }
  };

  return (
    <div className="bg-white w-full">
      <div className="overflow-x-auto">
        <table className="w-full min-w-[800px]">
          <thead>
            <tr>
              <th
                rowSpan={3}
                onClick={() => sortStatusTable("nickname")}
                className="border border-l-0 text-center  font-medium text-gray-600 p-[8px] text-[13px] group"
              >
                <div className="flex items-center cursor-pointer gap-1">
                  Nickname
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "nickname" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                rowSpan={3}
                onClick={() => sortStatusTable("country")}
                className="border p-[8px] text-[13px] whitespace-nowrap font-medium text-gray-600 text-center group"
              >
                <div className="flex items-center cursor-pointer gap-1 justify-center">
                  Country
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "country" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                rowSpan={3}
                onClick={() => sortStatusTable("created_at")}
                className="border p-[8px] text-[13px] whitespace-nowrap font-medium text-gray-600 text-center group"
              >
                <div className="flex items-center cursor-pointer gap-1 justify-center">
                  Created at
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "created_at" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                rowSpan={3}
                onClick={() => sortStatusTable("last_login")}
                className="border p-[8px] text-[13px] whitespace-nowrap font-medium text-gray-600 text-center group"
              >
                <div className="flex items-center cursor-pointer gap-1 justify-center">
                  Last log-in
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "last_login" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                colSpan={6}
                className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center"
              >
                Heroes
              </th>
              <th
                colSpan={6}
                className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center"
              >
                Loot
              </th>
              <th
                colSpan={8}
                className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center"
              >
                Coins
              </th>
              <th
                rowSpan={2}
                colSpan={2}
                className="border p-[8px] text-[13px] font-medium text-gray-600 text-center group"
              >
                <div className="flex items-center cursor-pointer gap-1 justify-center flex-col">
                  <div className="">Purchases (All Time)</div>
                </div>
              </th>
            </tr>
            <tr>
              <th
                colSpan={2}
                className="border p-[8px] text-[13px] whitespace-nowrap font-medium text-gray-600 text-center"
              >
                Regular (BI 30d)
              </th>
              <th
                colSpan={2}
                className="border p-[8px] text-[13px] whitespace-nowrap font-medium text-gray-600 text-center"
              >
                Fused (BI 60d)
              </th>
              <th
                colSpan={2}
                className="border p-[8px] text-[13px] whitespace-nowrap font-medium text-gray-600 text-center"
              >
                Limited (BI 90d)
              </th>
              <th
                colSpan={2}
                className="border p-[8px] text-[13px] whitespace-nowrap font-medium text-gray-600 text-center"
              >
                Loot box (15d)
              </th>
              <th
                colSpan={2}
                className="border p-[8px] text-[13px] whitespace-nowrap font-medium text-gray-600 text-center"
              >
                Cylinders (30d)
              </th>
              <th
                colSpan={2}
                className="border p-[8px] text-[13px] whitespace-nowrap font-medium text-gray-600 text-center"
              >
                Crafters (15d)
              </th>
              <th
                colSpan={2}
                className="border p-[8px] text-[13px] whitespace-nowrap font-medium text-gray-600 text-center"
              >
                Gold (60d)
              </th>
              <th
                colSpan={2}
                className="border p-[8px] text-[13px] whitespace-nowrap font-medium text-gray-600 text-center"
              >
                Diamonds (120d)
              </th>
              <th
                colSpan={2}
                className="border p-[8px] text-[13px] whitespace-nowrap font-medium text-gray-600 text-center"
              >
                Stars (60d)
              </th>
              <th
                colSpan={2}
                className="border p-[8px] text-[13px] whitespace-nowrap font-medium text-gray-600 text-center"
              >
                Pre-SOGAs (60d)
              </th>
            </tr>
            <tr>
              <th
                onClick={() => sortStatusTable("total_regular_heroes")}
                className="border p-[8px_0] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center group"
              >
                <div className="flex items-center cursor-pointer gap-1 justify-center">
                  Total
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "total_regular_heroes" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("last_purchase_regular_edition")}
                className="border p-[8px_0] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center group"
              >
                <div className="flex items-center cursor-pointer gap-1 justify-center">
                  L.Purch.
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "last_purchase_regular_edition" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("total_fused_heroes")}
                className="border p-[8px_0] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center group"
              >
                <div className="flex items-center cursor-pointer gap-1 justify-center">
                  Total
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "total_fused_heroes" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("last_purchase_fused_edition")}
                className="border p-[8px_0] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center group"
              >
                <div className="flex items-center cursor-pointer gap-1 justify-center">
                  L.Purch.
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "last_purchase_fused_edition" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("total_limited_heroes")}
                className="border p-[8px_0] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center group"
              >
                <div className="flex items-center cursor-pointer gap-1 justify-center">
                  Total
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "total_limited_heroes" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("last_purchase_limited_edition")}
                className="border p-[8px_0] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center group"
              >
                <div className="flex items-center cursor-pointer gap-1 justify-center">
                  L.Purch.
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "last_purchase_limited_edition" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("amount_lootboxes")}
                className="border p-[8px_0] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center group"
              >
                <div className="flex items-center cursor-pointer gap-1 justify-center">
                  Total
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "amount_lootboxes" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("last_lootbox_claim")}
                className="border p-[8px_0] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center group"
              >
                <div className="flex items-center cursor-pointer gap-1 justify-center">
                  L.Claim.
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "last_lootbox_claim" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("amount_body_parts_chest")}
                className="border p-[8px_0] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center group"
              >
                <div className="flex items-center cursor-pointer gap-1 justify-center">
                  Total
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "amount_body_parts_chest" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("last_chest_claim")}
                className="border p-[8px_0] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center group"
              >
                <div className="flex items-center cursor-pointer gap-1 justify-center">
                  L.Claim.
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "last_chest_claim" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("amount_crafter")}
                className="border p-[8px_0] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center group"
              >
                <div className="flex items-center cursor-pointer gap-1 justify-center">
                  Total
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "amount_crafter" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("last_crafter_revealed")}
                className="border p-[8px_0] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center group"
              >
                <div className="flex items-center cursor-pointer gap-1 justify-center">
                  L.Reveal.
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "last_crafter_revealed" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("amount_coins")}
                className="border p-[8px_0] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center group"
              >
                <div className="flex items-center cursor-pointer gap-1 justify-center">
                  Total
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "amount_coins" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("last_purchase_coins")}
                className="border p-[8px_0] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center group"
              >
                <div className="flex items-center cursor-pointer gap-1 justify-center">
                  L.Purch.
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "last_purchase_coins" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("amount_diamonds")}
                className="border p-[8px_0] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center group"
              >
                <div className="flex items-center cursor-pointer gap-1 justify-center">
                  Total
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "amount_diamonds" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("last_purchase_diamonds")}
                className="border p-[8px_0] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center group"
              >
                <div className="flex items-center cursor-pointer gap-1 justify-center">
                  L.Purch.
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "last_purchase_diamonds" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("amount_stars")}
                className="border p-[8px_0] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center group"
              >
                <div className="flex items-center cursor-pointer gap-1 justify-center">
                  Total
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "amount_stars" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("last_purchase_stars")}
                className="border p-[8px_0] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center group"
              >
                <div className="flex items-center cursor-pointer gap-1 justify-center">
                  L.Purch.
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "last_purchase_stars" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("amount_soga")}
                className="border p-[8px_0] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center group"
              >
                <div className="flex items-center cursor-pointer gap-1 justify-center">
                  Total
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "amount_soga" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("last_purchase_soga")}
                className="border p-[8px_0] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center group"
              >
                <div className="flex items-center cursor-pointer gap-1 justify-center">
                  L.Purch.
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "last_purchase_soga" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("total_sales")}
                className="border p-[8px_0] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center group"
              >
                <div className="flex items-center cursor-pointer gap-1 justify-center">
                  Total
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "total_sales" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("sales")}
                className="border p-[8px_0] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center group"
              >
                <div className="flex items-center cursor-pointer gap-1 justify-center">
                  Income
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "sales" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {circulantPlayers?.map((hero, key) => (
              <tr key={key}>
                <td className="border first:border-l-0 last:border-r-0 text-left font-medium text-gray-600 p-[8px] text-[14px] whitespace-nowrap ">
                  {hero?.nickname !== "-" ? `@${hero?.nickname}` : "-"}
                </td>
                <td className="border first:border-l-0 last:border-r-0 text-left font-medium text-gray-600 p-[8px] text-[14px] whitespace-nowrap ">
                  <div
                    className="flex justify-center"
                    data-tooltip-id={key + "country"}
                  >
                    <img
                      className="w-[30px]"
                      src={hero.country_flag}
                      alt={hero.country_title}
                    />
                  </div>
                  <Tooltip id={key + "country"}>{hero.country_title}</Tooltip>
                </td>
                <td className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center text-blue-500">
                  {hero?.created_at || "-"}
                </td>
                <td
                  className={`border p-[8px] text-[14px] whitespace-nowrap font-medium text-center cell-${hero.last_login_highlight}`}
                >
                  {hero?.last_login !== "-" ? `${hero?.last_login}d` : "-"}
                </td>
                <td
                  className={`border p-[8px] text-[14px] whitespace-nowrap font-medium text-center cell-${hero?.heroes?.regular.highlight}`}
                >
                  {formatAmount(hero?.heroes?.regular?.total)}
                </td>
                <td
                  className={`border p-[8px] text-[14px] whitespace-nowrap font-medium text-center cell-${hero?.heroes?.regular.highlight}`}
                >
                  {hero?.heroes?.regular?.last_purchase !== "-"
                    ? `${hero?.heroes?.regular?.last_purchase}d`
                    : "-"}
                </td>
                <td
                  className={`border p-[8px] text-[14px] whitespace-nowrap font-medium text-center cell-${hero?.heroes?.fused.highlight}`}
                >
                  {formatAmount(hero?.heroes?.fused?.total)}
                </td>
                <td
                  className={`border p-[8px] text-[14px] whitespace-nowrap font-medium text-center cell-${hero?.heroes?.fused.highlight}`}
                >
                  {hero?.heroes?.fused?.last_purchase !== "-"
                    ? `${hero?.heroes?.fused?.last_purchase}d`
                    : "-"}
                </td>
                <td
                  className={`border p-[8px] text-[14px] whitespace-nowrap font-medium text-left cell-${hero?.heroes?.limited.highlight}`}
                >
                  {formatAmount(hero?.heroes?.limited?.total)}
                </td>
                <td
                  className={`border p-[8px] text-[14px] whitespace-nowrap font-medium text-center cell-${hero?.heroes?.limited.highlight}`}
                >
                  {hero?.heroes?.limited?.last_purchase !== "-"
                    ? `${hero?.heroes?.limited?.last_purchase}d`
                    : "-"}
                </td>
                <td
                  className={`border p-[8px] text-[14px] whitespace-nowrap font-medium text-center cell-${hero?.loot?.lootbox.highlight}`}
                >
                  {formatAmount(hero?.loot?.lootbox?.total)}
                </td>
                <td
                  className={`border p-[8px] text-[14px] whitespace-nowrap font-medium text-center cell-${hero?.loot?.lootbox.highlight}`}
                >
                  {hero?.loot?.lootbox?.last_claim !== "-"
                    ? `${hero?.loot?.lootbox?.last_claim}d`
                    : "-"}
                </td>
                <td
                  className={`border p-[8px] text-[14px] whitespace-nowrap font-medium text-center cell-${hero?.loot?.cylinders.highlight}`}
                >
                  {formatAmount(hero?.loot?.cylinders?.total)}
                </td>
                <td
                  className={`border p-[8px] text-[14px] whitespace-nowrap font-medium text-center cell-${hero?.loot?.cylinders.highlight}`}
                >
                  {hero?.loot?.cylinders?.last_claim || "-"}
                </td>
                <td
                  className={`border p-[8px] text-[14px] whitespace-nowrap font-medium text-center cell-${hero?.loot?.crafters.highlight}`}
                >
                  {formatAmount(hero?.loot?.crafters?.total)}
                </td>
                <td
                  className={`border p-[8px] text-[14px] whitespace-nowrap font-medium text-center cell-${hero?.loot?.crafters.highlight}`}
                >
                  {hero?.loot?.crafters?.last_reveal || "-"}
                </td>
                <td
                  className={`border p-[8px] text-[14px] whitespace-nowrap font-medium text-center cell-${hero?.coins?.gold.highlight}`}
                >
                  {formatAmount(hero?.coins?.gold?.total)}
                </td>
                <td
                  className={`border p-[8px] text-[14px] whitespace-nowrap font-medium text-center cell-${hero?.coins?.gold.highlight}`}
                >
                  {hero?.coins?.gold?.last_purchase !== "-"
                    ? `${hero?.coins?.gold?.last_purchase}d`
                    : "-"}
                </td>
                <td
                  className={`border p-[8px] text-[14px] whitespace-nowrap font-medium text-center cell-${hero?.coins?.diamonds.highlight}`}
                >
                  {formatAmount(hero?.coins?.diamonds?.total)}
                </td>
                <td
                  className={`border p-[8px] text-[14px] whitespace-nowrap font-medium text-center cell-${hero?.coins?.diamonds.highlight}`}
                >
                  {hero?.coins?.diamonds?.last_purchase !== "-"
                    ? `${hero?.coins?.diamonds?.last_purchase}d`
                    : "-"}
                </td>
                <td
                  className={`border p-[8px] text-[14px] whitespace-nowrap font-medium text-center cell-${hero?.coins?.stars.highlight}`}
                >
                  {formatAmount(hero?.coins?.stars?.total)}
                </td>
                <td
                  className={`border p-[8px] text-[14px] whitespace-nowrap font-medium text-center cell-${hero?.coins?.stars.highlight}`}
                >
                  {hero?.coins?.stars?.last_purchase !== "-"
                    ? `${hero?.coins?.stars?.last_purchase}d`
                    : "-"}
                </td>
                <td
                  className={`border p-[8px] text-[14px] whitespace-nowrap font-medium text-center cell-${hero?.coins?.pre_sogas?.highlight}`}
                >
                  {formatAmount(hero?.coins?.pre_sogas?.total)}
                </td>
                <td
                  className={`border p-[8px] text-[14px] whitespace-nowrap font-medium text-center cell-${hero?.coins?.pre_sogas?.highlight}`}
                >
                  {hero?.coins?.pre_sogas?.last_purchase !== "-"
                    ? `${hero?.coins?.pre_sogas?.last_purchase}d`
                    : "-"}
                </td>
                <td
                  className={`border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center`}
                >
                  {hero?.sales?.count.toLocaleString()}
                </td>
                <td
                  className={`border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center`}
                >
                  {roundToTwoDecimalPlaces(hero?.sales?.amount)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="tokenomics-pagination my-10 mr-6">
        <ReactPaginate
          breakLabel="..."
          nextLabel={null}
          previousLabel={null}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pagination?.totalPages}
          forcePage={pagination?.previousPage}
          marginPagesDisplayed={2}
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
};

export default NFTStatusTable;

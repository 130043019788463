import React from "react";
import Daily from "./Daily";
import Monthly from "./Monthly";
import moment from "moment";

export default function UsersChart({ dailyAccounts, monthlyAccounts }) {
  return (
    <div className="p-6 bg-white w-full mt-6">
      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-9 border-r pr-5 flex flex-col">
          <h4 className="text-[20px] text-black font-semibold mb-4">
            Daily Active Users
          </h4>
          <Daily dailyAccounts={dailyAccounts} />
        </div>
        <div className="col-span-3">
          <h4 className="text-[20px] text-black font-semibold mb-4">
            Monthly Active Users (Up to day {moment().format('Do')})
          </h4>
          <Monthly monthlyAccounts={monthlyAccounts} />
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from "recharts";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
const formatPrice = (price) => {
  if (typeof price === "string") {
    price = parseFloat(price);
  }
  if (!isNaN(price)) {
    return price.toLocaleString();
  }
  return price;
};
const renderCustomTooltip = (data) => {
  if (!data.active) return null;
  const customContent = (
    <div className="flex gap-2 bg-white border p-[10px] border-[#cccccc] whitespace-nowrap">
      <p> {data.payload[0].name}</p>
      <p> {formatPrice(data.payload[0].value)}</p>
    </div>
  );

  return <div className="custom-tooltip">{customContent}</div>;
};

const COLORS = ["#5468DB", "#4BC18F", "#FFBB44","#ff7400","#b501ef"];

export default function Product({ salesProduct, income }) {
  return (
    <div className="h-[300px] w-full">
      <ResponsiveContainer>
        <PieChart className="mx-auto !h-[400px] !w-[400px] [&_svg]:!w-full  [&_svg]:!h-full -mt-[55px]">
          <Pie
            data={salesProduct}
            cx="50%"
            cy="50%"
            label={renderCustomizedLabel}
            labelLine={false}
            outerRadius={80}
            fill="#8884d8"
            dataKey={`${income ? "income" : "amount"}`}
          >
            {salesProduct?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip content={renderCustomTooltip} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

import React from "react";
import { BiLoaderCircle } from "react-icons/bi";
import moment from "moment";

const TournamentTable = ({
  list,
  loader,
  setCreate,
  setType,
  detailTournament,
}) => {
  const roundToTwoDecimalPlaces = (input) => {
    const number = parseFloat(input);

    if (typeof number === "number" && !Number.isNaN(number) && number !== 0) {
      const roundedNumber = parseFloat(number.toFixed(2));
      return roundedNumber.toLocaleString();
    }

    if (isNaN(number)) {
      return 0;
    }

    return input;
  };

  return (
    <div className="bg-white w-full">
      <div className="overflow-x-auto">
        <table className="w-full min-w-[800px]">
          <thead>
            <tr>
              <th className="border border-l-0 p-[8px] text-[13px] whitespace-nowrap text-left">
                Id
              </th>
              <th className="border p-[8px] text-[13px] whitespace-nowrap text-left">
                Tourn. template
              </th>
              <th className="border p-[8px] text-[13px] whitespace-nowrap text-left">
                Sedoncary. template
              </th>
              <th className="border p-[8px] text-[13px] whitespace-nowrap text-left">
                Server
              </th>
              <th className="border p-[8px] text-[13px] whitespace-nowrap text-left">
                Title
              </th>
              <th className="border p-[8px] text-[13px] whitespace-nowrap text-left">
                Reg. Start
              </th>
              <th className="border p-[8px] text-[13px] whitespace-nowrap text-left">
                Reg. End
              </th>
              <th className="border p-[8px] text-[13px] whitespace-nowrap text-left">
                Tourn. Start
              </th>
              <th className="border p-[8px] text-[13px] whitespace-nowrap text-left">
                Tourn. End
              </th>
              <th className="border p-[8px] text-[13px] whitespace-nowrap text-left">
                Tourn. Status
              </th>
              <th className="border p-[8px] text-[13px] whitespace-nowrap text-center">
                Ticket
              </th>
              <th className="border p-[8px] text-[13px] whitespace-nowrap text-center">
                Entries
              </th>
              <th className="border p-[8px] text-[13px] whitespace-nowrap text-center uppercase">
                Gross Turn.
              </th>
              <th className="border p-[8px] text-[13px] whitespace-nowrap text-center uppercase">
                Inc./Burnt
              </th>
              <th className="border p-[8px] text-[13px] whitespace-nowrap text-center uppercase">
                Prize pool
              </th>
              <th className="border border-r-0 p-[8px] text-[13px] whitespace-nowrap uppercase text-center"></th>
            </tr>
          </thead>
          <tbody>
            {loader ? (
              <tr>
                <td colSpan={25}>
                  <div className="text-center p-[28px] flex items-center justify-center">
                    <BiLoaderCircle
                      size={62}
                      className="animate-[spin_3s_linear_infinite] text-[#0080A6]"
                    />
                  </div>
                </td>
              </tr>
            ) : (
              list?.map((item, key) => {
                return (
                  <tr key={key}>
                    <td className="border p-[8px] text-[13px] whitespace-nowrap text-left">
                      {item.id || "-"}
                    </td>
                    <td className="border p-[8px] text-[13px] whitespace-nowrap text-left">
                      {item.tournament_template || "-"}
                    </td>
                    <td className="border p-[8px] text-[13px] whitespace-nowrap text-left">
                      {item.secondary_template || "-"}
                    </td>
                    <td className="border p-[8px] text-[13px] whitespace-nowrap text-left">
                      {item.server || "-"}
                    </td>
                    <td className="border p-[8px] text-[13px] whitespace-nowrap text-left">
                      {item.title || "-"}
                    </td>
                    <td className="border p-[8px] text-[13px] whitespace-nowrap text-left">
                      {moment.utc(item.registration_starts).format(
                        "YYYY/MM/DD HH:mm"
                      )}
                    </td>
                    <td className="border p-[8px] text-[13px] whitespace-nowrap text-left">
                      {moment.utc(item.registration_ends).format(
                        "YYYY/MM/DD HH:mm"
                      )}
                    </td>
                    <td className="border p-[8px] text-[13px] whitespace-nowrap text-left">
                      {moment.utc(item.tournament_starts).format(
                        "YYYY/MM/DD HH:mm"
                      )}
                    </td>
                    <td className="border p-[8px] text-[13px] whitespace-nowrap text-left">
                      {moment.utc(item.tournament_ends).format("YYYY/MM/DD HH:mm")}
                    </td>
                    <td className="border p-[8px] text-[13px] whitespace-nowrap text-left">
                      {item.tournament_status ? item.tournament_status : "-"}
                    </td>
                    <td className="border p-[8px] text-[13px] ">
                      <div className="flex justify-center gap-2">
                        {item.ticket_amount}
                        {item.ticket_image && (
                          <img
                            src={`https://www.socialgames.com/${item.ticket_image}`}
                            className="w-5"
                            alt="Ticket"
                          />
                        )}
                      </div>
                    </td>
                    <td className="border p-[8px] text-[13px] whitespace-nowrap text-center">
                      {item.entries || "-"}
                    </td>
                    <td className="border p-[8px] text-[13px] ">
                      <div className="flex justify-center gap-2">
                        {item.gross_turn || "-"}
                        {item.gross_tun_img && (
                          <img
                            src={`https://www.socialgames.com/${item.gross_tun_img}`}
                            className="w-5"
                            alt="Ticket"
                          />
                        )}
                      </div>
                    </td>
                    <td className="border p-[8px] text-[13px] ">
                      <div className="flex justify-center gap-2">
                        {item.inc_burnt || "-"}
                        {item.inc_burnt_img && (
                          <img
                            src={`https://www.socialgames.com/${item.inc_burnt_img}`}
                            className="w-5"
                            alt="Ticket"
                          />
                        )}
                      </div>
                    </td>
                    <td className="border p-[8px] text-[13px] whitespace-nowrap text-center">
                      {item.prize_pool || "-"}
                    </td>
                    <td
                      onClick={() => {
                        setCreate(true);
                        setType("update");
                        detailTournament(item?.id);
                      }}
                      className="border p-[8px] text-[13px] whitespace-nowrap text-center cursor-pointer text-blue-500 font-medium"
                    >
                      Edit
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TournamentTable;

import React, { useState,useEffect } from "react";
import Income from "./Income";
import Product from "./Product";

export default function Sales({ fetchWorkersIncome, fetchWorkersSummary, workersIncome, workersSummary, summaryPie }) {
  
  const [income, setIncome] = useState(true);

  const [activeMatics,setActiveMatics] = useState(true);
  const [activeSogas,setActiveSogas] = useState(true);
  const [activeCoin,setCoin] = useState(true);

  useEffect(() => {
    fetchWorkersIncome(activeMatics,activeSogas,activeCoin); 
    fetchWorkersSummary(activeMatics,activeSogas,activeCoin)
  }, [activeMatics, activeSogas,activeCoin]);

  const roundToTwoDecimalPlaces = (input, noDecimals = false) => {
    let number = Number(input);
    const roundedNumber = noDecimals 
      ? Math.round(number).toLocaleString() 
      : parseFloat(number.toFixed(2)).toLocaleString();
    if (isNaN(number)) return 0;
    return roundedNumber;
  };
  return (
    <div className="p-6 bg-white w-full mt-6">
      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-9 border-r pr-5 flex flex-col">
          <div className="flex mb-6">
            <h4 className="text-[20px] text-black font-semibold">
              Workers tasks Economic{" "}
              <span
                className={`${
                  !income && "text-[#5468DB]"
                } inline-block cursor-pointer`}
                onClick={() => setIncome(true)}
              >
                value
              </span>{" "}
              |{" "}
              <span
                className={`${
                  income && "text-[#5468DB]"
                } inline-block cursor-pointer`}
                onClick={() => setIncome(false)}
              >
                Amount &nbsp;
              </span>
            </h4>
            <div className="ps-3 flex items-center">
                <label
                  htmlFor="maticsWorker"
                  className="cursor-pointer flex items-center text-[15px] font-normal"
                >
                  <input
                    id="maticsWorker"
                    type="checkbox"
                    className="hidden peer"
                    value={activeMatics}
                    checked={activeMatics}
                    onChange={() => setActiveMatics(!activeMatics)}
                  />
                  <span className="mr-2 peer-checked:bg-[#0080A6] peer-checked:border-[#0080A6] bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5.55px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border-[1.5px] border-[#ccc] rounded-sm"></span>
                  Matics
                </label>
              </div>
              <div className="ps-3 flex items-center">
                <label
                  htmlFor="sogasWorker"
                  className="cursor-pointer flex items-center text-[15px] font-normal"
                >
                  <input
                    id="sogasWorker"
                    type="checkbox"
                    className="hidden peer"
                    value={activeSogas}
                    checked={activeSogas}
                    onChange={() => setActiveSogas(!activeSogas)}
                  />
                  <span className="mr-2 peer-checked:bg-[#0080A6] peer-checked:border-[#0080A6] bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5.55px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border-[1.5px] border-[#ccc] rounded-sm"></span>
                  Sogas
                </label>
              </div>
              <div className="ps-3 flex items-center">
                <label
                  htmlFor="coin"
                  className="cursor-pointer flex items-center text-[15px] font-normal"
                >
                  <input
                    id="coin"
                    type="checkbox"
                    className="hidden peer"
                    value={activeCoin}
                    checked={activeCoin}
                    onChange={() => setCoin(!activeCoin)}
                  />
                  <span className="mr-2 peer-checked:bg-[#0080A6] peer-checked:border-[#0080A6] bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5.55px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border-[1.5px] border-[#ccc] rounded-sm"></span>
                  in-game coin (gold, diamonds, stars)
                </label>
              </div>
          </div>
          <Income workersIncome={workersIncome} income={income} />
        </div>
        <div className="col-span-3">
          <div className="grid grid-cols-10 gap-2 w-full mb-4">
            <div className="col-span-4">
              <div className="flex items-center gap-2">
                <h6 className="text-[14px] text-black font-medium">
                  Workers tasks
                </h6>
              </div>
            </div>
            <div className="col-span-2 text-center">
              <h6 className="text-[13px] text-black font-medium">Amount</h6>
            </div>
            <div className="col-span-2 text-center">
              <h6 className="text-[13px] text-black font-medium">Value</h6>
            </div>
            <div className="col-span-2 text-right">
              <h6 className="text-[13px] text-black font-medium">
                Cash-in/<br />Burnt
              </h6>
            </div>
          </div>
          <div className="grid grid-cols-10 gap-2 w-full">
            <div className="col-span-4">
              <div className="flex items-center gap-2">
                <div className="w-[10px] h-[10px] bg-[#5468DB]"></div>
                <h6 className="text-[13px] text-black ">Builders</h6>
              </div>
            </div>
            <div className="col-span-2 text-end">
              <p className="text-[13px]">{roundToTwoDecimalPlaces(workersSummary[1]?.amount)}u</p>
            </div>
            <div className="col-span-2 text-end">
              <p className="text-[13px]">${roundToTwoDecimalPlaces(workersSummary[1]?.value,true)}</p>
            </div>
            <div className="col-span-2 text-right">
              <p className="text-[13px]">
                {roundToTwoDecimalPlaces(workersSummary[1]?.cash_in_burnt)}%
              </p>
            </div>
          </div>
          <div className="grid grid-cols-10 gap-2 w-full">
            <div className="col-span-4">
              <div className="flex items-center gap-2">
                <div className="w-[10px] h-[10px] bg-[#4BC18F]"></div>
                <h6 className="text-[13px] text-black ">Sales</h6>
              </div>
            </div>
            <div className="col-span-2 text-end">
              <p className="text-[13px]">{roundToTwoDecimalPlaces(workersSummary[4]?.amount)}u</p>
            </div>
            <div className="col-span-2 text-end">
              <p className="text-[13px]">${roundToTwoDecimalPlaces(workersSummary[4]?.value,true)}</p>
            </div>
            <div className="col-span-2 text-right">
              <p className="text-[13px]">
                {roundToTwoDecimalPlaces(workersSummary[4]?.cash_in_burnt)}%
              </p>
            </div>
          </div>
          <div className="grid grid-cols-10 gap-2 w-full">
            <div className="col-span-4">
              <div className="flex items-center gap-2">
                <div className="w-[10px] h-[10px] bg-[#FFBB44]"></div>
                <h6 className="text-[13px] text-black ">Gym</h6>
              </div>
            </div>
            <div className="col-span-2 text-end">
              <p className="text-[13px]">{roundToTwoDecimalPlaces(workersSummary[2]?.amount)}u</p>
            </div>
            <div className="col-span-2 text-end">
              <p className="text-[13px]">${roundToTwoDecimalPlaces(workersSummary[2]?.value,true)}</p>
            </div>
            <div className="col-span-2 text-right">
              <p className="text-[13px]">
                {roundToTwoDecimalPlaces(workersSummary[2]?.cash_in_burnt)}%
              </p>
            </div>
          </div>
          <div className="grid grid-cols-10 gap-2 w-full">
            <div className="col-span-4">
              <div className="flex items-center gap-2">
                <div className="w-[10px] h-[10px] bg-[#E97300]"></div>
                <h6 className="text-[13px] text-black ">Engineering</h6>
              </div>
            </div>
            <div className="col-span-2 text-end">
              <p className="text-[13px]">{roundToTwoDecimalPlaces(workersSummary[3]?.amount)}u</p>
            </div>
            <div className="col-span-2 text-end">
              <p className="text-[13px]">${roundToTwoDecimalPlaces(workersSummary[3]?.value,true)}</p>
            </div>
            <div className="col-span-2 text-right">
              <p className="text-[13px]">
                {roundToTwoDecimalPlaces(workersSummary[3]?.cash_in_burnt)}%
              </p>
            </div>
          </div>
          <div className="grid grid-cols-10 gap-2 w-full">
            <div className="col-span-4">
              <div className="flex items-center gap-2">
                <div className="w-[10px] h-[10px] bg-[#9A00E9]"></div>
                <h6 className="text-[13px] text-black ">Officer</h6>
              </div>
            </div>
            <div className="col-span-2 text-end">
              <p className="text-[13px]">{roundToTwoDecimalPlaces(workersSummary[0]?.amount)}u</p>
            </div>
            <div className="col-span-2 text-end">
              <p className="text-[13px]">${roundToTwoDecimalPlaces(workersSummary[0]?.value,true)}</p>
            </div>
            <div className="col-span-2 text-right">
              <p className="text-[13px]">
                {roundToTwoDecimalPlaces(workersSummary[0]?.cash_in_burnt)}%
              </p>
            </div>
          </div>

          <div className="grid grid-cols-10 gap-2 w-full">
            <div className="col-span-4">
              <div className="flex items-center gap-2">
                <div className="w-[10px] h-[10px] bg-[#0e1fff]"></div>
                <h6 className="text-[13px] text-black ">Marketplace (P2P)</h6>
              </div>
            </div>
            <div className="col-span-2 text-end">
              <p className="text-[13px]">{roundToTwoDecimalPlaces(workersSummary[5]?.amount)}u</p>
            </div>
            <div className="col-span-2 text-end">
              <p className="text-[13px]">${roundToTwoDecimalPlaces(workersSummary[5]?.value,true)}</p>
            </div>
            <div className="col-span-2 text-right">
              <p className="text-[13px]">
                {roundToTwoDecimalPlaces(workersSummary[5]?.cash_in_burnt)}%
              </p>
            </div>
          </div>

          <div className="grid grid-cols-10 gap-2 w-full">
            <div className="col-span-4">
              <div className="flex items-center gap-2">
                <div className="w-[10px] h-[10px] bg-[#00ff00]"></div>
                <h6 className="text-[13px] text-black ">Rentals (P2P)</h6>
              </div>
            </div>
            <div className="col-span-2 text-end">
              <p className="text-[13px]">{roundToTwoDecimalPlaces(workersSummary[6]?.amount)}u</p>
            </div>
            <div className="col-span-2 text-end">
              <p className="text-[13px]">${roundToTwoDecimalPlaces(workersSummary[6]?.value,true)}</p>
            </div>
            <div className="col-span-2 text-right">
              <p className="text-[13px]">
                {roundToTwoDecimalPlaces(workersSummary[6]?.cash_in_burnt)}%
              </p>
            </div>
          </div>


          <div className="grid grid-cols-10 gap-2 w-full">
            <div className="col-span-4">
              <div className="flex items-center gap-2">
                <div className="w-[10px] h-[10px]"></div>
                <h6 className="text-[13px] text-black font-semibold">Total</h6>
              </div>
            </div>
            <div className="col-span-2 text-end">
              <p className="text-[13px] font-semibold">
                {workersSummary[7]?.total_amount}u
              </p>
            </div>
            <div className="col-span-2 text-end">
              <p className="text-[13px] font-semibold">
                ${roundToTwoDecimalPlaces(workersSummary[7]?.total_value,true)}
              </p>
            </div>
            <div className="col-span-2 text-right">
              <p className="text-[13px] font-semibold">
                {workersSummary[7]?.total_cash_in_burnt || 0}%
              </p>
            </div>
          </div>
          <Product summaryPie={summaryPie} income={income} />
        </div>
      </div>
    </div>
  );
}

import React, { useState,useEffect } from "react";
import NewAccounts from "./NewAccounts";
import KPIs from "./KPIs";

export default function AccountsChart({ newAccounts, kpiAccount, kpisPeriod, fetchNewAccounts, fetchKPIsPeriod }) {

  const [directSales,setDirectSales] = useState(true);
  const [rentals,setRentals] = useState(true);
  const [marketplace,setMarketplace] = useState(true);

  useEffect(() => {
    fetchNewAccounts(directSales,rentals,marketplace);
    fetchKPIsPeriod(directSales,rentals,marketplace)
  }, [directSales, rentals, marketplace]);

  return (
    <div className="p-6 bg-white w-full mt-6">
      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-9 border-r pr-5 flex flex-col">
          <div className="flex mb-4">
            <h4 className="text-[20px] text-black font-semibold">
              New Accounts, Include transaction ONLY with matics/money on 
            </h4>
            <div className="ps-3 flex items-center">
              <label
                htmlFor="directSales"
                className="cursor-pointer flex items-center text-[15px] font-normal"
              >
                <input
                  id="directSales"
                  type="checkbox"
                  className="hidden peer"
                  value={directSales}
                  checked={directSales}
                  onChange={() => setDirectSales(!directSales)}
                />
                <span className="mr-2 peer-checked:bg-[#0080A6] peer-checked:border-[#0080A6] bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5.55px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border-[1.5px] border-[#ccc] rounded-sm"></span>
                Direct Sales
              </label>
            </div>
            <div className="ps-3 flex items-center">
              <label
                htmlFor="rentals"
                className="cursor-pointer flex items-center text-[15px] font-normal"
              >
                <input
                  id="rentals"
                  type="checkbox"
                  className="hidden peer"
                  value={rentals}
                  checked={rentals}
                  onChange={() => setRentals(!rentals)}
                />
                <span className="mr-2 peer-checked:bg-[#0080A6] peer-checked:border-[#0080A6] bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5.55px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border-[1.5px] border-[#ccc] rounded-sm"></span>
                Rentals
              </label>
            </div>
            <div className="ps-3 flex items-center">
              <label
                htmlFor="marketplace"
                className="cursor-pointer flex items-center text-[15px] font-normal"
              >
                <input
                  id="marketplace"
                  type="checkbox"
                  className="hidden peer"
                  value={marketplace}
                  checked={marketplace}
                  onChange={() => setMarketplace(!marketplace)}
                />
                <span className="mr-2 peer-checked:bg-[#0080A6] peer-checked:border-[#0080A6] bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5.55px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border-[1.5px] border-[#ccc] rounded-sm"></span>
                2nd hand marketplace
              </label>
            </div>
          </div>
          <NewAccounts newAccounts={newAccounts} />
        </div>
        <div className="col-span-3">
          <KPIs kpiAccount={kpiAccount} kpisPeriod={kpisPeriod} />
        </div>
      </div>
    </div>
  );
}

import { useState } from 'react';
import { BiLoaderCircle } from "react-icons/bi";
import ListBoxSelect from "../ListBoxSelect";
import ReactPaginate from "react-paginate";
import moment from "moment";
import {postMission,getMissionDetail,updateMission} from "../../../../api/index"
const TokenomicsTable = ({
  tokenomics,
  pagination,
  handlePageClick,
  loader,
  platformItems,
  challengesMission,
  fetchMissionList
}) => {
  const leagueList = [
    { title: "Amateur", value: 1 },
    { title: "Pro", value: 2 },
    { title: "Star", value: 3 },
  ];

  const currentYear = moment().format("YYYY");
  const currentMonth = moment().format("MM");

  // Initialize activeChallengesMission as an empty array
  const [activeChallengesMission, setActiveChallengesMission] = useState([]);

  const [create, setCreate] = useState(false);
  const [selectedLeague, setSelectedLeague] = useState(leagueList[0]);
  const [selectedPlateform, setSelectedPlateform] = useState(platformItems[0]);
  
  const [from, setFrom] = useState(
    moment(`${currentYear}-${currentMonth}-10 00:00:01`).format("YYYY-MM-DDTHH:mm")
  );
  const [to, setTo] = useState(
    moment(`${currentYear}-${currentMonth}-28 23:59:59`).format("YYYY-MM-DDTHH:mm")
  );

  const [goalXp, setGoalXp] = useState("");
  const [prizePlatformAmount, setPrizePlatformAmount] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [currentMissionId, setCurrentMissionId] = useState(null);

  const [error,setError] = useState('');

  const roundToTwoDecimalPlaces = (input) => {
    const number = parseFloat(input);

    if (typeof number === "number" && !Number.isNaN(number) && number !== 0) {
      return parseFloat(number.toFixed(2)).toLocaleString();
    }

    return isNaN(number) ? 0 : input;
  };

  const handleCheckboxChange = (challengeId) => {
    setActiveChallengesMission((prev) => {
      if (prev.includes(challengeId)) {
        return prev.filter((id) => id !== challengeId);
      } else {
        return [...prev, challengeId];
      }
    });
  };

  const handleLeagueChange = (selectedLeague) => {
    setSelectedLeague(selectedLeague);
  
    const currentYear = moment().format("YYYY");
    const currentMonth = moment().format("MM");
  
    // Update dates based on the selected league
    if (selectedLeague.value === 1) {
      // Amateur league
      setFrom(
        moment(`${currentYear}-${currentMonth}-10 00:00:01`, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm")
      );
    } else if (selectedLeague.value === 2 || selectedLeague.value === 3) {
      // Pro or Star league
      setFrom(
        moment(`${currentYear}-${currentMonth}-15 00:00:01`, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm")
      );
    }
  
    setTo(
      moment(`${currentYear}-${currentMonth}-28 23:59:59`, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm")
    );
  };
  

  const openCreateMission = () => {
    setSelectedLeague(leagueList[0]);
    setSelectedPlateform(platformItems[0]);
  
    setGoalXp("");
    setPrizePlatformAmount("");
    setActiveChallengesMission([]);
    setCreate(true);
    setEditMode(false); // Ensure edit mode is off when opening the form
    setCurrentMissionId(null); // Reset current mission id
  };  
  

  const fetchMission = async (item) => {
    let response = await getMissionDetail(item);
    let mission = response.content;
    
    // Set state with fetched mission details
    setSelectedLeague(leagueList.find((league) => league.value === mission.league_id) || leagueList[0]);
    setGoalXp(mission.goal_xp || "");
    setSelectedPlateform(
      platformItems.find((item) => item.id === mission.prize_platform_item_id) || platformItems[0]
    );
    setPrizePlatformAmount(mission.prize_platform_item_amount || "");
    setFrom(moment(mission.starts_at).utc().format("YYYY-MM-DD HH:mm"));
    setTo(moment(mission.ends_at).utc().format("YYYY-MM-DD HH:mm"));
    setActiveChallengesMission(mission.challenges_ids || []);
    
    setCreate(true);
    setEditMode(true); // Set to edit mode
    setCurrentMissionId(item); // Set current mission id
  };
  

  const handleCreateOrUpdateData = async () => {
    const body = {
      league: selectedLeague.value,
      goal_xp: goalXp,
      prize_platform_item_id: selectedPlateform.id,
      prize_platform_item_amount: prizePlatformAmount,
      starts_at: moment(from).format("YYYY-MM-DD HH:mm:ss"),
      ends_at: moment(to).format("YYYY-MM-DD HH:mm:ss"),
      challenges: activeChallengesMission
    };
  
    // Validate the body parameters
    const validationError = validateBodyParameters(body);
  
    if (validationError) {
      setError(validationError);
      return false;
    }
  
    let response;
    if (editMode) {
      // Update existing mission
      body.id = currentMissionId;
      response = await updateMission(body);
    } else {
      // Create new mission
      response = await postMission(body);
    }
  
    if (response.data.result.status === "1") {
      fetchMissionList();
      setCreate(false);
      setEditMode(false); // Ensure edit mode is off
      setCurrentMissionId(null); // Reset current mission id
    } else {
      setError(response.data.result.err_description);
    }
  };
  
  // Validation function to check for empty or undefined parameters
  const validateBodyParameters = (body) => {
    if (!body.league) return "League is empty";
    if (!body.goal_xp) return "Goal XP is empty";
    if (!body.prize_platform_item_id) return "Prize platform item ID is empty";
    if (!body.prize_platform_item_amount) return "Prize platform item amount is empty";
    if (!body.starts_at) return "Start date is empty";
    if (!body.ends_at) return "End date is empty";
    //if (!body.challenges || body.challenges.length === 0) return "Challenges are empty";
    return null;
  };
  

  return (
    <>
      <div className="bg-white w-full">
        <div className="overflow-x-auto">
          <table className="w-full min-w-[800px]">
            <thead>
              <tr>
                <th className="border border-l-0 text-center font-medium text-gray-600 p-[8px] text-[14px] whitespace-nowrap">Id</th>
                <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center">Created at</th>
                <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center">Started at</th>
                <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center">End at</th>
                <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left">League</th>
                <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left">Challenges ids</th>
                <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left">Total_points</th>
                <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left">Goal XP</th>
                <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left">Prize</th>
              </tr>
            </thead>
            <tbody>
              {loader ? (
                <tr>
                  <td colSpan={9}>
                    <div className="text-center p-[28px] flex items-center justify-center">
                      <BiLoaderCircle size={62} className="animate-[spin_3s_linear_infinite] text-[#0080A6]" />
                    </div>
                  </td>
                </tr>
              ) : (
                tokenomics?.map((item) => (
                  <tr className='cursor-pointer' onClick={() => fetchMission(item.id)} key={item.id}>
                    <th className="border first:border-l-0 last:border-r-0 text-center font-medium text-gray-600 p-[8px] text-[14px] whitespace-nowrap">
                      {item.id}
                    </th>
                    <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-center text-gray-600 cursor-pointer">
                      {moment(item.created_at).utc().format("YYYY-MM-DD HH:mm:ss")}
                    </th>
                    <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-center text-gray-600 cursor-pointer">
                      {moment(item.starts_at).utc().format("YYYY-MM-DD HH:mm:ss")}
                    </th>
                    <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center">
                      {moment(item.ends_at).utc().format("YYYY-MM-DD HH:mm:ss")}
                    </th>
                    <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left">
                      {item.league}
                    </th>
                    <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left">
                      {item.challenges_ids.join(', ')}
                    </th>
                    <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left">
                      {roundToTwoDecimalPlaces(item.total_points)}
                    </th>
                    <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left">
                      {roundToTwoDecimalPlaces(item.goal_xp)}
                    </th>
                    <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left">
                      {roundToTwoDecimalPlaces(item.prize_platform_item_amount)} {item.prize_platform_item_title}
                    </th>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="tokenomics-pagination my-10 mr-6">
          <ReactPaginate
            breakLabel="..."
            nextLabel={null}
            previousLabel={null}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pagination?.totalPages}
            forcePage={pagination?.previousPage}
            marginPagesDisplayed={2}
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
      <div className="flex items-center gap-[10px] w-full">
        Click here to add a new Mission
        <button
          onClick={openCreateMission}
          className="h-[40px] px-8 text-[16px] text-white flex items-center justify-center bg-[#0080a6]"
        >
          Add a new mission
        </button>
      </div>
      {create && (
        <div className="p-6 rounded-[2px] border bg-white w-full mt-6">
          <div className="grid grid-cols-12 gap-[2vw]">
            <div className="col-span-4 flex flex-col gap-[20px]">
              <div className="flex items-center justify-between gap-[10px] w-full">
                League
                <div className="w-[220px]">
                  <ListBoxSelect
                    options={leagueList}
                    selected={selectedLeague}
                    setSelected={handleLeagueChange}
                  />
                </div>
              </div>
              <div className="flex items-center justify-between gap-[10px] w-full">
                Goal XP
                <div className="w-[220px]">
                  <input
                    required
                    type="text"
                    value={goalXp}
                    onChange={(e) => setGoalXp(e.target.value)}
                    className="h-[40px] px-3 border w-full outline-0 border-[#a8a8a8] placeholder:text-white"
                  />
                </div>
              </div>
              <div className="flex items-center justify-between gap-[10px] w-full">
                Prize platform item
                <div className="w-[220px]">
                  <ListBoxSelect
                    options={platformItems}
                    selected={selectedPlateform}
                    setSelected={setSelectedPlateform}
                  />
                </div>
              </div>
              <div className="flex items-center justify-between gap-[10px] w-full">
                Prize platform amount
                <div className="w-[220px]">
                  <input
                    required
                    type="text"
                    value={prizePlatformAmount}
                    onChange={(e) => setPrizePlatformAmount(e.target.value)}
                    className="h-[40px] px-3 border w-full outline-0 border-[#a8a8a8] placeholder:text-white"
                  />
                </div>
              </div>
              <div className="flex items-center justify-between gap-[10px] w-full">
                Starts at
                <div className="relative w-[220px]">
                  <input
                    type="datetime-local"
                    value={from}
                    onChange={(e) => setFrom(e.target.value)}
                    className="h-[40px] px-3 border w-full outline-0 border-[#a8a8a8]"
                  />
                </div>
              </div>
              <div className="flex items-center justify-between gap-[10px] w-full">
                Finish at
                <div className="relative w-[220px]">
                  <input
                    type="datetime-local"
                    value={to}
                    onChange={(e) => setTo(e.target.value)}
                    className="h-[40px] px-3 border w-full outline-0 border-[#a8a8a8]"
                  />
                </div>
              </div>


            </div>
            <div className="col-span-6">
              <h3 className='mb-3'>Select the challenge of the mission</h3>
              <div className='flex flex-col'>
                {challengesMission.map((challenge) => (
                  <div key={challenge.challenge_id} className="mb-2 flex items-center">
                    <label
                      htmlFor={`active-${challenge.challenge_id}`}
                      className="cursor-pointer flex items-center text-[15px] font-normal"
                    >
                      <input
                        id={`active-${challenge.challenge_id}`}
                        type="checkbox"
                        className="hidden peer"
                        value={activeChallengesMission.includes(challenge.challenge_id)}
                        checked={activeChallengesMission.includes(challenge.challenge_id)}
                        onChange={() => handleCheckboxChange(challenge.challenge_id)}
                      />
                      <span className="mr-3 peer-checked:bg-[#0080A6] peer-checked:border-[#0080A6] bg-white h-[16px] min-w-[16px] before:content-[''] relative before:absolute before:bottom-[5.55px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border-[1.5px] border-[#ccc] rounded-sm"></span>
                      {challenge.title} (exp: {challenge.exp})
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className='col-span-2 flex justify-between items-end mb-2'>
              <p className='text-red-500'>{error}</p>
              <button
                onClick={handleCreateOrUpdateData}
                className="h-[40px] px-8 text-[16px] text-white flex items-center justify-center bg-[#0080a6]"
              >
                {editMode ? "Update Mission" : "Create Mission"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TokenomicsTable;

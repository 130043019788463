import React from "react";
import { BiLoaderCircle } from "react-icons/bi";

const AccountsTable = ({
  accountsTable,
  fetchSalesFunnel,
  updateActiveUser,
  fetchSalesROI,
  type,
  routeUrl,
  loader,
}) => {
  const roundToTwoDecimalPlaces = (input) => {
    const number = parseFloat(input);

    if (typeof number === "number" && !Number.isNaN(number) && number !== 0) {
      const roundedNumber = parseFloat(number.toFixed(2));
      return roundedNumber.toLocaleString();
    }

    if (isNaN(number)) {
      return 0;
    }

    return input;
  };

  return (
    <div className="bg-white w-full">
      <div className="overflow-x-auto">
        <table className="w-full min-w-[800px]">
          <thead>
            <tr>
              <th className="border-r border-b py-[6px] px-[3px] text-[12px] whitespace-nowrap"></th>
              <th></th>
              {routeUrl&&
                <th
                  colSpan="2"
                  className="uppercase py-[6px] px-[3px] text-[12px] whitespace-nowrap"
                >
                  STREAM
                </th>
              }
              <th
                colSpan="3"
                className="uppercase py-[6px] px-[3px] text-[12px] whitespace-nowrap border-r"
              >
                New Accounts
              </th>
              <th
                colSpan="5"
                className="uppercase py-[6px] px-[3px] text-[12px] whitespace-nowrap border-r"
              >
                New Accounts Challenges
              </th>
              <th
                colSpan="7"
                className="uppercase py-[6px] px-[3px] text-[12px] whitespace-nowrap border-r"
              >
                Kips in the period for all Active accounts
              </th>
              <th
                colSpan="4"
                className="uppercase py-[6px] px-[3px] text-[12px] whitespace-nowrap border-r"
              >
                HEROES IN PERIOD ALL ACTIVE ACC.
              </th>
              <th
                colSpan="5"
                className="uppercase py-[6px] px-[3px] text-[12px] whitespace-nowrap"
              >
                Avg games played
              </th>
            </tr>
            <tr>
              <th className="border border-l-0 py-[6px] px-[3px] text-[12px] whitespace-nowrap text-left">
                Kol/Streamer
              </th>
              <th className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-center">
                Status
              </th>
              {routeUrl&&
                <>
                  <th className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-center">
                    Times
                  </th>
                  <th className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-center">
                    Days
                  </th>
                </>
              }
              <th className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                New
              </th>
              <th className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                Active
              </th>
              <th className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                Paying
              </th>
              <th className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                0
              </th>
              <th className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                Min 1
              </th>
              <th className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                Min 10
              </th>
              <th className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                Min 25
              </th>
              <th className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                Min 50
              </th>
              <th className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                Sales
              </th>
              <th className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right uppercase">
                ARPU
              </th>
              <th className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right uppercase">
                ARPPU
              </th>
              <th className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right uppercase">
                Income
              </th>
              <th className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right uppercase">
                Cost
              </th>
              <th className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right uppercase">
                ROI
              </th>
              <th className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right uppercase">
                COMIS
              </th>
              <th className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                New Borrow
              </th>
              <th className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                Min. 1
              </th>
              <th className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                Min. 2
              </th>
              <th className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                Min. 3
              </th>
              <th className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right uppercase">
                DR
              </th>
              <th className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right uppercase">
                PB
              </th>
              <th className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right uppercase">
                SR
              </th>
              <th className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap uppercase text-right">
                CC
              </th>
              <th className="border border-r-0 py-[6px] px-[3px] text-[12px] whitespace-nowrap uppercase text-right">
                MECHS
              </th>
            </tr>
          </thead>
          <tbody>
            {loader ? (
              <tr>
                <td colSpan={25}>
                  <div className="text-center p-[28px] flex items-center justify-center">
                    <BiLoaderCircle
                      size={62}
                      className="animate-[spin_3s_linear_infinite] text-[#0080A6]"
                    />
                  </div>
                </td>
              </tr>
            ) : (
              accountsTable?.map((account, key) => {
                return (
                  <tr key={key}>
                    {type ? (
                      <th
                        className={`${
                          account.active
                            ? "text-black font-bold"
                            : "text-[#5468DB]"
                        } border border-l-0 py-[6px] px-[3px] text-left text-[12px] whitespace-nowrap  cursor-pointer`}
                        onClick={() => {
                          fetchSalesFunnel(account?.referal_code);
                          updateActiveUser(account);
                          fetchSalesROI(account?.referal_code);
                        }}
                      >
                        {account.nickname_soga}
                      </th>
                    ) : (
                      <th
                        className={`${
                          account.active
                            ? "text-black font-bold"
                            : "text-[#5468DB]"
                        } border border-l-0 py-[6px] px-[3px] text-left text-[12px] whitespace-nowrap  cursor-pointer`}
                      >
                        {account.nickname_soga}
                      </th>
                    )}
                    <td className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                      {account.status}
                    </td>
                    {routeUrl&&
                      <>
                        <td className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                          {account.stream.times}
                        </td>
                        <td className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                          {account.stream.days}
                        </td>
                      </>
                    }
                    <td className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                      {roundToTwoDecimalPlaces(account.new_accounts)}
                    </td>
                    <td className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                      {roundToTwoDecimalPlaces(account.playing)}%
                    </td>
                    <td className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                      {roundToTwoDecimalPlaces(account.paying)}%
                    </td>
                    <td className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                      {roundToTwoDecimalPlaces(account.challenges["0"])}%
                    </td>
                    <td className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                      {roundToTwoDecimalPlaces(account.challenges["min_1"])}%
                    </td>
                    <td className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                      {roundToTwoDecimalPlaces(account.challenges["min_10"])}%
                    </td>
                    <td className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                      {roundToTwoDecimalPlaces(account.challenges["min_25"])}%
                    </td>
                    <td className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                      {roundToTwoDecimalPlaces(account.challenges["min_50"])}%
                    </td>
                    <td className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                      {roundToTwoDecimalPlaces(account.items_sold)}
                    </td>
                    <td className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                      ${roundToTwoDecimalPlaces(account.arpu)}
                    </td>
                    <td className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                      ${roundToTwoDecimalPlaces(account.arppu)}
                    </td>
                    <td className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                      ${roundToTwoDecimalPlaces(account.total_income)}
                    </td>
                    <td className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                      ${roundToTwoDecimalPlaces(account.cost)}
                    </td>
                    <td className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                      {roundToTwoDecimalPlaces(account.roi)}
                    </td>
                    <td className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                      ${roundToTwoDecimalPlaces(account.kol_commission)}
                    </td>
                    <td className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                      {roundToTwoDecimalPlaces(account.heroes.borrowed)}%
                    </td>
                    <td className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                      {roundToTwoDecimalPlaces(account.heroes.min_1)}%
                    </td>
                    <td className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                      {roundToTwoDecimalPlaces(account.heroes.min_2)}%
                    </td>
                    <td className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                      {roundToTwoDecimalPlaces(account.heroes.min_3)}%
                    </td>
                    <td className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                      {account.games.dr === "Infinity"
                        ? 0
                        : roundToTwoDecimalPlaces(account.games.dr)}
                    </td>
                    <td className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                      {account.games.pb === "Infinity"
                        ? 0
                        : roundToTwoDecimalPlaces(account.games.pb)}
                    </td>
                    <td className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                      {account.games.sr === "Infinity"
                        ? 0
                        : roundToTwoDecimalPlaces(account.games.sr)}
                    </td>
                    <td className="border py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                      {account.games.cc === "Infinity"
                        ? 0
                        : roundToTwoDecimalPlaces(account.games.cc)}
                    </td>
                    <td className="border border-r-0 py-[6px] px-[3px] text-[12px] whitespace-nowrap text-right">
                      {account.games.mechs === "Infinity"
                        ? 0
                        : roundToTwoDecimalPlaces(account.games.mechs)}
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AccountsTable;

import ListBoxSelect from "./components/ListBoxSelect";
import { useEffect, useState } from "react";
import TokenomicsTable from "./components/TokenomicsTable";
import moment from "moment";
import { getTokenomicsSales } from "../../api";
import { BiLoaderCircle } from "react-icons/bi";

const statusList = [
  { title: "All records", value: 1 },
  { title: "Only sales", value: 99 },
  { title: "Only failed", value: -99 },
];

export default function KolManager() {
  const [from, setFrom] = useState(
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );
  const [to, setTo] = useState(moment().format("YYYY-MM-DD"));
  const [status, setStatus] = useState(1);
  const [page, setPage] = useState(1);
  const [itemPage, setItemPage] = useState(20);
  const [tokenomics, setTokenomics] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    fetchTokenomicsSales();
  }, [page]);

  const handlePageClick = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
  };

  const fetchTokenomicsSales = async () => {
    setLoader(true);
    try {
      let data = await getTokenomicsSales(from, to, page, itemPage, status);
      setTokenomics(data);
    } catch (error) {
      console.error(error);
    }
    setLoader(false);
  };

  const selectStatus = async (param) => {
    setStatus(param.value);
  };

  const filter = async () => {
    setPage(1);
    setLoader(true);
    await fetchTokenomicsSales();
    setLoader(false);
  };

  return (
    <div className="flex flex-col items-center h-full p-6 gap-6">
      <div className="p-6 rounded-[2px] border bg-white w-full">
        {/* <p className="text-[16px] font-medium text-black">
          Select the date and reload the dashboard
        </p>
        <p className="text-[16px] font-medium text-black">
          [Current month]{" "}
          <span className="text-[#5468DB]">
            [Same period int the last month] [last 30 days] [last 60 days] [last
            90 days]
          </span>
        </p> */}
        <div className="flex items-center gap-5 mt-2">
          <label>Creation date, from</label>
          <div className="relative w-[120px]">
            <input
              type="text"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              className="h-[40px] px-3 border w-full outline-0 border-[#E8E7E4] placeholder:text-white"
            />
            <input
              type="date"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              className="absolute inset-0 opacity-0 z-1"
            />
          </div>
          <label>to</label>
          <div className="relative w-[120px]">
            <input
              type="text"
              value={to}
              onChange={(e) => setTo(e.target.value)}
              className="h-[40px] px-3 border w-full outline-0 border-[#E8E7E4] placeholder:text-white"
            />
            <input
              type="date"
              value={to}
              onChange={(e) => setTo(e.target.value)}
              className="absolute inset-0 opacity-0 z-1"
            />
          </div>
          <div className="w-[150px]">
            <ListBoxSelect options={statusList} onChange={selectStatus} />
          </div>
          {loader ? (
            <button className="h-[40px] px-8 text-[16px] text-white flex items-center justify-center bg-[#0080A6]">
              <BiLoaderCircle
                size={22}
                className="animate-[spin_3s_linear_infinite]"
              />
            </button>
          ) : (
            <button
              onClick={filter}
              className="h-[40px] px-8 text-[16px] text-white flex items-center justify-center bg-[#0080A6]"
            >
              Reload
            </button>
          )}
        </div>
      </div>
      <TokenomicsTable
        tokenomics={tokenomics?.content}
        pagination={tokenomics?.pagination}
        handlePageClick={handlePageClick}
        status={status}
        loader={loader}
      />
    </div>
  );
}

import { Fragment } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { IoCaretDownSharp } from "react-icons/io5";

export default function AutocompleteBox({
  setQuery,
  filteredName,
  query,
  handleFilterName,
  handleKolTypeForm,
  selected,
  setSelected,
}) {
  return (
    <Combobox
      value={selected}
      onChange={(e) => {
        setSelected(e);
        handleKolTypeForm(e.id, "player_id");
      }}
    >
      <div className="relative w-full z-50">
        <div className="relative w-full cursor-pointer overflow-hidden border border-[#E8E7E4]">
          <Combobox.Button>
            <Combobox.Input
              className="w-full border-none py-2 pl-3 pr-10 text-[16px] leading-5 text-gray-900 focus:ring-0 outline-0"
              displayValue={(name) => name.nickname}
              onChange={(event) => handleFilterName(event.target.value)}
            />
            <span className="absolute inset-y-0 right-0 flex items-center pr-2">
              <IoCaretDownSharp className="h-5 w-5 text-black" />
            </span>
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery("")}
        >
          <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredName.length === 0 && query !== "" ? (
              <div className="relative cursor-pointer select-none py-2 px-3 text-gray-700">
                Nothing found.
              </div>
            ) : (
              filteredName.map((name, index) => (
                <Combobox.Option
                  key={index}
                  className={({ active }) =>
                    `relative cursor-pointer select-none py-2 px-3 ${
                      active ? "bg-[#0080A6] text-white" : "text-gray-900"
                    }`
                  }
                  value={name}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {name.nickname}
                      </span>
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
}

import { useState } from "react";
import SocialLogo from "../../../assets/images/logo.png";
import { NavLink } from "react-router-dom";
export default function Login() {
  const [show, setShow] = useState(false);
  const handleContinue = (e) => {
    e.preventDefault();
    setShow(true);
  };

  return (
    <div className="h-screen flex flex-col container-fluid">
      <div className="w-full">
        <div className="w-full rounded-xl flex justify-between flex-wrap items-center p-4">
          <h6 className="text-[20px] font-semibold">Site name</h6>
          <p className="text-[16px] text-black hover:opacity-80">
            Already have an account?{" "}
            <NavLink
              to="/"
              className="text-[16px] text-primary hover:opacity-80"
            >
              Sign in.
            </NavLink>
          </p>
        </div>
      </div>
      <div className="p-4 max-w-[650px] w-full h-full mx-auto grow mt-5">
        <div className="flex-grow flex flex-col items-center justify-center w-full space-y-4">
          <img
            src={SocialLogo}
            alt="Social Games Logo"
            className={`w-[300px] mx-auto`}
          />
          <h1 className="text-black !mb-5 text-[1.6rem] font-semibold text-center">
            Welcome to Site Name
          </h1>
          <form className="w-full border p-4 rounded-[4px]">
            {!show && (
              <select className="w-full h-11 border rounded-[8px] px-2">
                <option>United States</option>
                <option>Austria</option>
                <option>Belgium</option>
              </select>
            )}
            {show && (
              <div>
                <label className="text-[15px] text-black">
                  Hardware Serial
                </label>
                <input
                  id="hardware"
                  type="text"
                  placeholder="XXXX-XXX-XXX "
                  className="mt-1 placeholder:text-slate-700 h-11 block px-3 w-full rounded-xl bg-gray-100 shadow-none focus:outline-0 focus:ring-0 border-none"
                  required
                  autofocus
                />
              </div>
            )}

            <div className="flex items-center justify-end mt-4">
              <button
                disabled={show ? true : false}
                onClick={handleContinue}
                className="fs-14 Regular h-11 w-full text-white bg-blue-500 hover:bg-blue-500 focus:bg-blue-500 hover:opacity-90 normal-case rounded-xl justify-center disabled:opacity-75"
              >
                Continue
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

import { NavLink } from "react-router-dom";

export default function NotFound() {
  return (
    <div className="h-screen flex items-center justify-center text-center">
      <div className="w-3/12">
        <h3 className="text-[50px] font-bold text-black mb-5 mr-1 leading-[1]">
          404
        </h3>
        <p className="text-[20px] font-medium text-black mb-2">Not Found</p>
        <NavLink
          to="/dashboard"
          className={({ isActive, isPending }) =>
            `${
              isPending ? "pending" : isActive ? "actives" : ""
            } underline font-medium px-4 py-2`
          }
        >
          Go Home
        </NavLink>
      </div>
    </div>
  );
}

import React from "react";
import { BiLoaderCircle } from "react-icons/bi";
import IconCoin from "assets/images/icon_gold.png";
import IconDiamond from "assets/images/icon_diamond.png";
import IconStar from "assets/images/icon_silverstar.png";
import IconSoga from "assets/images/icon_SOGA.png";
import IconMatic from "assets/images/matic.png";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { AiOutlineCaretDown } from "react-icons/ai";

const TournamentTable = ({
  list,
  loader,
  handlePageClick,
  pagination,
  sortStatusTable,
  sortBy,
  sortDir,
}) => {
  const roundToTwoDecimalPlaces = (input) => {
    const number = parseFloat(input);

    if (typeof number === "number" && !Number.isNaN(number) && number !== 0) {
      const roundedNumber = parseFloat(number.toFixed(2));
      return roundedNumber.toLocaleString();
    }

    if (isNaN(number)) {
      return 0;
    }

    return input;
  };

  return (
    <div className="bg-white w-full">
      <div className="overflow-x-auto">
        <table className="w-full min-w-[800px]">
          <thead>
            <tr>
              <th
                onClick={() => sortStatusTable("id")}
                className="border font-semibold border-l-0 py-[8px] px-[5px] text-[13px] whitespace-nowrap text-center group cursor-pointer"
              >
                <div className="flex items-center justify-center gap-1">
                  Id
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${sortBy === "id" ? "" : "hidden"} ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("created_at")}
                className="border font-semibold py-[8px] px-[5px] text-[13px] whitespace-nowrap text-left group cursor-pointer"
              >
                <div className="flex items-center gap-1">
                  Created at
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "created_at" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("owner")}
                className="border font-semibold py-[8px] px-[5px] text-[13px] whitespace-nowrap text-left group cursor-pointer"
              >
                <div className="flex items-center gap-1">
                  Owner
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "owner" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("renter")}
                className="border font-semibold py-[8px] px-[5px] text-[13px] whitespace-nowrap text-left group cursor-pointer"
              >
                <div className="flex items-center gap-1">
                  Renter
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "owner" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("rented_at")}
                className="border font-semibold py-[8px] px-[5px] text-[13px] whitespace-nowrap text-left group cursor-pointer"
              >
                <div className="flex items-center gap-1">
                  Rented at
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "owner" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("collection")}
                className="border font-semibold py-[8px] px-[5px] text-[13px] whitespace-nowrap text-left group cursor-pointer"
              >
                <div className="flex items-center gap-1">
                  Collection
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "collection" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("hero_name")}
                className="border font-semibold py-[8px] px-[5px] text-[13px] whitespace-nowrap group cursor-pointer text-center"
              >
                <div className="flex items-center justify-center gap-1">
                  Hero Name
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "hero_name" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("current_rank")}
                className="border font-semibold py-[8px] px-[5px] text-[13px] whitespace-nowrap group cursor-pointer text-center"
              >
                <div className="flex items-center justify-center gap-1">
                  Rank
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "current_rank" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("current_level")}
                className="border font-semibold py-[8px] px-[5px] text-[13px] whitespace-nowrap group cursor-pointer text-center"
              >
                <div className="flex items-center justify-center gap-1">
                  Level
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "current_level" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("power_with_equipment")}
                className="border font-semibold py-[8px] px-[5px] text-[13px] whitespace-nowrap group cursor-pointer text-center"
              >
                <div className="flex items-center justify-center gap-1">
                  Power with <div /> Equipment
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "power_with_equipment" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("power_without_equipment")}
                className="border font-semibold py-[8px] px-[5px] text-[13px] whitespace-nowrap group cursor-pointer text-center"
              >
                <div className="flex items-center justify-center gap-1">
                  Power without <div /> Equipment
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "power_without_equipment" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("rental_status")}
                className="border font-semibold py-[8px] px-[5px] text-[13px] whitespace-nowrap group cursor-pointer text-center"
              >
                <div className="flex items-center justify-center gap-1">
                  Rental status
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "rental_status" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th className="border font-semibold py-[8px] px-[5px] text-[13px] whitespace-nowrap group cursor-pointer text-center">
                <img src={IconCoin} className="w-5 mx-auto" alt="Coin" />
              </th>
              <th className="border font-semibold py-[8px] px-[5px] text-[13px] whitespace-nowrap group cursor-pointer text-center">
                <img src={IconDiamond} className="w-5 mx-auto" alt="Diamond" />
              </th>
              <th className="border font-semibold py-[8px] px-[5px] text-[13px] whitespace-nowrap group cursor-pointer text-center uppercase">
                <img src={IconStar} className="w-5 mx-auto" alt="Star" />
              </th>
              <th className="border font-semibold py-[8px] px-[5px] text-[13px] whitespace-nowrap group cursor-pointer text-center uppercase">
                <img src={IconSoga} className="w-5 mx-auto" alt="Soga" />
              </th>
              <th className="border font-semibold border-r-0 py-[8px] px-[5px] text-[13px] whitespace-nowrap group cursor-pointer uppercase text-center">
                <img src={IconMatic} className="w-5 mx-auto" alt="Matic" />
              </th>
            </tr>
          </thead>
          <tbody>
            {loader ? (
              <tr>
                <td colSpan={25}>
                  <div className="text-center p-[28px] flex items-center justify-center">
                    <BiLoaderCircle
                      size={62}
                      className="animate-[spin_3s_linear_infinite] text-[#0080A6]"
                    />
                  </div>
                </td>
              </tr>
            ) : (
              list?.map((item, key) => {
                return (
                  <tr key={key}>
                    <td className="border py-[8px] px-[5px] text-[13px] whitespace-nowrap text-center">
                      {item.id || "-"}
                    </td>
                    <td className="border py-[8px] px-[5px] text-[13px] whitespace-nowrap text-left">
                      {moment(item.created_at).format("YYYY/MM/DD HH:mm") ||
                        "-"}
                    </td>
                    <td className="border py-[8px] px-[5px] text-[13px] whitespace-nowrap text-left">
                      @{item.owner || "-"}
                    </td>
                    <td className="border py-[8px] px-[5px] text-[13px] whitespace-nowrap text-left">
                      {item.renter && <>@</>}  
                      {item.renter || "-"}
                    </td>
                    <td className="border py-[8px] px-[5px] text-[13px] whitespace-nowrap text-left">
                      {item.rented_at ? moment(item.rented_at).format("YYYY/MM/DD HH:mm") : "-"}
                    </td>
                    <td className="border py-[8px] px-[5px] text-[13px] whitespace-nowrap text-left">
                      {item.collection || "-"}
                    </td>
                    <td className="border py-[8px] px-[5px] text-[13px] whitespace-nowrap text-center">
                      {item.hero_name || "-"}
                    </td>
                    <td className="border py-[8px] px-[5px] text-[13px] whitespace-nowrap text-center">
                      {item.current_rank || "-"}
                    </td>
                    <td className="border py-[8px] px-[5px] text-[13px] whitespace-nowrap text-center">
                      {item.current_level || "-"}
                    </td>
                    <td className="border py-[8px] px-[5px] text-[13px] whitespace-nowrap text-center">
                      {item.power_with_equipment || "-"}
                    </td>
                    <td className="border py-[8px] px-[5px] text-[13px] whitespace-nowrap text-center">
                      {item.power_without_equipment || "-"}
                    </td>
                    <td className="border py-[8px] px-[5px] text-[13px] whitespace-nowrap text-center">
                      {item.rental_status || "-"}
                    </td>
                    <td className="border py-[8px] px-[5px] text-[13px] whitespace-nowrap text-center">
                      {item.coin_platform_item_id == "1"
                        ? `${item.rentals_coins_amount}:${item.rentals_amount}`
                        : "0:0"}
                    </td>
                    <td className="border py-[8px] px-[5px] text-[13px] ">
                      <div className="flex justify-center gap-2">
                        {item.coin_platform_item_id == "2"
                          ? `${item.rentals_coins_amount}:${item.rentals_amount}`
                          : "0:0"}
                      </div>
                    </td>
                    <td className="border py-[8px] px-[5px] text-[13px] ">
                      <div className="flex justify-center gap-2">
                        {item.coin_platform_item_id == "3"
                          ? `${item.rentals_coins_amount}:${item.rentals_amount}`
                          : "0:0"}
                      </div>
                    </td>
                    <td className="border py-[8px] px-[5px] text-[13px] ">
                      <div className="flex justify-center gap-2">
                        {item.coin_platform_item_id == "18"
                          ? `${item.rentals_coins_amount}:${item.rentals_amount}`
                          : "0:0"}
                      </div>
                    </td>
                    <td className="border py-[8px] px-[5px] text-[13px] whitespace-nowrap text-center">
                      {item.coin_platform_item_id == "24"
                        ? `${item.rentals_coins_amount}:${item.rentals_amount}`
                        : "0:0"}
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <div className="tokenomics-pagination my-10 mr-6">
        <ReactPaginate
          breakLabel="..."
          nextLabel={null}
          previousLabel={null}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pagination?.totalPages}
          forcePage={pagination?.previousPage}
          marginPagesDisplayed={2}
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
};

export default TournamentTable;

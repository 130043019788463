import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { IoCaretDownSharp } from "react-icons/io5";

export default function ListBoxSelect({
  options,
  selected,
  setSelected,
  placeholder,
}) {
  return (
    <Listbox value={selected} onChange={(e) => setSelected(e)}>
      <div className="relative w-full">
        <Listbox.Button className="relative w-full cursor-pointer text-left border border-[#E8E7E4] h-[40px]">
          <span
            className={`${
              selected ? "text-black" : "text-black/50"
            } block truncate pl-3 pr-10 text-[14px] font-regular`}
          >
            {selected && Object.keys(selected).length !== 0
              ? selected.title
              : placeholder}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <IoCaretDownSharp
              className="h-5 w-5 text-black"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute mt-1 z-50 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {options.map((option, key) => (
              <Listbox.Option
                key={key}
                className={({ active }) =>
                  ` ${active ? "bg-[#0080A6] text-white" : "text-gray-900"}`
                }
                value={option}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate relative cursor-pointer select-none py-2 px-3 ${
                        selected
                          ? "font-medium bg-[#0080A6] text-white"
                          : "font-normal"
                      }`}
                    >
                      {option.title}
                    </span>
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}

import { useEffect, useState } from "react";
import PlayerProgressTable from "./components/PlayerProgressTable";
import ListBoxSelect from "./components/ListBoxSelect";
import { BiLoaderCircle } from "react-icons/bi";
import moment from "moment";
import CountrySelect from "./components/CountrySelect";
import api, { getAllKol } from "api";
import ReferalCodeSelect from "./components/ReferalCodeSelect";

const transactionList = [
  { title: "Transac. in the last 30 days", value: 30 },
  { title: "Transac. in the last 60 days", value: 60 },
  { title: "Transac. in the last 90 days", value: 90 },
  { title: "Transac. in the last 180 days", value: 180 },
  { title: "Transac. in all the period", value: "All" },
];

export default function NFTStatus() {
  const [from, setFrom] = useState(
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );
  const [to, setTo] = useState(moment().format("YYYY-MM-DD"));
  const [kolList, setKolList] = useState([]);
  const [nickname, setNickname] = useState("");
  const [filterBy, setFilterBy] = useState("created_at");
  const [referalCode, setReferalCode] = useState({});
  const [page, setPage] = useState(1);
  const itemPage = useState(20)[0];
  const [sortBy, setSortBy] = useState("created_at");
  const [sortByDir, setSortByDir] = useState("asc");
  const [playerProgress, setPlayerProgress] = useState([]);
  const [loader, setLoader] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCounttry] = useState({});
  const [transaction, setTransaction] = useState(transactionList[0]);

  useEffect(() => {
    fetchPlayerProgress();
  }, [page, sortBy, sortByDir]);
  useEffect(() => {
    fetchCountries();
    fetchKOLAll();
  }, []);

  const fetchPlayerProgress = async () => {
    try {
      const response = await api.get(
        `/player_progress/all?filter_by=${filterBy}&from_date=${from}&to_date=${to}&nickname=${nickname}&transaction_days=${
          transaction.value
        }&country=${selectedCountry.iso_code || ""}&referal_code=${
          referalCode?.referal_code || ""
        }&page=${page}&item_page=${itemPage}&sortby=${sortBy}&sortby_dir=${sortByDir}`
      );
      setPlayerProgress(response?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchKOLAll = async () => {
    try {
      let data = await getAllKol();
      if (data && data.content) {
        data.content.unshift({
          nickname: "KOL",
          referal_code: "",
        });
        setKolList(data.content);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCountries = async () => {
    try {
      let data = await api.get("/kol/countries");
      const first = { title: "Country", iso_code: "" };
      const country = data?.data?.content;
      country.unshift(first);
      setCountries(country);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePageClick = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
  };

  const sortPlayerProgress = (sortVal) => {
    if (sortVal === sortBy) {
      sortByDir === "asc" ? setSortByDir("desc") : setSortByDir("asc");
    } else {
      setSortByDir("asc");
      setSortBy(sortVal);
    }
  };

  const filter = async () => {
    setLoader(true);
    setSortByDir("asc");
    setPage(1);
    if (page === 1) {
      await fetchPlayerProgress();
    }
    setLoader(false);
  };
  return (
    <div className="flex flex-col items-center h-full p-6 gap-6">
      <div className="p-6 rounded-[2px] border bg-white w-full">
        <div className="flex items-center gap-5 mt-2">
          <div>
            <div className="flex items-center mb-2">
              <input
                className="relative mr-1 h-4 w-4 appearance-none rounded-full border-2 border-solid border-gray-400 before:pointer-events-none before:absolute before:h-3 before:w-3 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_10px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-3 after:w-3 after:rounded-full after:content-[''] checked:border-black checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.5rem] checked:after:w-[0.5rem] checked:after:rounded-full checked:after:border-black checked:after:bg-black checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_10px_rgba(0,0,0,0.6)]"
                type="radio"
                checked={filterBy === "created_at"}
                onClick={() => setFilterBy("created_at")}
                name="flexRadioDefault"
                id="bracket"
              />
              <label
                className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                htmlFor="bracket"
              >
                Created at
              </label>
            </div>
            <div className="flex items-center mb-2">
              <input
                className="relative mr-1 h-4 w-4 appearance-none rounded-full border-2 border-solid border-gray-400 before:pointer-events-none before:absolute before:h-3 before:w-3 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_10px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-3 after:w-3 after:rounded-full after:content-[''] checked:border-black checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.5rem] checked:after:w-[0.5rem] checked:after:rounded-full checked:after:border-black checked:after:bg-black checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_10px_rgba(0,0,0,0.6)]"
                type="radio"
                checked={filterBy === "last_login"}
                onClick={() => setFilterBy("last_login")}
                name="flexRadioDefault"
                id="login"
              />
              <label
                className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                htmlFor="login"
              >
                Last log-in
              </label>
            </div>
          </div>
          <div className="relative w-[120px]">
            <input
              type="text"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              className="h-[40px] px-3 border w-full outline-0 border-[#E8E7E4] placeholder:text-white text-black"
            />
            <input
              type="date"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              className="absolute inset-0 opacity-0 z-1"
            />
          </div>
          <label>to date</label>
          <div className="relative w-[120px]">
            <input
              type="text"
              value={to}
              onChange={(e) => setTo(e.target.value)}
              className="h-[40px] px-3 border w-full outline-0 border-[#E8E7E4] placeholder:text-white text-black"
            />
            <input
              type="date"
              value={to}
              onChange={(e) => setTo(e.target.value)}
              className="absolute inset-0 opacity-0 z-1"
            />
          </div>
          <div className="w-[140px]">
            <ReferalCodeSelect
              options={kolList}
              selected={referalCode}
              setSelected={setReferalCode}
              placeholder={"KOL"}
            />
          </div>
          <div className="w-[140px]">
            <input
              type="text"
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
              placeholder="Nickname"
              className="h-[40px] px-3 border w-full outline-0 border-[#E8E7E4] placeholder:text-black"
            />
          </div>
          <div className="w-[170px]">
            <CountrySelect
              options={countries}
              selected={selectedCountry}
              setSelected={setSelectedCounttry}
              placeholder={"Country"}
            />
          </div>
          <div className="w-[240px]">
            <ListBoxSelect
              options={transactionList}
              selected={transaction}
              setSelected={setTransaction}
              placeholder={"Transac in the last 30 days"}
            />
          </div>
          {loader ? (
            <button className="h-[40px] px-8 text-[16px] text-white flex items-center justify-center bg-[#0080A6]">
              <BiLoaderCircle
                size={22}
                className="animate-[spin_3s_linear_infinite]"
              />
            </button>
          ) : (
            <button
              onClick={filter}
              className="h-[40px] px-8 text-[16px] text-white flex items-center justify-center bg-[#0080A6]"
            >
              Reload
            </button>
          )}
        </div>
      </div>
      <PlayerProgressTable
        data={playerProgress?.content}
        sortPlayerProgress={sortPlayerProgress}
        transaction={transaction.value}
        handlePageClick={handlePageClick}
        pagination={playerProgress?.pagination}
        loader={loader}
        sortBy={sortBy}
        sortDir={sortByDir}
        fetchPlayerProgress={fetchPlayerProgress}
      />
    </div>
  );
}

import React from "react";
import Sales from "./Sales";
import TotalSales from "./TotalSales";

export default function SalesRoiChart({ salesRoi }) {
  return (
    <div className="pb-6 w-full">
      <div className="bg-white w-full">
        <div className="grid grid-cols-12">
          <div className="col-span-9 border-r p-6 flex flex-col">
            <h4 className="text-[20px] text-black font-semibold mb-4">
              Sales & ROI
            </h4>
            <Sales salesRoi={salesRoi} />
          </div>
          <div className="col-span-3">
            <TotalSales salesRoi={salesRoi} />
          </div>
        </div>
      </div>
    </div>
  );
}

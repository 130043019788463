import { Dialog, Transition } from "@headlessui/react";
import {
  Fragment,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Check from "assets/images/check.png";
import api from "api";

const EditPopup = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    openModal: openModal,
  }));
  let [isOpen, setIsOpen] = useState(false);
  let [data, setData] = useState({});
  let [cloned, setCloned] = useState({});
  let [error, setError] = useState("");
  let [errorList, setErrorList] = useState([]);

  const closeModal = () => {
    setError("");
    setErrorList([]);
    setIsOpen(false);
  };

  const openModal = (e) => {
    setIsOpen(true);
    setData(e);
  };

  useEffect(() => {
    setCloned({});
  }, [isOpen]);

  const deepClone = (obj) => {
    if (typeof obj !== "object" || obj === null) {
      return obj;
    }

    const clone = Array.isArray(obj) ? [] : {};

    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        clone[key] = deepClone(obj[key]);
      }
    }

    return clone;
  };

  const progressChange = (param, type) => {
    const clone = deepClone(Object.keys(cloned).length === 0 ? data : cloned);
    const challenges = clone[type]?.challenges;
    const targetIndex = challenges.findIndex(
      (item) =>
        item.challenge_category_sequence_index ===
        param.challenge_category_sequence_index
    );
    if (targetIndex !== -1) {
      challenges[targetIndex].status =
        challenges[targetIndex].status === 0 ? 1 : 0;
    }
    setCloned(clone);
  };

  const confirmChanges = async () => {
    let body = cloned?.challenge_account_progress?.challenges?.map((item) => ({
      challenge_category_id:
        cloned?.challenge_account_progress?.challenge_category_id,
      challenge_category_sequence_index:
        item["challenge_category_sequence_index"],
      status: item["status"],
    }));

    if (cloned?.challenge_mission) {
      body = body.concat(
        cloned?.challenge_mission?.challenges.map((item) => ({
          challenge_category_id:
            cloned?.challenge_mission?.challenge_category_id,
          challenge_category_sequence_index:
            item["challenge_category_sequence_index"],
          status: item["status"],
        }))
      );
    }
    try {
      const response = await api.post(`/player_progress/update`, {
        body,
        player_id: cloned.player_id,
      });
      if (response.data.result.status == "1") {
        await props.fetchPlayerProgress();
        closeModal();
      } else if (response.data.result.status == "-1") {
        setError(response.data.result.err_description);
      } else if (response.data.result.status == "-10") {
        setErrorList(response.data.result.err_description);
      } else {
        setError("Some error occur");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex flex-col gap-3">
                  <h3 className="uppercase text-[17px] font-bold text-black">
                    Account Progress
                  </h3>
                  {data?.challenge_account_progress?.challenges?.map(
                    (item, idx) =>
                      item.status !== 0 ? (
                        <div className="flex items-center gap-3" key={idx}>
                          <img src={Check} width={18} alt="check" />
                          <p className="text-base text-black">
                            {item.challenge_master_rule_text_pattern}
                          </p>
                        </div>
                      ) : (
                        <label
                          key={idx}
                          htmlFor={`progress-${idx}`}
                          className="cursor-pointer flex items-start text-base text-black"
                        >
                          <input
                            id={`progress-${idx}`}
                            type="checkbox"
                            onClick={() =>
                              progressChange(item, "challenge_account_progress")
                            }
                            className="hidden peer"
                          />
                          <span className="mr-3 mt-1 peer-checked:bg-[#0080A6] peer-checked:border-[#0080A6] bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5.55px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border-[1.5px] border-[#ccc] rounded-sm"></span>
                          <div>{item.challenge_master_rule_text_pattern}</div>
                        </label>
                      )
                  )}
                  <h3 className="uppercase text-[17px] font-bold text-black">
                    MONTHLY MISSION{" "}
                    <span className="text-[14px] text-black font-normal">
                      (we will reset this data every month, day 1st)
                    </span>
                  </h3>
                  {data?.challenge_mission?.challenges?.map((item, idx) =>
                    item.status !== 0 ? (
                      <div className="flex items-center gap-3" key={idx}>
                        <img src={Check} width={18} alt="check" />
                        <p className="text-base text-black">
                          {item.challenge_master_rule_text_pattern}
                        </p>
                      </div>
                    ) : (
                      <label
                        key={idx}
                        htmlFor={`mission-${idx}`}
                        className="cursor-pointer flex items-start text-base text-black"
                      >
                        <input
                          id={`mission-${idx}`}
                          type="checkbox"
                          onClick={() =>
                            progressChange(item, "challenge_mission")
                          }
                          className="hidden peer"
                        />
                        <span className="mr-3 mt-1 peer-checked:bg-[#0080A6] peer-checked:border-[#0080A6] bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5.55px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border-[1.5px] border-[#ccc] rounded-sm"></span>
                        <div>{item.challenge_master_rule_text_pattern}</div>
                      </label>
                    )
                  )}
                  {error && <p className="text-[red]">{error}</p>}

                  {errorList.map((error, index) => {
                    // Check if the length of the error item is greater than 0
                    if (error.length > 0) {
                      return (
                        <p key={index} className="text-[red]">
                          - {error}
                        </p>
                      );
                    }
                    return null; // If length is 0, don't render anything
                  })}
                </div>
                <div className="mt-4 flex gap-3 justify-end">
                  <button
                    onClick={confirmChanges}
                    className="h-[45px] disabled:opacity-70 px-5 text-[16px] text-white flex items-center justify-center bg-[#ff9f00]"
                  >
                    Confirm changes
                  </button>
                  <button
                    onClick={closeModal}
                    className="h-[45px] disabled:cursor-not-allowed disabled:opacity-70 px-5 text-[16px] text-white flex items-center justify-center bg-[#ee0000]"
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
});

export default EditPopup;

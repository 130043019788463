import React from "react";
import DataTable from "react-data-table-component";
import moment from "moment"; // Assuming you are using the 'moment' library
import ReactPaginate from "react-paginate";

const NFTStatusTable = ({ nftData, handlePageClick, pagination, loader }) => {
  const columns = [
    {
      name: "Player owner",
      selector: (row) => row.player_owner,
      sortable: true,
      cell: (row) => displayValue(row.player_owner),
    },
    {
      name: "Wallet address",
      selector: (row) => row.owner_wallet,
      sortable: true,
      cell: (row) => displayValue(formatWallet(row.owner_wallet)),
    },
    {
      name: "Hero Transfered at",
      selector: (row) => row.hero_transfered_at,
      sortable: true,
      cell: (row) =>
        displayValue(moment(row.hero_transfered_at).format("YYYY-MM-DD")),
    },
    {
      name: "Global NFT id",
      selector: (row) => row.global_nft_id,
      sortable: true,
      cell: (row) => displayValue(row.global_nft_id),
    },
    {
      name: "NFT type",
      selector: (row) => row.nft_type,
      sortable: true,
      cell: (row) => displayValue(row.nft_type),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => displayValue(row.status),
    },
    {
      name: "NFT Id",
      selector: (row) => row.nft_id,
      sortable: true,
      cell: (row) => displayValue(row.nft_id),
    },
    {
      name: "NFT Name",
      selector: (row) => row.nft_name,
      sortable: true,
      cell: (row) => displayValue(row.nft_name),
    },
    {
      name: "Block. Rarity",
      selector: (row) => row.rarity,
      sortable: true,
      cell: (row) => displayValue(row.rarity),
    },
    {
      name: "Block. Rank",
      selector: (row) => row.b_rank,
      sortable: true,
      cell: (row) => displayValue(row.b_rank),
    },
    {
      name: "Block. Level",
      selector: (row) => row.b_level,
      sortable: true,
      cell: (row) => displayValue(row.b_level),
    },
    {
      name: "DDBB Rank",
      selector: (row) => row.ddbb_rank,
      sortable: true,
      cell: (row) => displayValue(row.ddbb_rank),
    },
    {
      name: "DDBB Level",
      selector: (row) => row.ddbb_level,
      sortable: true,
      cell: (row) => displayValue(row.ddbb_level),
    },
  ];

  const displayValue = (value) => (value ? value : "-");

  const conditionalCellStyles = [
    {
      when: (cell, row) => row.selector === "owner_wallet",
      style: {
        color: "blue",
      },
    },
  ];

  function formatWallet(wallet) {
    if (wallet) {
      const firstSixDigits = wallet.slice(0, 6);
      const lastFourDigits = wallet.slice(-4);
      const middleDots = "...";

      return `${firstSixDigits}${middleDots}${lastFourDigits}`;
    }
  }

  return (
    <div className="bg-white w-full">
      <DataTable
        columns={columns}
        data={nftData}
        progressPending={loader}
        defaultSortFieldId={1}
        noDataComponent={
          <p className="text-center text-[18px] font-normal p-[28px]">
            No results found
          </p>
        }
        conditionalCellStyles={conditionalCellStyles}
      />
      <div className="tokenomics-pagination my-10 mr-6">
        <ReactPaginate
          breakLabel="..."
          nextLabel={null}
          previousLabel={null}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pagination?.totalPages}
          forcePage={pagination?.previousPage}
          marginPagesDisplayed={2}
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
};

export default NFTStatusTable;

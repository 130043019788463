import React, { useEffect, useState } from "react";

const TournamentTemplate = ({ list }) => {
  const [template, setTemplate] = useState([]);
  useEffect(() => {
    const data = [
      { name: "Tournament type", value: list?.tournament_type },
      { name: "Game mode", value: list?.game_mode },
      { name: "Series", value: list?.series },
      { name: "League", value: list?.league },
      { name: "Ticket type", value: list?.ticket_type },
      { name: "Ticket amount", value: list?.ticket_amount },
      { name: "Energy cost by combat", value: list?.energy_cost_by_combat },
      {
        name: "Participants, minimum rank",
        value: list?.participants_rank_min,
      },
      {
        name: "Participants, maximum rank",
        value: list?.participants_rank_max,
      },
      { name: "Rake, taken by host", value: list?.rake_taken_by_host },
      { name: "Is guaranteed?", value: list?.is_guaranteed },
      { name: "Guranteed coin amount", value: list?.guaranteed_coin_amount },
      {
        name: "Minimum amount of Heroes per player",
        value: list?.min_heroes_per_player,
      },
      {
        name: "Maximum amount of Heroes per player",
        value: list?.max_heroes_per_player,
      },
      {
        name: "Is Regular  collection by default allowed?",
        value: list?.is_regular_collection_by_default_allowed,
      },
      {
        name: "Minimum amount of Owned Heroes",
        value: list?.minimum_amount_of_owned_heroes,
      },
      { name: "Minimum Heroes Rank", value: list?.min_heroes_rank },
      { name: "Maximum Heroes Rank", value: list?.max_heroes_rank },
      {
        name: "Maximum total participants",
        value: list?.max_total_participants,
      },
      { name: "Turn time limit", value: list?.turn_time_limit },
    ];
    setTemplate(data);
  }, [list]);
  return (
    <>
      {template.map((item, key) => (
        <div className="grid grid-cols-12 gap-3 mb-2" key={key}>
          <div className="col-span-8 text-right">
            <p className="text-[13px]">{item.name}</p>
          </div>
          <div className="col-span-4">
            <h3 className="font-bold text-[13px]">
              {item.value === true
                ? "Yes"
                : item.value === false
                ? "No"
                : item.value}
            </h3>
          </div>
        </div>
      ))}
    </>
  );
};

export default TournamentTemplate;

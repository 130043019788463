import { useEffect, useState } from "react";
import TournamentTable from "./components/TournamentTable";
import { getRentalHeroes } from "api";
import moment from "moment";
import { BiLoaderCircle } from "react-icons/bi";
import ListBoxSelect from "./components/ListBoxSelect";

const statusOption = [
  {
    title: "Status",
    value: "",
  },
  {
    title: "Cancelled by expiration time",
    value: "-99",
  },
  {
    title: "Cancelled by vitality over",
    value: "-98",
  },
  {
    title: "Rented",
    value: "1",
  },
];
const collectionOption = [
  {
    title: "Collection",
    value: "",
  },
  {
    title: "Regular Collection",
    value: "regular collection",
  },
  {
    title: "Fused Collection",
    value: "fused collection",
  },
  {
    title: "Limited Edition",
    value: "limited edition",
  },
];
const rankOption = [
  {
    title: "Rank",
    value: "",
  },
  {
    title: "0",
    value: "0",
  },
  {
    title: "1",
    value: "1",
  },
  {
    title: "2",
    value: "2",
  },
  {
    title: "3",
    value: "3",
  },
  {
    title: "4",
    value: "4",
  },
  {
    title: "5",
    value: "5",
  },
  {
    title: "6",
    value: "6",
  },
  {
    title: "7",
    value: "7",
  },
  {
    title: "8",
    value: "8",
  },
  {
    title: "9",
    value: "9",
  },
  {
    title: "10",
    value: "10",
  },
];
export default function Manager() {
  const [filterBy, setFilterBy] = useState("created_between");
  const [heroesList, setHeroesList] = useState([]);
  const [owner, setOwner] = useState("");
  const [renter, setRenter] = useState("");
  const [status, setStatus] = useState({});
  const [collection, setCollection] = useState({});
  const [rank, setRank] = useState({});
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState("1");
  const [itemPage, setItemPage] = useState("20");
  const [from, setFrom] = useState(
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );
  const [to, setTo] = useState(moment().format("YYYY-MM-DD"));
  const [sortBy, setSortBy] = useState("created_at");
  const [sortDir, setSortDir] = useState("asc");

  useEffect(() => {
    fetchRentalHeroes();
  }, [page, sortBy, sortDir]);

  const fetchRentalHeroes = async () => {
    try {
      setLoader(true);
      let data = await getRentalHeroes(
        filterBy,
        from,
        to,
        owner,
        renter,
        status.value,
        collection.value,
        rank.value,
        page,
        itemPage,
        sortBy,
        sortDir
      );
      setHeroesList(data);

      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const handlePageClick = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
  };

  const sortStatusTable = (sortVal) => {
    if (sortVal === sortBy) {
      sortDir === "asc" ? setSortDir("desc") : setSortDir("asc");
    } else {
      setSortDir("asc");
      setSortBy(sortVal);
    }
  };

  const filter = async () => {
    setLoader(true);
    await fetchRentalHeroes();
    setLoader(false);
  };
  return (
    <div className="flex flex-col items-center h-full p-6">
      <div className="p-6 rounded-[2px] border bg-white w-full mb-6">
        <h4 className="text-[16px] font-bold text-black">
          Heroes Rental filter by
        </h4>
        <div className="flex items-center gap-5 mt-2">
          <div>
            <div className="flex items-center mb-2">
              <input
                className="relative mr-1 h-4 w-4 appearance-none rounded-full border-2 border-solid border-gray-400 before:pointer-events-none before:absolute before:h-3 before:w-3 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_10px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-3 after:w-3 after:rounded-full after:content-[''] checked:border-black checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.5rem] checked:after:w-[0.5rem] checked:after:rounded-full checked:after:border-black checked:after:bg-black checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_10px_rgba(0,0,0,0.6)]"
                type="radio"
                checked={filterBy === "created_between"}
                onClick={() => setFilterBy("created_between")}
                name="flexRadioDefault"
                id="bracket"
              />
              <label
                className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                htmlFor="bracket"
              >
                Offers created between
              </label>
            </div>
            <div className="flex items-center mb-2">
              <input
                className="relative mr-1 h-4 w-4 appearance-none rounded-full border-2 border-solid border-gray-400 before:pointer-events-none before:absolute before:h-3 before:w-3 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_10px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-3 after:w-3 after:rounded-full after:content-[''] checked:border-black checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.5rem] checked:after:w-[0.5rem] checked:after:rounded-full checked:after:border-black checked:after:bg-black checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_10px_rgba(0,0,0,0.6)]"
                type="radio"
                checked={filterBy === "rentals_between"}
                onClick={() => setFilterBy("rentals_between")}
                name="flexRadioDefault"
                id="login"
              />
              <label
                className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                htmlFor="login"
              >
                Rentals between
              </label>
            </div>
          </div>
          <div>
            <div className="flex items-center gap-5">
              <div className="relative">
                <input
                  type="text"
                  value={from}
                  onChange={(e) => setFrom(e.target.value)}
                  className="h-[40px] w-[150px] px-3 border border-[#E8E7E4] placeholder:text-white"
                />
                <input
                  type="date"
                  value={from}
                  onChange={(e) => setFrom(e.target.value)}
                  className="absolute inset-0 opacity-0 z-1"
                />
              </div>
              <p>and</p>
              <div className="relative">
                <input
                  type="text"
                  value={to}
                  onChange={(e) => setTo(e.target.value)}
                  className="h-[40px] w-[150px] px-3 border border-[#E8E7E4] placeholder:text-white"
                />
                <input
                  type="date"
                  value={to}
                  onChange={(e) => setTo(e.target.value)}
                  className="absolute inset-0 opacity-0 z-1"
                />
              </div>
              {filterBy === "created_between" ? (
                <div className="w-[150px]">
                  <input
                    type="text"
                    value={owner}
                    placeholder="Owner"
                    onChange={(e) => setOwner(e.target.value)}
                    className="h-[40px] w-full px-3 border border-[#E8E7E4] text-[14px] outline-0 placeholder:text-black"
                  />
                </div>
              ) : (
                <div className="w-[150px]">
                  <input
                    type="text"
                    value={renter}
                    placeholder="Renter"
                    onChange={(e) => setRenter(e.target.value)}
                    className="h-[40px] w-full px-3 border border-[#E8E7E4] text-[14px] outline-0 placeholder:text-black"
                  />
                </div>
              )}
              <div className="w-[150px]">
                <ListBoxSelect
                  options={statusOption}
                  selected={status}
                  setSelected={setStatus}
                  placeholder="Status"
                />
              </div>
              <div className="w-[150px]">
                <ListBoxSelect
                  options={collectionOption}
                  selected={collection}
                  setSelected={setCollection}
                  placeholder="Collection"
                />
              </div>
              <div className="w-[150px]">
                <ListBoxSelect
                  options={rankOption}
                  selected={rank}
                  setSelected={setRank}
                  placeholder="Rank"
                />
              </div>
              <button
                onClick={filter}
                className="h-[40px] px-8 text-[16px] text-white flex items-center justify-center bg-[#0080A6]"
              >
                {loader ? (
                  <BiLoaderCircle
                    size={22}
                    className="animate-[spin_3s_linear_infinite]"
                  />
                ) : (
                  "Reload"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <TournamentTable
        list={heroesList?.content}
        loader={loader}
        handlePageClick={handlePageClick}
        pagination={heroesList?.pagination}
        sortStatusTable={sortStatusTable}
        sortBy={sortBy}
        sortDir={sortDir}
      />
    </div>
  );
}

import React, { useEffect, useState } from "react";

const BracketTemplate = ({ list }) => {
  const [template, setTemplate] = useState([]);
  useEffect(() => {
    const data = [
      {
        name: "Has Pre-qualifiers",
        value: list?.has_pre_qualifiers,
      },
      {
        name: "Has Qaulifiers with groups",
        value: list?.has_qualifiers_with_groups,
      },
      {
        name: "Has brackets 8th",
        value: list?.has_brackets_8th,
      },
      {
        name: "Has brackets 4th",
        value: list?.has_brackets_4th,
      },
      {
        name: "Has brackets semi-final",
        value: list?.has_brackets_semi_final,
      },
      {
        name: "Has brackets final",
        value: list?.has_brackets_final,
      },
      {
        name: "Pre-qulaifiers percentage of winners",
        value: list?.pre_qualifiers_percentage_of_winners,
      },
      {
        name: "Qualifiers number of groups",
        value: list?.qualifiers_number_of_groups,
      },
      {
        name: "Qualifiers minimum of players per group",
        value: list?.qualifiers_min_players_per_group,
      },
      {
        name: "Qualifiers maximum of players per group",
        value: list?.qualifiers_max_players_per_group,
      },
      {
        name: "Is group filled with bots in case not complete?",
        value: list?.is_group_filled_with_bots,
      },
      {
        name: "Prize by amount or percentage",
        value: list?.prize_by_amount_or_percentage,
      },
      {
        name: "Prize per rewarded player in Pre-qualifying",
        value: list?.prize_reward_pre_qualifying,
      },
      {
        name: "Prize per rewarded player in Qualifying",
        value: list?.prize_reward_qualifying,
      },
      {
        name: "Prize per rewarded in brackets 8th",
        value: list?.prize_reward_brackets_8th,
      },
      {
        name: "Prize per rewarded in brackets 4th",
        value: list?.prize_reward_brackets_4th,
      },
      {
        name: "Prize per rewarded in brackets semi-final",
        value: list?.prize_reward_brackets_semi_final,
      },
      {
        name: "Prize per rewarded in brackets final",
        value: list?.prize_reward_brackets_final,
      },
      {
        name: "Prize per rewarded for winner",
        value: list?.prize_reward_winner,
      },
    ];
    setTemplate(data);
  }, [list]);
  return (
    <>
      {template.map((item, key) => (
        <div className="grid grid-cols-12 gap-3 mb-2" key={key}>
          <div className="col-span-9 text-right">
            <p className="text-[13px]">{item.name}</p>
          </div>
          <div className="col-span-3">
            <h3 className="font-bold text-[13px]">
              {item.value === true
                ? "Yes"
                : item.value === false
                ? "No"
                : item.value}
            </h3>
          </div>
        </div>
      ))}
    </>
  );
};

export default BracketTemplate;

import moment from "moment";
import React from "react";
import {
  ComposedChart,
  Area,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function Sales({ salesRoi }) {
  const roundToTwoDecimalPlaces = (input) => {
    const number = parseFloat(input);

    if (typeof number === "number" && !Number.isNaN(number) && number !== 0) {
      const roundedNumber = parseFloat(number.toFixed(2));
      return roundedNumber.toLocaleString();
    }

    if (isNaN(number)) {
      return 0;
    }

    return input;
  };
  let salesRoiData = salesRoi?.sales?.map((sales) => {
    return {
      ...sales,
      day: moment(sales.day).format("MMM YY"),
    };
  });
  let largestValue = Number.NEGATIVE_INFINITY;
  salesRoiData?.forEach((obj) => {
    Object.values(obj).forEach((value) => {
      if (typeof value === "number" && value > largestValue) {
        largestValue = value;
      }
    });
  });
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="p-4 bg-white border">
          <p className="mb-2">{label}</p>
          <p className="text-[#68ff54] mb-2">{`profitability_goal: ${payload[0].value.toLocaleString()} `}</p>
          <p className="text-[#89ffdf] mb-2">{`operational_cost: ${payload[1].value.toLocaleString()}  `}</p>
          <p className="text-[#ffda69] mb-2">{`streamer_cost: ${payload[2].value.toLocaleString()} `}</p>
          <p className="text-[#ff6969] mb-2">{`cost_alert: ${payload[3]?.value.toLocaleString()}`}</p>
          <p className="text-[#5468db] mb-2">{`heroes_income: ${roundToTwoDecimalPlaces(
            payload[4]?.value.toLocaleString()
          )}`}</p>
          <p className="text-[#4bc18f] mb-2">{`vpls_income: ${roundToTwoDecimalPlaces(
            payload[5]?.value.toLocaleString()
          )}`}</p>
          <p className="text-[#ffbb44] mb-2">{`supermarket_income: ${roundToTwoDecimalPlaces(
            payload[6]?.value.toLocaleString()
          )}`}</p>
          <p className="text-[#0e1fff] mb-2">{`marketplace_income: ${roundToTwoDecimalPlaces(
            payload[7]?.value.toLocaleString()
          )}`}</p>
          <p className="text-[#9A00E9] mb-2">{`rentals_income: ${roundToTwoDecimalPlaces(
            payload[8]?.value.toLocaleString()
          )}`}</p>
        </div>
      );
    }

    return null;
  };

  const newArrayLength = salesRoiData?.length;
  const divisionFactor = largestValue / newArrayLength;
  let yTicks = Array(newArrayLength).fill(0);
  for (let i = 0; i < newArrayLength; i++) {
    if (i === 0) {
      yTicks[i] += Math.ceil(divisionFactor);
    } else {
      yTicks[i] = Math.floor(yTicks[i - 1] + divisionFactor);
    }
  }

  return (
    <div className="w-full h-[400px] my-auto">
      <ResponsiveContainer>
        <ComposedChart data={salesRoiData}>
          <XAxis dataKey="day" />
          <YAxis yAxisId={2} ticks={yTicks} />
          <YAxis hide yAxisId={3} ticks={yTicks} />
          <YAxis hide yAxisId={4} ticks={yTicks} />
          <YAxis hide yAxisId={5} ticks={yTicks} />
          <YAxis hide />
          <Tooltip content={<CustomTooltip />} />
          <Legend />

          <Area
            type="monotone"
            dataKey="profitability_goal"
            stackId="2"
            stroke="#68FF54"
            fill="#68FF54"
            yAxisId={5}
          />

          <Area
            type="monotone"
            dataKey="operational_cost"
            stackId="2"
            stroke="#89FFDF"
            fill="#89FFDF"
            yAxisId={4}
          />

          <Area
            type="monotone"
            dataKey="streamer_cost"
            stackId="2"
            stroke="#FFDA69"
            fill="#FFDA69"
            yAxisId={3}
          />

          <Area
            type="monotone"
            dataKey="cost_alert"
            stackId="2"
            stroke="#FF6969"
            fill="#FF6969"
            yAxisId={2}
          />

          <Bar yAxisId={5} dataKey="heroes_income" stackId="a" fill="#5468DB" />
          <Bar yAxisId={5} dataKey="vpls_income" stackId="a" fill="#4BC18F" />
          <Bar
            yAxisId={5}
            dataKey="supermarket_income"
            stackId="a"
            fill="#FFBB44"
          />
          <Bar
            yAxisId={5}
            dataKey="marketplace_income"
            stackId="a"
            fill="#0e1fff"
          />
          <Bar
            yAxisId={5}
            dataKey="rentals_income"
            stackId="a"
            fill="#9A00E9"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

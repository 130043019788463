import React from "react";

const COLORS = [
  "bg-[#FFBB44]",
  "bg-[#ff3300]",
  "bg-[#00ffbe]",
  "bg-[#b124aa]",
  "bg-[#7800ff]",
  "bg-[#4BC18F]",
  "bg-[#7800ff]",
];
export default function ProductData({ productRank }) {
  const roundToTwoDecimalPlaces = (input) => {
    const number = parseFloat(input);

    if (typeof number === "number" && !Number.isNaN(number) && number !== 0) {
      const roundedNumber = parseFloat(number.toFixed(0));
      return roundedNumber.toLocaleString();
    }

    if (isNaN(number)) {
      return 0;
    }

    return input;
  };
  return (
    <>
      {productRank?.map((item, key) => (
        <div className="grid grid-cols-8 gap-2 w-full" key={key}>
          <div className="col-span-4">
            <div className="flex items-center gap-2">
              <div className={`w-[10px] h-[10px] ${COLORS[key]}`}></div>
              <h6 className="text-[15px] text-black ">{item.category}</h6>
            </div>
          </div>
          <div className="col-span-2 text-right">
            <p>
              {item.amount_count
                ? `${roundToTwoDecimalPlaces(item.amount_count)}u`
                : "-"}
            </p>
          </div>
          <div className="col-span-2 text-right">
            <p>
              {item.price ? `$${roundToTwoDecimalPlaces(item.price)}` : "-"}
            </p>
          </div>
        </div>
      ))}
    </>
  );
}

import { useEffect, useState } from "react";
import moment from "moment";
import { BiLoaderCircle } from "react-icons/bi";
import CirculateStatusTable from "./components/CirculateStatusTable";
import ProductPieChart from "./components/ProductPieChart";
import ListBoxSelect from "./components/ListBoxSelect";
import ProductData from "./components/ProductData";
import ProductRank from "./components/ProductRank";
import CountrySelect from "./components/CountrySelect";
import {
  getTokenomicSale,
  getTokeProductRanking,
  getTokeCountryRanking,
  getAllKol,
} from "api";
import KolSelectBox from "./components/KolSelectBox";
import api from "api";

const categoryList = [
  { title: "Category", value: "" },
  { title: "Hero", value: "1" },
  { title: "VPL", value: "2" },
  { title: "Super Market", value: "3" },
];

export default function TokenomicsSales() {
  const [salesList, setSalesList] = useState([]);
  const [productRank, setProductRank] = useState([]);
  const [countryRank, setCountryRank] = useState([]);
  const [loader, setLoader] = useState(false);
  const [allKol, setAllKol] = useState([]);
  const [selectedKol, setSelectedKol] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(categoryList[0]);
  const [page, setPage] = useState("1");
  const [from, setFrom] = useState(
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );
  const [to, setTo] = useState(moment().format("YYYY-MM-DD"));
  const [sortBy, setSortBy] = useState("created_at");
  const [sortDir, setSortDir] = useState("asc");
  const itemPage = useState("20")[0];
  const [nickname, setNickname] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCounttry] = useState();

  const handlePageClick = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
  };

  useEffect(() => {
    fetchTokenomicSales();
  }, [page, sortBy, sortDir]);

  useEffect(() => {
    fetchTokeProductRanking();
    fetchTokeCountryRanking();
    fetchAllKol();
    fetchCountries();
  }, []);

  const fetchAllKol = async () => {
    try {
      let data = await getAllKol();
      data?.content.unshift({
        nickname: "KOL",
        referal_code: "",
      });
      setAllKol(data?.content);
      setSelectedKol(data?.content[0]);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCountries = async () => {
    try {
      let data = await api.get("/kol/countries");
      const first = { title: "Country", iso_code: "" };
      const country = data?.data?.content;
      country.unshift(first);
      setCountries(country);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTokenomicSales = async () => {
    try {
      setLoader(true);
      let data = await getTokenomicSale(
        from,
        to,
        page,
        sortBy,
        sortDir,
        nickname,
        itemPage,
        selectedCategory.value,
        selectedKol.referal_code,
        selectedCountry?.iso_code
      );
      setSalesList(data);

      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const fetchTokeProductRanking = async () => {
    try {
      // setLoader(true);
      let data = await getTokeProductRanking(
        from,
        to,
        nickname,
        selectedKol.referal_code,
        selectedCategory.value,
        selectedCountry?.iso_code
      );
      setProductRank(data?.content);

      // setLoader(false);
    } catch (error) {
      console.error(error);
      // setLoader(false);
    }
  };

  const fetchTokeCountryRanking = async () => {
    try {
      // setLoader(true);
      let data = await getTokeCountryRanking(
        from,
        to,
        nickname,
        selectedKol.referal_code,
        selectedCategory.value,
        selectedCountry?.iso_code
      );
      setCountryRank(data?.content);

      // setLoader(false);
    } catch (error) {
      console.error(error);
      // setLoader(false);
    }
  };

  const sortStatusTable = (sortVal) => {
    if (sortVal === sortBy) {
      sortDir === "asc" ? setSortDir("desc") : setSortDir("asc");
    } else {
      setSortDir("asc");
      setSortBy(sortVal);
    }
  };

  const filter = async () => {
    setLoader(true);
    await fetchTokenomicSales();
    await fetchTokeProductRanking();
    await fetchTokeCountryRanking();
    setLoader(false);
  };
  return (
    <div className="flex flex-col items-center h-full p-6">
      <div className="p-6 rounded-[2px] border bg-white w-full mb-6">
        <div className="flex items-center gap-5 mt-2">
          <label>From date</label>
          <div className="relative">
            <input
              type="text"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              className="h-[40px] px-3 border border-[#E8E7E4] placeholder:text-white"
            />
            <input
              type="date"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              className="absolute inset-0 opacity-0 z-1"
            />
          </div>
          <label>to date</label>
          <div className="relative">
            <input
              type="text"
              value={to}
              onChange={(e) => setTo(e.target.value)}
              className="h-[40px] px-3 border border-[#E8E7E4] placeholder:text-white"
            />
            <input
              type="date"
              value={to}
              onChange={(e) => setTo(e.target.value)}
              className="absolute inset-0 opacity-0 z-1"
            />
          </div>
          <div className="w-[160px]">
            <KolSelectBox
              options={allKol}
              selected={selectedKol}
              setSelected={setSelectedKol}
              placeholder={"KOL"}
            />
          </div>
          <div className="w-[160px]">
            <ListBoxSelect
              options={categoryList}
              selected={selectedCategory}
              setSelected={setSelectedCategory}
              placeholder={"Category"}
            />
          </div>
          <div className="w-[140px]">
            <input
              type="text"
              placeholder="Nickname"
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
              className="h-[40px] px-3 border w-full outline-0 border-[#E8E7E4] placeholder:text-black"
            />
          </div>
          <div className="w-[160px]">
            <CountrySelect
              options={countries}
              selected={selectedCountry}
              setSelected={setSelectedCounttry}
              placeholder={"Country"}
            />
          </div>
          <button
            onClick={filter}
            className="h-[40px] px-8 text-[16px] text-white flex items-center justify-center bg-[#0080A6]"
          >
            {loader ? (
              <BiLoaderCircle
                size={22}
                className="animate-[spin_3s_linear_infinite]"
              />
            ) : (
              "Reload"
            )}
          </button>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-5 w-full">
        <div className="col-span-9">
          <CirculateStatusTable
            list={salesList?.content}
            loader={loader}
            handlePageClick={handlePageClick}
            sortStatusTable={sortStatusTable}
            pagination={salesList?.pagination}
            sortBy={sortBy}
            sortDir={sortDir}
          />
        </div>
        <div className="col-span-3">
          <div className="px-6 py-4 bg-white">
            <h4 className="text-[20px] text-black font-semibold mb-4">
              Sales Ranking by product
            </h4>
            <ProductData productRank={productRank} />
            <ProductPieChart productRank={productRank} />
            <h4 className="text-[20px] text-black font-semibold mb-4">
              Sales Ranking by country
            </h4>
            <ProductRank countryRank={countryRank} />
          </div>
        </div>
      </div>
    </div>
  );
}

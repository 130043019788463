import React from "react";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function Daily({ newAccounts }) {
  const roundToTwoDecimalPlaces = (input) => {
    const number = parseFloat(input);

    if (typeof number === "number" && !Number.isNaN(number) && number !== 0) {
      const roundedNumber = parseFloat(number.toFixed(2));
      return roundedNumber.toLocaleString();
    }

    if (isNaN(number)) {
      return 0;
    }

    return input;
  };
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const percentage =
        (payload[1]?.value / (payload[0]?.value + payload[1]?.value)) * 100;
      return (
        <div className="p-4 bg-white border">
          <p className="mb-2">
            {label} {payload[0].payload.dayName}
          </p>
          <p className="text-black mb-2">{`Total new accounts: ${
            payload[0].value + payload[1].value
          } `}</p>
          <p className="text-[#00ecff] mb-2">{`New Accounts wasted: ${
            payload[0].value
          } (${roundToTwoDecimalPlaces(100 - percentage)}%) `}</p>
          <p className="text-[#4BC18F] mb-2">{`New Accounts Active Users: ${
            payload[1].value
          } (${roundToTwoDecimalPlaces(percentage)}%)`}</p>
          <p className="text-[#EB553D] mb-2">{`New paying accounts: ${payload[2]?.value}`}</p>
          <p className="text-[#5468db] mb-2">{`Total paying accounts: ${payload[3]?.value}`}</p>
        </div>
      );
    }

    return null;
  };
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart data={newAccounts}>
        <XAxis dataKey="day" />
        <YAxis />
        <YAxis yAxisId={2} hide />

        <Tooltip content={<CustomTooltip />} />
        <Legend />

        <Bar
          dataKey="New Accounts wasted"
          barSize={20}
          stackId="a"
          fill="#00ecff"
        />
        <Bar
          dataKey="New Accounts Active Users"
          barSize={20}
          stackId="a"
          fill="#4BC18F"
        />
        <Line dataKey="New paying accounts" stroke="#EB553D" yAxisId={2} />
        <Line yAxisId={2} dataKey="Total paying accounts" stroke="#5468db" />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

const KOLTable = ({ accountList, editAccountData, fetchKOLAccountId }) => {
  return (
    <div className="bg-white w-full">
      <div className="overflow-x-auto">
        <table className="w-full min-w-[800px]">
          <thead>
            <tr>
              <th className="border border-l-0 text-right font-medium text-gray-600 p-[8px] text-[14px]">
                Id
              </th>
              <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left">
                Nickname
              </th>
              <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left">
                Status
              </th>
              <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left">
                Permission
              </th>
              <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center">
                Player Id
              </th>
              <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center">
                Nickname in Soga
              </th>
              <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left">
                KOL Type
              </th>
              <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left">
                Country
              </th>
              <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center">
                hours per week
              </th>
              <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center">
                Last month fee
              </th>
              <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center">
                MAU
              </th>
              <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center">
                Last Month ROI
              </th>
              <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center">
                Last fee update
              </th>
              <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center">
                Reffered Streamers
              </th>
            </tr>
          </thead>
          <tbody>
            {accountList.map((list) => {
              return (
                <tr key={list.id}>
                  <th className="border first:border-l-0 last:border-r-0 text-right font-medium text-gray-600 p-[8px] text-[14px] whitespace-nowrap ">
                    {list.id}
                  </th>
                  <th
                    onClick={() => fetchKOLAccountId(list.id)}
                    className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-left text-blue-400 cursor-pointer"
                  >
                    {list.nickname}
                  </th>
                  <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left">
                    {list.status}
                  </th>
                  <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left">
                    {list.permission}
                  </th>
                  <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center">
                    {list.player_id}
                  </th>
                  <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center">
                    {list.nickname_soga}
                  </th>
                  <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left">
                    {list.kol_type}
                  </th>
                  <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left">
                    {list.country}
                  </th>
                  <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center">
                    {list.hours_per_week}
                  </th>
                  <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center">
                    {list.last_month_fee || 0}
                  </th>
                  <td className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center">
                    {list.mau}
                  </td>
                  <td className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center">
                    {list.last_month_roi}
                  </td>
                  <td className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center">
                    {list.last_fee_update}
                  </td>
                  <td className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center">
                    {list.referred_streamers}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default KOLTable;

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function Daily({ dailyAccounts }) {
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="p-4 bg-white border">
          <p className="mb-2">
            {label} {payload[0]?.payload.dayName}
          </p>
          <p className="text-black mb-2">{`Total active users: ${
            payload[0]?.value + payload[1]?.value
          }`}</p>
          <p className="text-[#FFBB44] mb-2">{`Old active users: ${payload[0]?.value}`}</p>
          <p className="text-[#4bc18f] mb-2">{`New active users: ${payload[1]?.value}`}</p>
        </div>
      );
    }

    return null;
  };
  return (
    <div className="w-full h-[300px] my-auto">
      <ResponsiveContainer>
        <BarChart
          data={dailyAccounts}
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 0,
          }}
          barSize={20}
        >
          <XAxis
            dataKey="day"
            scale="point"
            tickLine={false}
            padding={{ left: 10, right: 10 }}
          />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey="Old active users" fill="#FFBB44" stackId="a" />
          <Bar dataKey="New active users" fill="#4bc18f" stackId="a" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

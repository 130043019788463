import React, { useEffect, useState } from "react";
import {
  ComposedChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
export default function Chart({
  fillColor,
  strokeColor,
  estimatedWorth,
  itemKey,
  itemTitle,
}) {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (estimatedWorth && estimatedWorth[itemKey]) {
      const charts = estimatedWorth[itemKey];
      const firstItem = { players_count: 0, amount: "0" };
      charts[0] = firstItem;
      setData(charts);
    }
  }, [estimatedWorth]);
  const formatYAxis = (tickItem) => {
    return `${tickItem} P`;
  };
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="p-4 bg-white border">
          <p
            className={`text-[${strokeColor}] mb-2`}
          >{`Players Count: ${payload[0].value} `}</p>
          <p
            className={`text-[${strokeColor}] mb-2`}
          >{`${itemTitle}: ${label} `}</p>
        </div>
      );
    }

    return null;
  };
  function formatAmount(amount) {
    if (amount) {
      if (amount >= 1000000) {
        return (amount / 1000000).toFixed(1) + "M";
      } else if (amount >= 1000) {
        return (amount / 1000).toFixed(1) + "K";
      } else {
        return amount.toString();
      }
    } else {
      return amount;
    }
  }
  return (
    <div className="w-full h-[220px] my-auto relative">
      <div className="absolute top-0 left-[75px]">
        <h2 className="text-[clamp(8px,1vw,11px)] font-medium">
          Discarded {data?.discarded?.minimum_players} players close to{" "}
          {formatAmount(data?.discarded?.minimum_amount)}
        </h2>
        <h2 className="text-[clamp(8px,1vw,11px)] font-medium">
          Discarded {data?.discarded?.maximum_players} players close to{" "}
          {formatAmount(data?.discarded?.maximum_amount)}
        </h2>
      </div>
      <ResponsiveContainer>
        <ComposedChart data={data?.figure}>
          <XAxis dataKey="amount" />
          <YAxis tickFormatter={formatYAxis} />
          <Tooltip content={<CustomTooltip />} />
          <Area
            type="monotone"
            dataKey="players_count"
            stroke={strokeColor}
            fill={fillColor}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

import { useEffect, useState } from "react";
import ListBoxSelect from "../ListBoxSelect";
import moment from "moment";
import { postMonthlyFee, removeMonthlyFee } from "../../../../api";

export default function MonthlyFees({ monthlyFee, setMonthlyFee, kolId }) {
  const [monthList, setMonthList] = useState([]);
  const [selected, setSelected] = useState("");
  const [postData, setPostData] = useState({ kol_id: "", date: "", fee: "" });

  useEffect(() => {
    fetchMonthOptionsList();
  }, []);

  const selectMonthlyFee = async (param) => {
    setPostData((prevState) => ({
      ...prevState,
      date: param.title,
    }));
  };

  const fetchMonthOptionsList = () => {
    const currentYear = moment().year();
    const months = moment.monthsShort();
    const formattedMonths = months.map((month, index) => ({
      id: index,
      title: `${currentYear} - ${month}`,
    }));
    setMonthList(formattedMonths);
  };

  const deleteMonthlyFee = async (id) => {
    try {
      await removeMonthlyFee(id);
      const updatedData = monthlyFee.filter((item) => item.id !== id);
      setMonthlyFee(updatedData);
    } catch (error) {
      console.error(error);
    }
  };

  const updateFee = async (e) => {
    setPostData((prevState) => ({
      ...prevState,
      fee: e.target.value,
    }));
  };

  const AddMonthlyFee = async () => {
    const date = moment(postData.date, "YYYY-MMMM").format("YYYY-MM");
    const convertedDate = `${date}-${moment().format("DD")}`;
    let query = {
      kol_id: kolId,
      date: convertedDate,
      fee: postData.fee,
    };

    try {
      await postMonthlyFee(query);
      setMonthlyFee((prevState) => [...prevState, postData]);
      setSelected("");
      setPostData((prevState) => ({
        ...prevState,
        fee: "",
      }));
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <h3 className="text-[18px] font-bold text-black mb-3">Monthly fees</h3>
      <div className="grid grid-cols-7 gap-16">
        <div className="flex flex-col gap-2 col-span-3">
          {monthlyFee &&
            monthlyFee.map((month, key) => {
              return (
                <div key={key} className="grid grid-cols-3 items-center gap-5">
                  <label className="text-[16px] font-medium text-black/80 ">
                    {month.date}
                  </label>
                  <p className="text-[16px] font-medium text-black/80 ">
                    ${month.fee}
                  </p>
                  <div>
                    <button
                      onClick={() => deleteMonthlyFee(month.id)}
                      className="h-[40px] px-8 text-[16px] min-w-[120px] text-white flex items-center justify-center bg-[#0080A6]"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              );
            })}

          <div className="grid grid-cols-3 items-center gap-5">
            <div className="w-[150px]">
              <ListBoxSelect
                options={monthList}
                onChange={selectMonthlyFee}
                selected={selected}
                setSelected={setSelected}
                className="bottom-[105%] !mt-0"
              />
            </div>
            <div className="flex items-center gap-3">
              <input
                type="text"
                value={postData.fee}
                onChange={updateFee}
                className="h-[40px] px-3 border w-[80px] outline-0 border-[#E8E7E4]"
              />
              <label className="text-[16px] font-medium text-black/80 ">
                USD$
              </label>
            </div>
            <div>
              <button
                onClick={AddMonthlyFee}
                className="h-[40px] px-8 text-[16px] min-w-[120px] text-white flex items-center justify-center bg-[#0080A6]"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";

export default function ProductRank({ workersSummary }) {
  const roundToTwoDecimalPlaces = (input) => {
    const number = parseFloat(input);

    if (typeof number === "number" && !Number.isNaN(number) && number !== 0) {
      const roundedNumber = parseFloat(number.toFixed(0));
      return roundedNumber.toLocaleString();
    }

    if (isNaN(number)) {
      return 0;
    }

    return input;
  };
  const prices = workersSummary.map((item) => item.total_value);
  const largestPrice = Math.max(...prices);
  return (
    <div className="flex flex-col gap-1 mt-8">
      {workersSummary?.map((item, key) => (
        <div className="flex items-center mb-2" key={key}>
          <div className="w-6/12 shrink-0">
            <div className="flex gap-3 pr-2 break-word">
              <img
                className="w-[22px]"
                src={item.seller_image}
                alt={item.seller_name}
              />
              {item.seller_name}
            </div>
          </div>
          <div className="w-6/12 pl-3 relative shrink-0">
            <div className="flex items-center ">
              <div className="absolute left-5">
                {roundToTwoDecimalPlaces(item.total_amount)}u
              </div>
              <div className="absolute right-3">
                ${roundToTwoDecimalPlaces(item.total_value)}
              </div>
              <div
                className="h-[20px] bg-[#FFBB44]"
                style={{ width: `${(item.total_value * 100) / largestPrice}%` }}
              ></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

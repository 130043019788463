import React, { useRef } from "react";
import { BiLoaderCircle } from "react-icons/bi";
import EditPopup from "./EditPopup";
import ReactPaginate from "react-paginate";
import { AiOutlineCaretDown } from "react-icons/ai";
import moment from "moment";

const PlayerProgressTable = ({
  data,
  loader,
  handlePageClick,
  pagination,
  sortPlayerProgress,
  transaction,
  sortBy,
  sortDir,
  fetchPlayerProgress,
}) => {
  const edit = useRef();

  const editPopup = async (data) => {
    if (edit.current) {
      edit.current.openModal(data);
    }
  };

  const formatWalletAddress = (address) => {
    if (address) {
      const lastFour = address.slice(-4);
      return `..${lastFour}`;
    }
  };

  const roundToTwoDecimalPlaces = (input) => {
    const number = parseFloat(input);

    if (typeof number === "number" && !Number.isNaN(number) && number !== 0) {
      const roundedNumber = parseFloat(number.toFixed(2));
      return roundedNumber.toLocaleString();
    }

    if (isNaN(number)) {
      return 0;
    }

    return input;
  };

  return (
    <div className="bg-white w-full">
      <div className="overflow-x-auto">
        <table className="w-full min-w-[800px]">
          <thead>
            <tr>
              <th
                onClick={() => sortPlayerProgress("player_id")}
                className="border font-semibold border-l-0 py-[8px] px-[2px] text-[12px] whitespace-nowrap text-center group cursor-pointer"
              >
                <div className="flex items-center justify-center gap-1">
                  Player id
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "player_id" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortPlayerProgress("created_at")}
                className="border font-semibold py-[8px] px-[2px] text-[12px] whitespace-nowrap text-left group cursor-pointer"
              >
                <div className="flex items-center gap-1">
                  Created at
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "created_at" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortPlayerProgress("last_login")}
                className="border font-semibold py-[8px] px-[2px] text-[12px] whitespace-nowrap text-left group cursor-pointer"
              >
                <div className="flex items-center gap-1">
                  Last log-in
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "last_login" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortPlayerProgress("iso_country")}
                className="border font-semibold py-[8px] px-[2px] text-[12px] whitespace-nowrap text-left group cursor-pointer"
              >
                <div className="flex items-center gap-1">
                  Country
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "iso_country" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortPlayerProgress("nickname")}
                className="border font-semibold py-[8px] px-[2px] text-[12px] whitespace-nowrap group cursor-pointer text-center"
              >
                <div className="flex items-center justify-center gap-1">
                  Nickname
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "nickname" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortPlayerProgress("referred")}
                className="border font-semibold py-[8px] px-[2px] text-[12px] whitespace-nowrap group cursor-pointer text-center"
              >
                <div className="flex items-center justify-center gap-1">
                  Referred
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "referred" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortPlayerProgress("email")}
                className="border font-semibold py-[8px] px-[2px] text-[12px] whitespace-nowrap group cursor-pointer text-center"
              >
                <div className="flex items-center justify-center gap-1">
                  Email
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "email" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortPlayerProgress("wallet_address")}
                className="border font-semibold py-[8px] px-[2px] text-[12px] whitespace-nowrap group cursor-pointer text-center"
              >
                <div className="flex items-center justify-center gap-1">
                  Wallet
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "wallet_address" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortPlayerProgress("league_id")}
                className="border font-semibold py-[8px] px-[2px] text-[12px] whitespace-nowrap group cursor-pointer text-center"
              >
                <div className="flex items-center justify-center gap-1">
                  League
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "league_id" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortPlayerProgress("rank")}
                className="border font-semibold py-[8px] px-[2px] text-[12px] whitespace-nowrap group cursor-pointer text-center"
              >
                <div className="flex items-center justify-center gap-1">
                  Rank
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "rank" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortPlayerProgress("quests")}
                className="border font-semibold py-[8px] px-[2px] text-[12px] whitespace-nowrap group cursor-pointer text-center"
              >
                <div className="flex items-center justify-center gap-1">
                  Quests
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "quests" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortPlayerProgress("heroes_borrowed")}
                className="border font-semibold py-[8px] px-[2px] text-[12px] whitespace-nowrap group cursor-pointer text-center"
              >
                <div className="flex items-center justify-center gap-1">
                  Borrowed
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "heroes_borrowed" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortPlayerProgress("heroes_owned")}
                className="border font-semibold py-[8px] px-[2px] text-[12px] whitespace-nowrap group cursor-pointer text-center"
              >
                <div className="flex items-center justify-center gap-1">
                  H. Owned
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "heroes_owned" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortPlayerProgress("purchase_amount")}
                className="border font-semibold py-[8px] px-[2px] text-[12px] whitespace-nowrap group cursor-pointer text-center"
              >
                <div className="flex items-center justify-center gap-1">
                  Purch. ({transaction}
                  {transaction !== "All" && "d"})
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "purchase_amount" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortPlayerProgress("rented_offers")}
                className="border font-semibold py-[8px] px-[2px] text-[12px] whitespace-nowrap group cursor-pointer text-center"
              >
                <div className="flex items-center justify-center gap-1">
                  Rented. ({transaction}
                  {transaction !== "All" && "d"})
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "rented_offers" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortPlayerProgress("rental_offers")}
                className="border font-semibold py-[8px] px-[2px] text-[12px] whitespace-nowrap group cursor-pointer text-center"
              >
                <div className="flex items-center justify-center gap-1">
                  Rental offers
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "rental_offers" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                // onClick={() => sortPlayerProgress("account_progress")}
                className="border font-semibold py-[8px] px-[2px] text-[12px] whitespace-nowrap group cursor-pointer text-center"
              >
                <div className="flex items-center justify-center gap-1">
                  Account progress
                  {/* <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "account_progress" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  /> */}
                </div>
              </th>
              <th
                // onClick={() => sortPlayerProgress("rental_status")}
                className="border font-semibold py-[8px] px-[2px] text-[12px] whitespace-nowrap group cursor-pointer text-center"
              >
                <div className="flex items-center justify-center gap-1">
                  Missions
                  {/* <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "rental_status" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  /> */}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {loader ? (
              <tr>
                <td colSpan={25}>
                  <div className="text-center p-[28px] flex items-center justify-center">
                    <BiLoaderCircle
                      size={62}
                      className="animate-[spin_3s_linear_infinite] text-[#0080A6]"
                    />
                  </div>
                </td>
              </tr>
            ) : data?.length === 0 ? (
              <tr>
                <td colSpan={25}>
                  <div className="text-center p-[28px] flex items-center justify-center">
                    No content found
                  </div>
                </td>
              </tr>
            ) : (
              data?.map((item, key) => {
                return (
                  <tr key={key}>
                    <td className="border border-l-0 py-[8px] px-[2px] text-[13px] whitespace-nowrap text-center">
                      {item.player_id || "-"}
                    </td>
                    <td className="border py-[8px] px-[2px] text-[13px] whitespace-nowrap text-left">
                      {moment(item.created_at).format("YYYY/MM/DD") || "-"}
                    </td>
                    <td
                      className={`border py-[8px] px-[2px] text-[13px] whitespace-nowrap text-center ${
                        item.last_login > 29
                          ? "bg-red-300 text-red-600 font-semibold"
                          : ""
                      }`}
                    >
                      {item.last_login || 0} days
                    </td>
                    <td className="border py-[8px] px-[2px] text-[13px] whitespace-nowrap">
                      <div className="flex justify-center">
                        <img src={item.iso_country} alt="flag" width={25} />
                      </div>
                    </td>
                    <td className="border py-[8px] px-[2px] text-[12px] whitespace-nowrap text-center">
                      {item.nickname || "-"}
                    </td>
                    <td className="border py-[8px] px-[2px] text-[12px] whitespace-nowrap text-center">
                      {item.referred || "-"}
                    </td>
                    <td className="border py-[8px] px-[2px] text-[12px] whitespace-nowrap text-center">
                      <div className="truncate"> {item.email || "-"}</div>
                    </td>
                    <td className="border py-[8px] px-[2px] text-[12px] whitespace-nowrap text-center">
                      {formatWalletAddress(item.wallet_address) || "-"}
                    </td>
                    <td className="border py-[8px] px-[2px] text-[12px] whitespace-nowrap text-center">
                      <div className="flex justify-center">
                        <img
                          src={`https://socialgames.com/${item.league_img}`}
                          alt="League"
                          width={25}
                        />
                      </div>
                    </td>
                    <td className="border py-[8px] px-[2px] text-[12px] whitespace-nowrap text-center">
                      {item.rank || "-"}
                    </td>
                    <td className="border py-[8px] px-[2px] text-[12px] whitespace-nowrap text-center">
                      {item.quests || "-"}
                    </td>
                    <td className="border py-[8px] px-[2px] text-[12px] whitespace-nowrap text-center">
                      {item.heroes_borrowed || "-"}
                    </td>
                    <td className="border py-[8px] px-[2px] text-[12px] text-center ">
                      {item.heroes_owned || "-"}
                    </td>
                    <td className="border py-[8px] px-[2px] text-[12px] ">
                      <div className="flex justify-center gap-2">
                        {roundToTwoDecimalPlaces(item.purchase_amount)} - $
                        {roundToTwoDecimalPlaces(item.purchase_income)}
                      </div>
                    </td>
                    <td className="border py-[8px] px-[2px] text-[12px] text-center">
                      {item.rented_offers || "-"}
                    </td>
                    <td className="border py-[8px] px-[2px] text-[12px] whitespace-nowrap text-center">
                      {item.rental_offers || "-"}
                    </td>
                    <td className="border py-[8px] px-[2px] text-[12px] whitespace-nowrap text-center">
                      <div className="flex items-center gap-1 justify-center">
                        {item?.challenge_account_progress?.challenges?.map(
                          (item, idx) => (
                            <div
                              className={`w-6 h-2  ${
                                item.status_color === "gray"
                                  ? "bg-gray-400"
                                  : item.status_color === "orange"
                                  ? "bg-orange-500"
                                  : item.status_color === "blue"
                                  ? "bg-blue-500"
                                  : item.status_color === "green"
                                  ? "bg-green-500"
                                  : ""
                              }`}
                              key={idx}
                            ></div>
                          )
                        )}
                      </div>
                    </td>
                    <td className="border py-[8px] px-[2px] text-[12px] whitespace-nowrap text-center">
                      <div className="flex items-center gap-2 justify-center">
                        <div className="flex items-center gap-1 justify-center">
                          {item?.challenge_mission?.challenges?.map(
                            (item, idx) => (
                              <div
                                className={`w-6 h-2  ${
                                  item.status_color === "gray"
                                    ? "bg-gray-400"
                                    : item.status_color === "orange"
                                    ? "bg-orange-500"
                                    : item.status_color === "blue"
                                    ? "bg-blue-500"
                                    : item.status_color === "green"
                                    ? "bg-green-500"
                                    : ""
                                }`}
                                key={idx}
                              ></div>
                            )
                          )}
                        </div>
                        <div
                          onClick={() => editPopup(item)}
                          className="text-blue-500 text-[14px] font-medium leading-[14px] cursor-pointer"
                        >
                          Edit
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <div className="tokenomics-pagination my-10 mr-6">
        <ReactPaginate
          breakLabel="..."
          nextLabel={null}
          previousLabel={null}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pagination?.totalPages}
          forcePage={pagination?.previousPage}
          marginPagesDisplayed={2}
          renderOnZeroPageCount={null}
        />
      </div>
      <EditPopup ref={edit} fetchPlayerProgress={fetchPlayerProgress} />
    </div>
  );
};

export default PlayerProgressTable;

import moment from "moment";
import { AiOutlineCaretDown } from "react-icons/ai";
import { BiLoaderCircle } from "react-icons/bi";
import ReactPaginate from "react-paginate";
import { Tooltip } from "react-tooltip";

const NFTStatusTable = ({
  list,
  loader,
  handlePageClick,
  sortStatusTable,
  pagination,
  sortBy,
  sortDir,
}) => {
  const roundToTwoDecimalPlaces = (input) => {
    const number = parseFloat(input);

    if (typeof number === "number" && !Number.isNaN(number) && number !== 0) {
      const roundedNumber = parseFloat(number.toFixed(2));
      return roundedNumber.toLocaleString();
    }

    if (isNaN(number)) {
      return 0;
    }

    return input;
  };

  return (
    <div className="bg-white w-full">
      <div className="overflow-x-auto">
        <table className="w-full min-w-[800px]">
          <thead>
            <tr>
              <th
                onClick={() => sortStatusTable("sales_id")}
                className="border border-l-0 text-center cursor-pointer font-medium text-gray-600 p-[8px] text-[13px] group"
              >
                <div className="flex items-center justify-center gap-1">
                  Sales Id
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "sales_id" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("created_at")}
                className="border p-[8px] text-[13px] cursor-pointer whitespace-nowrap font-medium text-gray-600 text-left group"
              >
                <div className="flex items-center gap-1">
                  Date
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "created_at" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("country")}
                className="border p-[8px] text-[13px] cursor-pointer whitespace-nowrap font-medium text-gray-600 text-center group"
              >
                <div className="flex items-center justify-center gap-1">
                  Country
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "country" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("player")}
                className="border p-[8px] text-[13px] cursor-pointer whitespace-nowrap font-medium text-gray-600 text-left group"
              >
                <div className="flex items-center gap-1">
                  Player
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "player" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("referral")}
                className="border p-[8px] text-[13px] cursor-pointer whitespace-nowrap font-medium text-gray-600 text-left group"
              >
                <div className="flex items-center gap-1">
                  Referral
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "referral" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("category")}
                className="border p-[8px] text-[14px] cursor-pointer whitespace-nowrap font-medium text-gray-600 text-left group"
              >
                <div className="flex items-center gap-1">
                  Category
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "category" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("product")}
                className="border p-[8px] text-[14px] cursor-pointer whitespace-nowrap font-medium text-gray-600 text-left group"
              >
                <div className="flex items-center gap-1">
                  Product
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "product" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
              <th
                onClick={() => sortStatusTable("price")}
                className="border p-[8px] text-[14px] cursor-pointer whitespace-nowrap font-medium text-gray-600 text-left group"
              >
                <div className="flex items-center gap-1">
                  Prize
                  <AiOutlineCaretDown
                    size={16}
                    className={`transform ${
                      sortBy === "price" ? "" : "hidden"
                    } ${
                      sortDir === "asc" ? "rotate-[180deg]" : ""
                    } transition-opacity duration-300 ease-in-out group-hover:block shrink-0`}
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {loader ? (
              <tr>
                <td colSpan={25}>
                  <div className="text-center p-[28px] flex items-center justify-center">
                    <BiLoaderCircle
                      size={62}
                      className="animate-[spin_3s_linear_infinite] text-[#0080A6]"
                    />
                  </div>
                </td>
              </tr>
            ) : (
              list?.map((sales, key) => (
                <tr key={key}>
                  <td className="border first:border-l-0 last:border-r-0 text-center font-medium text-gray-600 p-[8px] text-[14px] whitespace-nowrap ">
                    {sales.sales_id || "-"}
                  </td>
                  <td className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left">
                    {moment(sales.created_at).format("YYYY/MM/DD") || "-"}
                  </td>
                  <td className="border first:border-l-0 last:border-r-0 text-left font-medium text-gray-600 p-[8px] text-[14px] whitespace-nowrap ">
                    <div
                      className="flex justify-center"
                      data-tooltip-id={key + "country"}
                    >
                      <img
                        className="w-[30px]"
                        src={sales.country}
                        alt={sales.product}
                      />
                    </div>
                    {/* <Tooltip id={key + "country"}>{sales.product}</Tooltip> */}
                  </td>
                  <td
                    className={`border p-[8px] text-[14px] whitespace-nowrap font-medium text-left`}
                  >
                    {sales.player || "-"}
                  </td>
                  <td
                    className={`border p-[8px] text-[14px] whitespace-nowrap font-medium text-left`}
                  >
                    {sales.referral || "-"}
                  </td>
                  <td
                    className={`border p-[8px] text-[14px] whitespace-nowrap font-medium text-left`}
                  >
                    {sales.category || "-"}
                  </td>
                  <td
                    className={`border p-[8px] text-[14px] whitespace-nowrap font-medium text-left`}
                  >
                    <div className="flex gap-2">
                      <img
                        className="w-[30px]"
                        src={sales.product_img}
                        alt={sales.product}
                      />
                      {sales.product || "-"}
                    </div>
                  </td>
                  <td
                    className={`border p-[8px] text-[14px] whitespace-nowrap font-medium text-left`}
                  >
                    ${roundToTwoDecimalPlaces(sales.price) || "-"}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="tokenomics-pagination py-10 mr-6">
        <ReactPaginate
          breakLabel="..."
          nextLabel={null}
          previousLabel={null}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pagination?.totalPages}
          forcePage={pagination?.previousPage}
          marginPagesDisplayed={2}
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
};

export default NFTStatusTable;

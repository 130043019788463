import IconLootBox from "assets/images/icon_lootbox.png";
import IconCylinder from "assets/images/icon_cylinder.png";
import IconGold from "assets/images/icon_gold.png";
import IconFood from "assets/images/icon_food.png";
import IconDiamond from "assets/images/icon_diamond.png";
import IconSilverStart from "assets/images/icon_silverstar.png";
import IconSoga from "assets/images/icon_SOGA.png";
import { useEffect, useState } from "react";

const NFTStatusTable = ({ circulateSupply }) => {
  const [totalValueSum, setTotalValueSum] = useState(0);
  const [valuePerPlayerSum, setValuePerPlayerSum] = useState(0);

  useEffect(() => {
    let valueSum = 0;
    let perPlayerSum = 0;

    Object.keys(circulateSupply).forEach((key) => {
      const data = circulateSupply[key];
      const totalValue = parseFloat(data.total_value);
      const valuePerPlayer = parseFloat(data.value_per_player);
      if (!isNaN(totalValue)) {
        valueSum += totalValue;
      }
      if (!isNaN(valuePerPlayer)) {
        perPlayerSum += valuePerPlayer;
      }
    });

    setTotalValueSum(valueSum);
    setValuePerPlayerSum(perPlayerSum);
  }, [circulateSupply]);
  const formatAndRoundAmount = (amount) => {
    if (typeof amount === "string") {
      amount = parseFloat(amount);
    }
    if (typeof amount === "number" && !Number.isNaN(amount) && amount !== 0) {
      if (amount >= 1000000) {
        return (amount / 1000000).toFixed(1) + "M";
      } else if (amount >= 1000) {
        return (amount / 1000).toFixed(1) + "K";
      } else if (amount < 0.01) {
        return amount.toFixed(3);
      } else {
        return amount.toFixed(2);
      }
    }
    if (isNaN(amount)) {
      return 0;
    }
    return amount;
  };
  return (
    <div className="bg-white w-full">
      <div className="overflow-x-auto">
        <table className="w-full ">
          <thead>
            <tr>
              <th className="p-[0_4px_16px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center  opacity-0">
                Coin
              </th>
              <th className="p-[0_4px_16px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center">
                Equiv($)
              </th>
              <th className="p-[0_4px_16px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center">
                Total amount
              </th>
              <th className="p-[0_4px_16px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center">
                Total Value($)
              </th>
              <th className="p-[0_4px_16px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center">
                Avg./player
              </th>
              <th className="p-[0_4px_16px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center">
                Value/player
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-medium text-gray-700 text-center">
                <img src={IconLootBox} alt="Logo" width={25} />
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {circulateSupply?.lootboxes?.dollar_equivalant}
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {formatAndRoundAmount(circulateSupply?.lootboxes?.total_amount)}
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {formatAndRoundAmount(circulateSupply?.lootboxes?.total_value)}
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {formatAndRoundAmount(
                  circulateSupply?.lootboxes?.avg_per_player
                )}
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {formatAndRoundAmount(
                  circulateSupply?.lootboxes?.value_per_player
                )}
              </td>
            </tr>
            <tr>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-medium text-gray-700 text-center">
                <img src={IconCylinder} alt="Logo" width={25} />
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {circulateSupply?.body_parts_chest?.dollar_equivalant}
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {formatAndRoundAmount(
                  circulateSupply?.body_parts_chest?.total_amount
                )}
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {formatAndRoundAmount(
                  circulateSupply?.body_parts_chest?.total_value
                )}
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {formatAndRoundAmount(
                  circulateSupply?.body_parts_chest?.avg_per_player
                )}
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {formatAndRoundAmount(
                  circulateSupply?.body_parts_chest?.value_per_player
                )}
              </td>
            </tr>
            <tr>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-medium text-gray-700 text-center">
                <img src={IconGold} alt="Logo" width={25} />
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {circulateSupply?.coins?.dollar_equivalant}
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {formatAndRoundAmount(circulateSupply?.coins?.total_amount)}
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {formatAndRoundAmount(circulateSupply?.coins?.total_value)}
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {formatAndRoundAmount(circulateSupply?.coins?.avg_per_player)}
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {formatAndRoundAmount(circulateSupply?.coins?.value_per_player)}
              </td>
            </tr>
            <tr>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-medium text-gray-700 text-center">
                <img src={IconFood} alt="Logo" width={25} />
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {circulateSupply?.food?.dollar_equivalant}
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {formatAndRoundAmount(circulateSupply?.food?.total_amount)}
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {formatAndRoundAmount(circulateSupply?.food?.total_value)}
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {formatAndRoundAmount(circulateSupply?.food?.avg_per_player)}
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {formatAndRoundAmount(circulateSupply?.food?.value_per_player)}
              </td>
            </tr>
            <tr>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-medium text-gray-700 text-center">
                <img src={IconDiamond} alt="Logo" width={25} />
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {circulateSupply?.diamonds?.dollar_equivalant}
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {formatAndRoundAmount(circulateSupply?.diamonds?.total_amount)}
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {formatAndRoundAmount(circulateSupply?.diamonds?.total_value)}
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {formatAndRoundAmount(
                  circulateSupply?.diamonds?.avg_per_player
                )}
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {formatAndRoundAmount(
                  circulateSupply?.diamonds?.value_per_player
                )}
              </td>
            </tr>
            <tr>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-medium text-gray-700 text-center">
                <img src={IconSilverStart} alt="Logo" width={25} />
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {circulateSupply?.stars?.dollar_equivalant}
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {formatAndRoundAmount(circulateSupply?.stars?.total_amount)}
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {formatAndRoundAmount(circulateSupply?.stars?.total_value)}
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {formatAndRoundAmount(circulateSupply?.stars?.avg_per_player)}
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {formatAndRoundAmount(circulateSupply?.stars?.value_per_player)}
              </td>
            </tr>
            <tr>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-medium text-gray-700 text-center">
                <img src={IconSoga} alt="Logo" width={25} />
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {circulateSupply?.sogas?.dollar_equivalant}
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {formatAndRoundAmount(circulateSupply?.sogas?.total_amount)}
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {formatAndRoundAmount(circulateSupply?.sogas?.total_value)}
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {formatAndRoundAmount(circulateSupply?.sogas?.avg_per_player)}
              </td>
              <td className="p-[4px_4px] text-[14px] whitespace-nowrap font-normal text-gray-700 text-center">
                {formatAndRoundAmount(circulateSupply?.sogas?.value_per_player)}
              </td>
            </tr>
            <tr>
              <th className="p-[12px_4px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center"></th>
              <th className="p-[12px_4px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center"></th>
              <th className="p-[12px_4px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center"></th>
              <th className="p-[12px_4px] text-[16px] whitespace-nowrap font-medium text-black text-center">
                ${formatAndRoundAmount(totalValueSum)}
              </th>
              <th className="p-[12px_4px] text-[16px] whitespace-nowrap font-medium text-black text-center"></th>
              <th className="p-[12px_4px] text-[16px] whitespace-nowrap font-medium text-black text-center">
                ${formatAndRoundAmount(valuePerPlayerSum)}
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NFTStatusTable;

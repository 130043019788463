import React, { useState } from "react";
import Product from "./Product";

export default function Sales({ workersSummary }) {
  const roundToTwoDecimalPlaces = (input, noDecimals = false) => {
    let number = Number(input);
    const roundedNumber = noDecimals 
      ? Math.round(number).toLocaleString() 
      : parseFloat(number.toFixed(2)).toLocaleString();
    if (isNaN(number)) return 0;
    return roundedNumber;
  };
  const bgColors = ["#5468DB", "#4BC18F", "#FFBB44", "#E97300", "#9A00E9", "#0e1fff", "#00ff00"];
  return (
    <div>
      <div className="pb-4"><h4 className="text-[18px] text-black font-semibold">Sales Ranking by product category</h4></div>
      {workersSummary.map((item, index) => (
        <div key={item.category_id} className="grid grid-cols-10 gap-2 mb-2">
          <div className="col-span-4">
            <div className="flex items-center gap-2">
              <div className="w-[10px] h-[10px]" style={{ backgroundColor: bgColors[index % bgColors.length] }}></div>
              <h6 className="text-[13px] text-black ">{item.category}</h6>
            </div>
          </div>
          <div className="col-span-3 text-end">
            <p className="text-[13px]">{roundToTwoDecimalPlaces(item.total_amount)}u</p>
          </div>
          <div className="col-span-3 text-end">
            <p className="text-[13px]">${roundToTwoDecimalPlaces(item.total_value)}</p>
          </div>
        </div>
      ))}
      <Product summaryPie={workersSummary} />
    </div>
  );
}

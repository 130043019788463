import React from "react";

export default function TotalSales({ salesRoi }) {
  const totalSales = salesRoi?.sales?.reduce(
    (sum, item) => {
      sum.heroes_amount += parseFloat(item.heroes_amount);
      sum.heroes_income += parseFloat(item.heroes_income);
      sum.vpls_amount += parseFloat(item.vpls_amount);
      sum.vpls_income += parseFloat(item.vpls_income);
      sum.supermarket_amount += parseFloat(item.supermarket_amount);
      sum.supermarket_income += parseFloat(item.supermarket_income);
      sum.marketplace_income += parseFloat(item.marketplace_income);
      sum.marketplace_amount += parseFloat(item.marketplace_amount);
      sum.rentals_income += parseFloat(item.rentals_income);
      sum.rentals_amount += parseFloat(item.rentals_amount);
      return sum;
    },
    {
      heroes_amount: 0,
      heroes_income: 0,
      vpls_amount: 0,
      vpls_income: 0,
      supermarket_amount: 0,
      supermarket_income: 0,
      marketplace_income:0,
      marketplace_amount:0,
      rentals_income:0,
      rentals_amount:0,
    }
  );

  let totalAmount = 0;
  let totalIncome = 0;

  for (const key in totalSales) {
      if (totalSales.hasOwnProperty(key)) {
          if (key.includes('_amount')) {
              totalAmount += totalSales[key];
          }
          if (key.includes('_income')) {
              totalIncome += totalSales[key];
          }
      }
  }

  const roundToTwoDecimalPlaces = (input) => {
    const number = parseFloat(input);

    if (typeof number === "number" && !Number.isNaN(number) && number !== 0) {
      const roundedNumber = parseFloat(number.toFixed(2));
      return roundedNumber.toLocaleString();
    }

    if (isNaN(number)) {
      return 0;
    }

    return input;
  };
  return (
    <div className="flex flex-col">
      <div className="p-6">
        <div className="grid grid-cols-8 gap-2 w-full">
          <div className="col-span-4">
            <h4 className="text-[20px] text-black font-semibold mb-4">
              Total sales in the period
            </h4>
          </div>
          <div className="col-span-2 text-right">
            <p>Units</p>
          </div>
          <div className="col-span-2 text-right">
            <p>Incomes</p>
          </div>
        </div>
        <div className="grid grid-cols-8 gap-2 w-full">
          <div className="col-span-4">
            <div className="flex items-center gap-2">
              <div className="w-[10px] h-[10px] bg-[#5468DB]"></div>
              <h6 className="text-[15px] text-black ">Heroes</h6>
            </div>
          </div>
          <div className="col-span-2 text-right">
            <p>{roundToTwoDecimalPlaces(totalSales?.heroes_amount)}u</p>
          </div>
          <div className="col-span-2 text-right">
            <p>${roundToTwoDecimalPlaces(totalSales?.heroes_income)}</p>
          </div>
        </div>
        <div className="grid grid-cols-8 gap-2 w-full">
          <div className="col-span-4">
            <div className="flex items-center gap-2">
              <div className="w-[10px] h-[10px] bg-[#4BC18F]"></div>
              <h6 className="text-[15px] text-black ">VPLs</h6>
            </div>
          </div>
          <div className="col-span-2 text-right">
            <p>{roundToTwoDecimalPlaces(totalSales?.vpls_amount)}u</p>
          </div>
          <div className="col-span-2 text-right">
            <p>${roundToTwoDecimalPlaces(totalSales?.vpls_income)}</p>
          </div>
        </div>
        <div className="grid grid-cols-8 gap-2 w-full">
          <div className="col-span-4">
            <div className="flex items-center gap-2">
              <div className="w-[10px] h-[10px] bg-[#FFBB44]"></div>
              <h6 className="text-[15px] text-black ">Supermarket</h6>
            </div>
          </div>
          <div className="col-span-2 text-right">
            <p>{roundToTwoDecimalPlaces(totalSales?.supermarket_amount)}u</p>
          </div>
          <div className="col-span-2 text-right">
            <p>${roundToTwoDecimalPlaces(totalSales?.supermarket_income)}</p>
          </div>
        </div>
        <div className="grid grid-cols-8 gap-2 w-full">
          <div className="col-span-4">
            <div className="flex items-center gap-2">
              <div className="w-[10px] h-[10px] bg-[#0e1fff]"></div>
              <h6 className="text-[15px] text-black ">MarketPlace</h6>
            </div>
          </div>
          <div className="col-span-2 text-right">
            <p>{roundToTwoDecimalPlaces(totalSales?.marketplace_amount)}u</p>
          </div>
          <div className="col-span-2 text-right">
            <p>${roundToTwoDecimalPlaces(totalSales?.marketplace_income)}</p>
          </div>
        </div>
        <div className="grid grid-cols-8 gap-2 w-full">
          <div className="col-span-4">
            <div className="flex items-center gap-2">
              <div className="w-[10px] h-[10px] bg-[#9A00E9]"></div>
              <h6 className="text-[15px] text-black ">Rentals</h6>
            </div>
          </div>
          <div className="col-span-2 text-right">
            <p>{roundToTwoDecimalPlaces(totalSales?.rentals_amount)}u</p>
          </div>
          <div className="col-span-2 text-right">
            <p>${roundToTwoDecimalPlaces(totalSales?.rentals_income)}</p>
          </div>
        </div>
        <div className="grid grid-cols-8 gap-2 w-full">
          <div className="col-span-4">
            <div className="flex items-center gap-2">
              <div className="w-[10px] h-[10px] bg-[#fff]"></div>
              <h6 className="text-[15px] text-black ">Total</h6>
            </div>
          </div>
          <div className="col-span-2 text-right">
            <p>{roundToTwoDecimalPlaces(totalAmount)}u</p>
          </div>
          <div className="col-span-2 text-right">
            <p>${roundToTwoDecimalPlaces(totalIncome)}</p>
          </div>
        </div>
      </div>
      <div className="bg-[#FFF0D7] py-6 px-4 mt-6">
        <div className="grid grid-cols-2 gap-4 w-full">
          <div>
            <h4 className="text-[16px] whitespace-nowrap text-black font-semibold mb-4">
              All The time Status
            </h4>
            <h6 className="text-[14px] text-black font-medium">
              Total streamer cost
            </h6>
            <h6 className="text-[14px] text-black font-medium">Total sales</h6>
            <h6 className="text-[14px] text-black font-medium">ROI</h6>
          </div>
          <div className="text-right">
            <h4 className="text-[16px] whitespace-nowrap text-black font-semibold mb-4">
              Results
            </h4>
            <h6 className="text-[14px] text-black font-medium">
              $
              {roundToTwoDecimalPlaces(
                salesRoi?.all_time_status?.total_streamer_cost
              )}
            </h6>
            <h6 className="text-[14px] text-black font-medium">
              ${roundToTwoDecimalPlaces(salesRoi?.all_time_status?.total_sales)}
            </h6>
            <h6 className="text-[14px] text-black font-medium">
              {roundToTwoDecimalPlaces(salesRoi?.all_time_status?.roi)}
            </h6>
          </div>
        </div>
      </div>
      <div className="bg-[#e6ffd7] py-6 px-4 mt-1">
        <div className="grid grid-cols-2 gap-4 w-full">
          <div>
            <h4 className="text-[16px] whitespace-nowrap text-black font-semibold mb-4">
              {salesRoi?.current_period?.period}
            </h4>
            <h6 className="text-[14px] text-black font-medium">
              Streamer cost
            </h6>
            <h6 className="text-[14px] text-black font-medium">Total sales</h6>
            <h6 className="text-[14px] text-black font-medium">ROI</h6>
          </div>
          <div className="text-right">
            <h4 className="text-[16px] whitespace-nowrap text-black font-semibold mb-4">
              Results
            </h4>
            <h6 className="text-[14px] text-black font-medium">
              $
              {roundToTwoDecimalPlaces(
                salesRoi?.current_period?.total_streamer_cost
              )}
            </h6>
            <h6 className="text-[14px] text-black font-medium">
              ${roundToTwoDecimalPlaces(salesRoi?.current_period?.total_sales)}
            </h6>
            <h6 className="text-[14px] text-black font-medium">
              {roundToTwoDecimalPlaces(salesRoi?.current_period?.roi)}
            </h6>
          </div>
        </div>
      </div>
      <div className="bg-[#98A5EE] py-6 px-4 mt-1">
        <div className="grid grid-cols-2 gap-4 w-full">
          <div>
            <h4 className="text-[16px] whitespace-nowrap text-black font-semibold mb-4">
              {salesRoi?.filter_period?.period}
            </h4>
            <h6 className="text-[14px] text-black font-medium">
              Streamer cost
            </h6>
            <h6 className="text-[14px] text-black font-medium">Total sales</h6>
            <h6 className="text-[14px] text-black font-medium">ROI</h6>
          </div>
          <div className="text-right">
            <h4 className="text-[16px] whitespace-nowrap text-black font-semibold mb-4">
              Results
            </h4>
            <h6 className="text-[14px] text-black font-medium">
              $
              {roundToTwoDecimalPlaces(
                salesRoi?.filter_period?.total_streamer_cost
              )}
            </h6>
            <h6 className="text-[14px] text-black font-medium">
              ${roundToTwoDecimalPlaces(salesRoi?.filter_period?.total_sales)}
            </h6>
            <h6 className="text-[14px] text-black font-medium">
              {roundToTwoDecimalPlaces(salesRoi?.filter_period?.roi)}
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
}

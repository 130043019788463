import ListBoxSelect from "../ListBoxSelect";
import StatusSelect from "../StatusSelect";
import AutocompleteBox from "../AutocompleteBox";
import { useEffect, useState } from "react";
import { postNewKOLList, updateNewKOLList } from "../../../../api";
import { BiLoaderCircle } from "react-icons/bi";
import moment from "moment";
const statusList = [
  { title: "waiting to start", value: 0 },
  { title: "test period", value: 1 },
  { title: "ambassador", value: 10 },
  { title: "cancelled", value: -99 },
];
const AddKOLDetails = ({
  stream,
  setQuery,
  filteredName,
  query,
  kolType,
  handleFilterName,
  fetchKOLAccountList,
  editAccountList,
  defaultNickSaga,
  type,
}) => {
  const [loader, setLoader] = useState(false);
  const [selected, setSelected] = useState("");
  const [statusSelected, setStatusSelected] = useState("");
  const [typeSelected, setTypeSelected] = useState("");
  const [nicknameSelected, setNicknameSelected] = useState("");
  const [kolTypeForm, setKolTypeForm] = useState({
    nickname: "",
    player_id: "",
    kol_type: "",
    media_channel: "",
    channel_url: "",
    hours_per_week: "",
    agreement_start_at: "",
    agreement_end_at: "",
    initial_monthly_fee: "",
    status: "",
    stats_access: false,
  });
  useEffect(() => {
    if (type === "update" && Object.keys(editAccountList).length !== 0) {
      setKolTypeForm(editAccountList);
      setKolTypeForm((prevState) => ({
        ...prevState,
        agreement_start_at: moment(editAccountList?.agreement_start_at).format(
          "YYYY-MM-DD"
        ),
      }));
      setKolTypeForm((prevState) => ({
        ...prevState,
        agreement_end_at: moment(editAccountList?.agreement_end_at).format(
          "YYYY-MM-DD"
        ),
      }));
      const kol_type = kolType?.find(
        (item) => item.id === editAccountList?.kol_type
      );
      const stream_selected = stream?.find(
        (item) => item.id === editAccountList?.kol_type
      );
      const statuSelected = statusList?.find(
        (item) => item.value === editAccountList?.status
      );
      const nick_name_soga = filteredName[0];

      setTypeSelected(kol_type);
      setSelected(stream_selected);
      setStatusSelected(statuSelected);
      setNicknameSelected(nick_name_soga);
    }
  }, [editAccountList, filteredName]);
  const [errors, setErrors] = useState({
    nickname: "",
    player_id: "",
    kol_type: "",
    media_channel: "",
    channel_url: "",
    hours_per_week: "",
    agreement_start_at: "",
    agreement_end_at: "",
    initial_monthly_fee: "",
    status: "",
  });
  const handleKolType = (e) => {
    setErrors((errors.kol_type = validateField("kol_type", e.id)));
    setKolTypeForm((prevState) => ({
      ...prevState,
      kol_type: e.id,
    }));
  };
  const handleStreamMedia = (e) => {
    setErrors((prevState) => ({
      ...prevState,
      media_channel: validateField("media_channel", e.id),
    }));
    setKolTypeForm((prevState) => ({
      ...prevState,
      media_channel: e.id,
    }));
  };
  const handleKolTypeForm = (value, key) => {
    setKolTypeForm((prevState) => ({
      ...prevState,
      [key]: value,
    }));

    let errorMessage = validateField(key, value);

    if (key === "initial_monthly_fee" && value && !/^\d+$/.test(value)) {
      errorMessage = "Initial Monthly Fee should be a numeric value.";
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [key]: errorMessage,
    }));
  };
  const validateField = (key, value) => {
    switch (key) {
      case "nickname":
        if (!value) {
          return "Nickname is required.";
        }
        return "";
      case "player_id":
        if (!value) {
          return "Player Id is required.";
        }
        return "";
      case "kol_type":
        if (!value) {
          return "Please select Kol Type.";
        }
        return "";
      case "media_channel":
        if (!value) {
          return "Please select Media Channel.";
        }
        return "";
      case "channel_url":
        if (!value) {
          return "Please add channel url.";
        }
        return "";
      case "hours_per_week":
        if (!value) {
          return "Please add hours.";
        }
        return "";
      case "agreement_start_at":
        if (!value) {
          return "Please Select start date.";
        }
        return "";
      case "status":
        if (value === "") {
          return "Please Select status.";
        }
        return "";
      case "agreement_end_at":
        if (!value) {
          return "Please Select end date.";
        } else if (
          moment(kolTypeForm.agreement_end_at).isSameOrBefore(
            kolTypeForm.agreement_start_at
          )
        ) {
          return "End date must be after start date.";
        }
        return "";
      case "initial_monthly_fee":
        if (!value) {
          return "Please add monthly fee.";
        }
        return "";
      default:
        return "";
    }
  };
  const validateForm = () => {
    const newErrors = {};

    Object.entries(kolTypeForm).forEach(([fieldName, fieldValue]) => {
      const errorMessage = validateField(fieldName, fieldValue);
      if (errorMessage) {
        newErrors[fieldName] = errorMessage;
      }
    });

    setErrors(newErrors);

    // Check if there are any errors
    return Object.keys(newErrors).length === 0;
  };
  const addNewKol = async () => {
    setLoader(true);
    const isValid = validateForm();
    if (isValid) {
      await postNewKOLList(kolTypeForm);
      clearKOLForm();
      fetchKOLAccountList();
    }
    setLoader(false);
  };
  const UpdateNewKol = async () => {
    setLoader(true);

    const isValid = validateForm();
    if (isValid) {
      await updateNewKOLList(kolTypeForm);
      clearKOLForm();
      fetchKOLAccountList();
    }
    setLoader(false);
  };
  const clearKOLForm = () => {
    setKolTypeForm({
      nickname: "",
      player_id: "",
      kol_type: "",
      media_channel: "",
      channel_url: "",
      hours_per_week: "",
      agreement_start_at: "",
      agreement_end_at: "",
      initial_monthly_fee: "",
      status: 0,
      stats_access: false,
    });
    setSelected("");
    setTypeSelected("");
    setNicknameSelected("");
  };
  const selectStatus = async (param) => {
    setKolTypeForm((prevState) => ({
      ...prevState,
      status: param.value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      status: validateField("status", param.value),
    }));
  };

  return (
    <div className="p-6 rounded-[2px] border bg-white w-full">
      <h3 className="text-[18px] font-bold text-black mb-3">KOL details</h3>
      <div className="grid grid-cols-3 gap-16">
        <div className="flex flex-col gap-2">
          <div>
            <div className="grid grid-cols-2 items-center gap-5">
              <label className="text-[16px] font-medium text-black/80 ">
                Nickname
              </label>
              <input
                type="text"
                value={kolTypeForm.nickname}
                onChange={(e) => handleKolTypeForm(e.target.value, "nickname")}
                className="h-[40px] px-3 border w-full outline-0 border-[#E8E7E4]"
              />
            </div>
            {errors.nickname && (
              <p className="text-red-500 text-[12px] text-right">
                {errors.nickname}
              </p>
            )}
          </div>
          <div>
            <div className="grid grid-cols-2 items-center gap-5">
              <label className="text-[16px] font-medium text-black/80 ">
                Nickname in Soga
              </label>
              <AutocompleteBox
                setQuery={setQuery}
                filteredName={filteredName}
                handleFilterName={handleFilterName}
                handleKolTypeForm={handleKolTypeForm}
                query={query}
                selected={nicknameSelected}
                setSelected={setNicknameSelected}
                defaultNickSaga={defaultNickSaga}
              />
            </div>
            {errors.player_id && (
              <p className="text-red-500 text-[12px] text-right">
                {errors.nickname}
              </p>
            )}
          </div>
          <div>
            <div className="grid grid-cols-2 items-center gap-5">
              <label className="text-[16px] font-medium text-black/80 ">
                KOL Type
              </label>
              <ListBoxSelect
                options={kolType}
                onChange={handleKolType}
                selected={typeSelected}
                setSelected={setTypeSelected}
              />
            </div>
            {errors.kol_type && (
              <p className="text-red-500 text-[12px] text-right">
                {errors.kol_type}
              </p>
            )}
          </div>
          <div>
            <div className="grid grid-cols-2 items-center gap-5 relative">
              <label className="text-[16px] font-medium text-black/80 ">
                Started
              </label>
              <div className="relative">
                <input
                  type="text"
                  value={kolTypeForm.agreement_start_at}
                  onChange={(e) => {
                    handleKolTypeForm(e.target.value, "agreement_start_at");
                  }}
                  className="h-[40px] px-3 border w-full outline-0 border-[#E8E7E4] placeholder:text-white"
                />
                <input
                  type="date"
                  value={kolTypeForm.agreement_start_at}
                  onChange={(e) => {
                    handleKolTypeForm(e.target.value, "agreement_start_at");
                  }}
                  className="absolute inset-0 opacity-0 z-1"
                />
              </div>
            </div>
            {errors.agreement_start_at && (
              <p className="text-red-500 text-[12px] text-right">
                {errors.agreement_start_at}
              </p>
            )}
          </div>
          <div>
            <div className="grid grid-cols-2 items-center gap-5 relative">
              <label className="text-[16px] font-medium text-black/80 ">
                Finished
              </label>
              <div className="relative">
                <input
                  type="text"
                  value={kolTypeForm.agreement_end_at}
                  onChange={(e) => {
                    handleKolTypeForm(e.target.value, "agreement_end_at");
                  }}
                  className="h-[40px] px-3 border w-full outline-0 border-[#E8E7E4] placeholder:text-white"
                />
                <input
                  type="date"
                  value={kolTypeForm.agreement_end_at}
                  onChange={(e) => {
                    handleKolTypeForm(e.target.value, "agreement_end_at");
                  }}
                  className="absolute inset-0 opacity-0 z-1"
                />
              </div>
            </div>
            {errors.agreement_end_at && (
              <p className="text-red-500 text-[12px] text-right">
                {errors.agreement_end_at}
              </p>
            )}
          </div>
          <div>
            <div className="grid grid-cols-2 items-center gap-5 relative">
              <label className="text-[16px] font-medium text-black/80 ">
                Status
              </label>
              <div className="relative">
                <StatusSelect
                  options={statusList}
                  onChange={selectStatus}
                  statusSelected={statusSelected}
                  setStatusSelected={setStatusSelected}
                />
              </div>
            </div>
            {errors.status && (
              <p className="text-red-500 text-[12px] text-right">
                {errors.status}
              </p>
            )}
          </div>
          <div>
            <div className="grid grid-cols-2 items-center gap-5 relative">
              <label className="text-[16px] font-medium text-black/80 ">
                Stats access
              </label>
              <div className="relative">
                <input
                  type="checkbox"
                  value={""}
                  checked={kolTypeForm.stats_access}
                  onChange={(e) => {
                    handleKolTypeForm(e.target.checked, "stats_access");
                  }}
                />
              </div>
            </div>
            {errors.status && (
              <p className="text-red-500 text-[12px] text-right">
                {errors.status}
              </p>
            )}
          </div>
        </div>
        <div className="col-span-2 flex flex-col gap-2">
          <div className="flex gap-3 items-center">
            <label className="text-[16px] font-medium w-[24%] block text-black/80 ">
              Streaming in this media
            </label>
            <div className="w-[24%]">
              <ListBoxSelect
                options={stream}
                onChange={handleStreamMedia}
                selected={selected}
                setSelected={setSelected}
              />
              {errors.media_channel && (
                <p className="text-red-500 text-[12px] text-right">
                  {errors.media_channel}
                </p>
              )}
            </div>
          </div>

          <div className="flex gap-3 items-center">
            <label className="text-[16px] font-medium w-[24%] block text-black/80 ">
              Url of this channel
            </label>
            <div className="w-[24%]">
              <input
                type="text"
                value={kolTypeForm.channel_url}
                onChange={(e) =>
                  handleKolTypeForm(e.target.value, "channel_url")
                }
                className="h-[40px] px-3 w-full border outline-0 border-[#E8E7E4]"
              />
              {errors.channel_url && (
                <p className="text-red-500 text-[12px] text-right">
                  {errors.channel_url}
                </p>
              )}
            </div>
          </div>
          <div className="flex gap-3 items-center">
            <label className="text-[16px] font-medium w-[24%] block text-black/80 ">
              Hours per week deal
            </label>
            <div className="w-[24%]">
              <input
                type="text"
                value={kolTypeForm.hours_per_week}
                onChange={(e) =>
                  handleKolTypeForm(e.target.value, "hours_per_week")
                }
                className="h-[40px] px-3 w-full border outline-0 border-[#E8E7E4]"
              />
              {errors.hours_per_week && (
                <p className="text-red-500 text-[12px] text-right">
                  {errors.hours_per_week}
                </p>
              )}
            </div>
            <label className="text-[16px] font-medium w-[24%] block text-black/80 ">
              hours
            </label>
          </div>
          <div className="flex gap-3 items-center">
            <label className="text-[16px] font-medium w-[24%] block text-black/80 ">
              Initial monthly fee
            </label>
            <div className="w-[24%]">
              <input
                type="text"
                value={kolTypeForm.initial_monthly_fee}
                onChange={(e) =>
                  handleKolTypeForm(e.target.value, "initial_monthly_fee")
                }
                className="h-[40px] px-3 w-full border outline-0 border-[#E8E7E4]"
              />{" "}
              {errors.initial_monthly_fee && (
                <p className="text-red-500 text-[12px] text-right">
                  {errors.initial_monthly_fee}
                </p>
              )}
            </div>
            <label className="text-[16px] font-medium w-[24%] block text-black/80 ">
              USD$
            </label>
          </div>
        </div>
      </div>
      <div className="flex justify-end mt-12">
        {loader ? (
          <button className="h-[40px] px-8 text-[16px] text-white flex items-center justify-center bg-[#0080A6]">
            <BiLoaderCircle
              size={22}
              className="animate-[spin_3s_linear_infinite]"
            />
          </button>
        ) : (
          <button
            onClick={type == "update" ? UpdateNewKol : addNewKol}
            className="h-[40px] px-8 text-[16px] text-white flex items-center justify-center bg-[#0080A6]"
          >
            {type == "update" ? "Update data" : "Add a new KOL"}
          </button>
        )}
      </div>
    </div>
  );
};

export default AddKOLDetails;

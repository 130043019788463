import React from "react";

export default function Monthly({ kpiAccount, kpisPeriod }) {
  const roundToTwoDecimalPlaces = (number) => {
    if (typeof number === "number" && !Number.isNaN(number) && number !== 0) {
      return parseFloat(number.toFixed(2));
    }
    return number;
  };
  const formatPrice = (price) => {
    if (typeof price === "string") {
      price = parseFloat(price);
    }
    if (!isNaN(price) && price !== undefined && price !== null) {
      return price.toLocaleString();
    }
    return price;
  };
  return (
    <div className="flex flex-col">
      <h4 className="text-[#FFBB44] text-[18px] font-semibold">
        New accounts in the period
      </h4>
      <div className="flex items-center gap-3">
        <div className="w-[80px]">
          <p className="text-[18px] text-black font-medium text-right leading-none">
            {formatPrice(kpisPeriod?.new_accounts?.new_accounts_in_the_period)}
          </p>
        </div>
        <div className="grow">
          <p className="text-[16px] text-black font-medium">
            New accounts in the period
          </p>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <div className="w-[80px]">
          <p className="text-[18px] text-black font-medium text-right leading-none">
            {formatPrice(kpisPeriod?.new_accounts?.new_active_users)}
          </p>
        </div>
        <div className="grow">
          <p className="text-[16px] text-black font-medium">New active users</p>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <div className="w-[80px]">
          <p className="text-[18px] text-black font-medium text-right leading-none">
            {formatPrice(kpisPeriod?.new_accounts?.new_paying_accounts)}
          </p>
        </div>
        <div className="grow">
          <p className="text-[16px] text-black font-medium">
            New paying accounts
          </p>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <div className="w-[80px]">
          <p className="text-[18px] text-black font-medium text-right leading-none">
            $
            {formatPrice(
              roundToTwoDecimalPlaces(kpisPeriod?.new_accounts?.total_sales)
            )}
          </p>
        </div>
        <div className="grow">
          <p className="text-[16px] text-black font-medium">Total Sales</p>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <div className="w-[80px]">
          <p className="text-[18px] text-black font-medium text-right leading-none">
            ${kpisPeriod?.new_accounts?.arpu}
          </p>
        </div>
        <div className="grow">
          <p className="text-[16px] text-black font-medium">ARPU</p>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <div className="w-[80px]">
          <p className="text-[18px] text-black font-medium text-right leading-none">
            ${kpisPeriod?.new_accounts?.arppu}
          </p>
        </div>
        <div className="grow">
          <p className="text-[16px] text-black font-medium">ARPPU</p>
        </div>
      </div>

      <h4 className="text-[#FFBB44] text-[18px] font-semibold">
        All accounts in the period
      </h4>
      <div className="flex items-center gap-3">
        <div className="w-[80px]">
          <p className="text-[18px] text-black font-medium text-right leading-none">
            {formatPrice(kpisPeriod?.all_accounts?.total_active_users)}
          </p>
        </div>
        <div className="grow">
          <p className="text-[16px] text-black font-medium">
            Total active users
          </p>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <div className="w-[80px]">
          <p className="text-[18px] text-black font-medium text-right leading-none">
            {formatPrice(kpisPeriod?.all_accounts?.total_paying_accounts)}
          </p>
        </div>
        <div className="grow">
          <p className="text-[16px] text-black font-medium">
            Total paying accounts
          </p>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <div className="w-[80px]">
          <p className="text-[18px] text-black font-medium text-right leading-none">
            {roundToTwoDecimalPlaces(
              kpisPeriod?.all_accounts?.ratio_of_paying_accounts
            )}
            %
          </p>
        </div>
        <div className="grow">
          <p className="text-[16px] text-black font-medium">
            Ratio of paying accounts
          </p>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <div className="w-[80px]">
          <p className="text-[18px] text-black font-medium text-right leading-none">
            $
            {formatPrice(
              roundToTwoDecimalPlaces(kpisPeriod?.all_accounts?.total_sales)
            )}
          </p>
        </div>
        <div className="grow">
          <p className="text-[16px] text-black font-medium">Total Sales</p>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <div className="w-[80px]">
          <p className="text-[18px] text-black font-medium text-right leading-none">
            ${kpisPeriod?.all_accounts?.arpu}
          </p>
        </div>
        <div className="grow">
          <p className="text-[16px] text-black font-medium">ARPU</p>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <div className="w-[80px]">
          <p className="text-[18px] text-black font-medium text-right leading-none">
            ${kpisPeriod?.all_accounts?.arppu}
          </p>
        </div>
        <div className="grow">
          <p className="text-[16px] text-black font-medium">ARPPU</p>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <div className="grow">
          <h4 className="text-[#FFBB44] text-[18px] font-semibold">
            All accounts in the last month (
            <span className="font-bold">
              {kpisPeriod?.last_month?.month_name}
            </span>
            )
          </h4>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <div className="w-[80px]">
          <p className="text-[18px] text-black font-medium text-right leading-none">
            {formatPrice(kpisPeriod?.last_month?.total_active_users)}
          </p>
        </div>
        <div className="grow">
          <p className="text-[16px] text-black font-medium">
            Total active users
          </p>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <div className="w-[80px]">
          <p className="text-[18px] text-black font-medium text-right leading-none">
            {formatPrice(kpisPeriod?.last_month?.total_paying_accounts)}
          </p>
        </div>
        <div className="grow">
          <p className="text-[16px] text-black font-medium">
            Total paying accounts
          </p>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <div className="w-[80px]">
          <p className="text-[18px] text-black font-medium text-right leading-none">
            {kpisPeriod?.last_month?.ratio_of_paying_accounts}%
          </p>
        </div>
        <div className="grow">
          <p className="text-[16px] text-black font-medium">
            Ratio of paying accounts
          </p>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <div className="w-[80px]">
          <p className="text-[18px] text-black font-medium text-right leading-none">
            $
            {formatPrice(
              roundToTwoDecimalPlaces(kpisPeriod?.last_month?.total_sales)
            )}
          </p>
        </div>
        <div className="grow">
          <p className="text-[16px] text-black font-medium">Total Sales</p>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <div className="w-[80px]">
          <p className="text-[18px] text-black font-medium text-right leading-none">
            ${kpisPeriod?.last_month?.arpu}
          </p>
        </div>
        <div className="grow">
          <p className="text-[16px] text-black font-medium">ARPU</p>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <div className="w-[80px]">
          <p className="text-[18px] text-black font-medium text-right leading-none">
            ${kpisPeriod?.last_month?.arppu}
          </p>
        </div>
        <div className="grow">
          <p className="text-[16px] text-black font-medium">ARPPU</p>
        </div>
      </div>
    </div>
  );
}

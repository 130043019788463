import { BiLoaderCircle } from "react-icons/bi";

export default function LoginToken() {
  return (
    <div className="h-screen flex items-center justify-center">
      <BiLoaderCircle
        size={100}
        className="animate-[spin_3s_linear_infinite] text-[#419aa5]"
      />
    </div>
  );
}

import { BiLoaderCircle } from "react-icons/bi";
import ReactPaginate from "react-paginate";
import moment from "moment";
const TokenomicsTable = ({
  tokenomics,
  pagination,
  handlePageClick,
  status,
  loader,
}) => {
  const roundToTwoDecimalPlaces = (input) => {
    const number = parseFloat(input);

    if (typeof number === "number" && !Number.isNaN(number) && number !== 0) {
      const roundedNumber = parseFloat(number.toFixed(2));
      return roundedNumber.toLocaleString();
    }

    if (isNaN(number)) {
      return 0;
    }

    return input;
  };
  return (
    <div className="bg-white w-full">
      <div className="overflow-x-auto">
        <table className="w-full min-w-[800px]">
          <thead>
            <tr>
              <th className="border border-l-0 text-center font-medium text-gray-600 p-[8px] text-[14px] whitespace-nowrap">
                Sales Id
              </th>
              <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center">
                Log Id
              </th>
              <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center">
                Date
              </th>
              <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left">
                Player
              </th>
              <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center">
                NFT Type
              </th>
              <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left">
                Collection
              </th>
              <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left">
                Collect Index
              </th>
              <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left">
                Global NFT Id
              </th>
              <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left">
                NFT Id
              </th>
              <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left">
                Name
              </th>
              <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left">
                Price/Coin
              </th>
              <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left">
                Amount sent
              </th>
              <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left">
                Status
              </th>
              <th className="border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left">
                Error Description
              </th>
            </tr>
          </thead>
          <tbody>
            {loader ? (
              <tr>
                <td colSpan={14}>
                  <div className="text-center p-[28px] flex items-center justify-center">
                    <BiLoaderCircle
                      size={62}
                      className="animate-[spin_3s_linear_infinite] text-[#0080A6]"
                    />
                  </div>
                </td>
              </tr>
            ) : (
              tokenomics?.map((item, key) => (
                <tr key={key}>
                  <th
                    className={`${
                      item.status === "FAILED" || item.status === "B. FAILED"
                        ? "text-red-500"
                        : "text-black"
                    } border first:border-l-0 last:border-r-0 text-center font-medium text-gray-600 p-[8px] text-[14px] whitespace-nowrap `}
                  >
                    {item.sales_id}
                  </th>
                  <th
                    className={`${
                      item.status === "FAILED" || item.status === "B. FAILED"
                        ? "text-red-500"
                        : "text-black"
                    } border p-[8px] text-[14px] whitespace-nowrap font-medium text-center text-gray-600 cursor-pointer`}
                  >
                    {item.log_id}
                  </th>
                  <th
                    className={`${
                      item.status === "FAILED" || item.status === "B. FAILED"
                        ? "text-red-500"
                        : "text-black"
                    } border p-[8px] text-[14px] whitespace-nowrap font-medium text-center text-gray-600 cursor-pointer`}
                  >
                    {moment(item.dated).format("YYYY/MM/DD HH:mm:ss")}
                  </th>
                  <th
                    className={`${
                      item.status === "FAILED" || item.status === "B. FAILED"
                        ? "text-red-500"
                        : "text-black"
                    } border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left`}
                  >
                    {item.player}
                  </th>
                  <th
                    className={`${
                      item.status === "FAILED" || item.status === "B. FAILED"
                        ? "text-red-500"
                        : "text-black"
                    } border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-center`}
                  >
                    {item.nft_type}
                  </th>
                  <th
                    className={`${
                      item.status === "FAILED" || item.status === "B. FAILED"
                        ? "text-red-500"
                        : "text-black"
                    } border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left`}
                  >
                    {item.edition_name}
                  </th>
                  <th
                    className={`${
                      item.status === "FAILED" || item.status === "B. FAILED"
                        ? "text-red-500"
                        : "text-black"
                    } border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left`}
                  >
                    {item.collect_index}
                  </th>
                  <th
                    className={`${
                      item.status === "FAILED" || item.status === "B. FAILED"
                        ? "text-red-500"
                        : "text-black"
                    } border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left`}
                  >
                    {item.global_nft_id}
                  </th>
                  <th
                    className={`${
                      item.status === "FAILED" || item.status === "B. FAILED"
                        ? "text-red-500"
                        : "text-black"
                    } border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left`}
                  >
                    {item.nft_id}
                  </th>
                  <th
                    className={`${
                      item.status === "FAILED" || item.status === "B. FAILED"
                        ? "text-red-500"
                        : "text-black"
                    } border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left`}
                  >
                    {item.name}
                  </th>
                  <th
                    className={`${
                      item.status === "FAILED" || item.status === "B. FAILED"
                        ? "text-red-500"
                        : "text-black"
                    } border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left`}
                  >
                    {roundToTwoDecimalPlaces(item.real_price)} USDC
                  </th>
                  <td
                    className={`${
                      item.status === "FAILED" || item.status === "B. FAILED"
                        ? "text-red-500"
                        : "text-black"
                    } border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left`}
                  >
                    {roundToTwoDecimalPlaces(item.sales_price)} USDC
                  </td>
                  <td
                    className={`${
                      item.status === "FAILED" || item.status === "B. FAILED"
                        ? "text-red-500"
                        : "text-black"
                    } border p-[8px] text-[14px] whitespace-nowrap font-medium text-gray-600 text-left`}
                  >
                    {item.status}
                  </td>
                  <td
                    className={`${
                      item.status === "FAILED" || item.status === "B. FAILED"
                        ? "text-red-500"
                        : "text-black"
                    } border p-[8px] text-[14px] whitespace-break-spaces font-medium text-gray-600 text-left w-[150px]`}
                  >
                    {item.error_desc === "" ? "-" : item.error_desc}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="tokenomics-pagination my-10 mr-6">
        <ReactPaginate
          breakLabel="..."
          nextLabel={null}
          previousLabel={null}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pagination?.totalPages}
          forcePage={pagination?.previousPage}
          marginPagesDisplayed={2}
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
};

export default TokenomicsTable;

import { useEffect, useState } from "react";
import CirculateStatusTable from "./components/CirculateStatusTable";
import {
  getCirculatingSupply,
  getEstimatedWorth,
  getAccumulatedHeroes,
  getCirculantPlayers,
  getItemsMechanicsProformance,
  getAllKol,
} from "api";
import ListBoxSelect from "./components/ListBoxSelect";
import { BiLoaderCircle } from "react-icons/bi";
import moment from "moment";
import Chart from "./components/Chart";
import MiniChart from "./components/MiniChart";
import CirculatingTable from "./components/CirculatingTable";

export default function NFTStatus() {
  const [from, setFrom] = useState(
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );
  const [to, setTo] = useState(moment().format("YYYY-MM-DD"));
  const [activeFrom, setActiveFrom] = useState(
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );
  const [activeTo, setActiveTo] = useState(moment().format("YYYY-MM-DD"));
  const [referalCode, setReferalCode] = useState({
    nickname: "",
    referal_code: "",
  });
  const [page, setPage] = useState(1);
  const [circulateSupply, setCirculateSupply] = useState({});
  const [estimatedWorth, setEstimatedWorth] = useState({});
  const [accumulatedHeroes, setAccumulatedHeroes] = useState([]);
  const [accumulatedChart, setAccumulatedChart] = useState([]);
  const [circulantPlayers, setCirculantPlayers] = useState([]);
  const [loader, setLoader] = useState(false);
  const [nickname, setNickname] = useState("");
  const [sortBy, setSortBy] = useState("created_at");
  const [sortDir, setSortDir] = useState("asc");
  const [mechanics, setMechanics] = useState(null);
  const [allKol, setAllKol] = useState([]);
  const [activeOnlyPaying, setActiveOnlyPaying] = useState(false);

  useEffect(() => {
    fetchCirculatingSupply();
    fetchEstimatedWorth();
    fetchAccumulatedHeroes();
    fetchItemsMechanics();
    fetchAllKol();
  }, []);

  useEffect(() => {
    fetchCirculantPlayers();
  }, [page, sortBy, sortDir]);

  const fetchAllKol = async () => {
    try {
      let data = await getAllKol();
      data?.content.unshift({
        nickname: "Referral KOL",
        referal_code: "",
      });
      setAllKol(data?.content);
      setReferalCode(allKol[0]);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCirculatingSupply = async () => {
    try {
      let data = await getCirculatingSupply(
        from,
        to,
        activeFrom,
        activeTo,
        nickname,
        referalCode?.referal_code ?? "",
        activeOnlyPaying
      );
      setCirculateSupply(data?.content);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchItemsMechanics = async () => {
    try {
      let data = await getItemsMechanicsProformance(
        from,
        to,
        activeFrom,
        activeTo,
        nickname,
        referalCode?.referal_code ?? "",
        activeOnlyPaying
      );
      setMechanics(data?.content);
    } catch (error) {
      console.error(error);
    }
  };

  const formatAmount = (amount) => {
    if (amount >= 1000000) {
      return (amount / 1000000).toFixed(1) + "M";
    } else if (amount >= 1000) {
      return (amount / 1000).toFixed(1) + "K";
    } else {
      return amount.toString();
    }
  };

  const fetchEstimatedWorth = async () => {
    try {
      let data = await getEstimatedWorth(
        from,
        to,
        activeFrom,
        activeTo,
        nickname,
        referalCode?.referal_code ?? "",
        activeOnlyPaying
      );

      const formattedData = {
        diamonds: {},
        estimated_worth: {},
        food: {},
        gold: {},
        pre_soga: {},
        stars: {},
      };

      for (const category in data?.content) {
        formattedData[category].figure = data?.content[category]?.figure?.map(
          (item) => ({
            players_count: item.players_count,
            amount: formatAmount(item.amount),
          })
        );
        formattedData[category].discarded = data?.content[category]?.discarded;
      }
      setEstimatedWorth(formattedData);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAccumulatedHeroes = async () => {
    try {
      let data = await getAccumulatedHeroes(
        from,
        to,
        activeFrom,
        activeTo,
        nickname,
        referalCode?.referal_code ?? "",
        activeOnlyPaying
      );

      let sortedNewPaying = data?.content?.data?.borrowed_heroes.map(
        (item, index) => {
          return {
            borrowed_heroes:
              data?.content?.data["borrowed_heroes"][index].players,
            regular_heroes:
              data?.content?.data["regular_heroes"][index].players,
            fused_heroes: data?.content?.data["fused_heroes"][index].players,
            limited_heroes:
              data?.content?.data["limited_heroes"][index].players,
            name: index,
          };
        }
      );

      setAccumulatedChart(sortedNewPaying);
      setAccumulatedHeroes(data?.content);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCirculantPlayers = async () => {
    try {
      let data = await getCirculantPlayers(
        from,
        to,
        activeFrom,
        activeTo,
        nickname,
        referalCode?.referal_code ?? "",
        page,
        sortBy,
        sortDir,
        activeOnlyPaying
      );
      setCirculantPlayers(data);
    } catch (error) {
      console.error(error);
    }
  };

  const sortStatusTable = (sortVal) => {
    if (sortVal === sortBy) {
      sortDir === "asc" ? setSortDir("desc") : setSortDir("asc");
    } else {
      setSortDir("asc");
      setSortBy(sortVal);
    }
  };

  const handlePageClick = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
  };

  const filter = async () => {
    setLoader(true);
    setPage(1);
    await fetchCirculatingSupply();
    await fetchEstimatedWorth();
    await fetchAccumulatedHeroes();
    await fetchItemsMechanics();
    if (page === 1) {
      await fetchCirculantPlayers();
    }
    setLoader(false);
  };

  const handleDateClick = (val) => {
    if (val === "createdDate") {
      setActiveFrom(from); // Update activeFrom with the calculated date
      setActiveTo(to);
    } else if (val === "empty") {
      setActiveFrom(""); // Update activeFrom with the calculated date
      setActiveTo("");
    } else {
      const today = moment(); // Get the current date using moment
      const sevenDaysAgo = today.subtract(val, "days"); // Subtract 7 days using moment
      // // Format the date in YYYY-MM-DD format
      const formattedDate = sevenDaysAgo.format("YYYY-MM-DD");
      setActiveFrom(formattedDate);
    }
  };

  const roundToOneDecimalPlaces = (number) => {
    if (!Number.isNaN(number) && number !== 0) {
      return parseFloat(number.toFixed(1));
    }
    if (isNaN(number)) {
      return 0;
    }
    return number;
  };
  return (
    <>
      <div className="flex flex-col items-center h-full p-6 gap-6">
        <div className="p-6 rounded-[2px] border bg-white w-full">
          <div className="flex items-center gap-5 mt-2">
            <label>Accounts created between</label>
            <div className="relative w-[120px]">
              <input
                type="text"
                value={from}
                onChange={(e) => setFrom(e.target.value)}
                className="h-[40px] px-3 border w-full outline-0 border-[#E8E7E4] placeholder:text-white text-black"
              />
              <input
                type="date"
                value={from}
                onChange={(e) => setFrom(e.target.value)}
                className="absolute inset-0 opacity-0 z-1"
              />
            </div>
            <label>to</label>
            <div className="relative w-[120px]">
              <input
                type="text"
                value={to}
                onChange={(e) => setTo(e.target.value)}
                className="h-[40px] px-3 border w-full outline-0 border-[#E8E7E4] placeholder:text-white text-black"
              />
              <input
                type="date"
                value={to}
                onChange={(e) => setTo(e.target.value)}
                className="absolute inset-0 opacity-0 z-1"
              />
            </div>
            <div className="w-[160px]">
              <ListBoxSelect
                options={allKol}
                selected={referalCode}
                setSelected={setReferalCode}
                placeholder={"Referral KOL"}
              />
            </div>
            <div className="w-[140px]">
              <input
                type="text"
                value={nickname}
                onChange={(e) => setNickname(e.target.value)}
                placeholder="Nickname"
                className="h-[40px] px-3 border w-full outline-0 border-[#E8E7E4] placeholder:text-black"
              />
            </div>
            {loader ? (
              <button className="h-[40px] px-8 text-[16px] text-white flex items-center justify-center bg-[#0080A6]">
                <BiLoaderCircle
                  size={22}
                  className="animate-[spin_3s_linear_infinite]"
                />
              </button>
            ) : (
              <button
                onClick={filter}
                className="h-[40px] px-8 text-[16px] text-white flex items-center justify-center bg-[#0080A6]"
              >
                Reload
              </button>
            )}
          </div>
          <div className="flex items-center gap-5 my-5">
            <label className="mr-2">Active Accounts between</label>
            <p
              className="text-blue-500 cursor-pointer"
              onClick={() => handleDateClick("empty")}
            >
              Remove
            </p>
            <p
              className="text-blue-500 cursor-pointer"
              onClick={() => handleDateClick(7)}
            >
              Last 7 days
            </p>
            <p
              className="text-blue-500 cursor-pointer"
              onClick={() => handleDateClick(30)}
            >
              Last 30 days
            </p>
            <p
              className="text-blue-500 cursor-pointer"
              onClick={() => handleDateClick(90)}
            >
              Last 90 days
            </p>
            <p
              className="text-blue-500 cursor-pointer"
              onClick={() => handleDateClick("createdDate")}
            >
              Same period than created accounts
            </p>
          </div>
          <div className="flex items-center gap-5 mt-2 ml-52">
            <div className="relative w-[120px]">
              <input
                type="text"
                value={activeFrom}
                onChange={(e) => setActiveFrom(e.target.value)}
                className="h-[40px] px-3 border w-full outline-0 border-[#E8E7E4] placeholder:text-white text-black"
              />
              <input
                type="date"
                value={activeFrom}
                onChange={(e) => setActiveFrom(e.target.value)}
                className="absolute inset-0 opacity-0 z-1"
              />
            </div>
            <label>to</label>
            <div className="relative w-[120px]">
              <input
                type="text"
                value={activeTo}
                onChange={(e) => setActiveTo(e.target.value)}
                className="h-[40px] px-3 border w-full outline-0 border-[#E8E7E4] placeholder:text-white text-black"
              />
              <input
                type="date"
                value={activeTo}
                onChange={(e) => setActiveTo(e.target.value)}
                className="absolute inset-0 opacity-0 z-1"
              />
            </div>
            <div className="h-[40px] px-3 border border-[#E8E7E4] flex items-center">
              <label
                htmlFor="active"
                className="cursor-pointer flex items-center text-[15px] font-normal"
              >
                <input
                  id="active"
                  type="checkbox"
                  className="hidden peer"
                  value={activeOnlyPaying}
                  checked={activeOnlyPaying}
                  onChange={() => setActiveOnlyPaying(!activeOnlyPaying)}
                />
                <span className="mr-3 peer-checked:bg-[#0080A6] peer-checked:border-[#0080A6] bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5.55px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border-[1.5px] border-[#ccc] rounded-sm"></span>
                Only paying accounts
              </label>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-6 w-full">
          <div className="p-6 rounded-[2px] border bg-white w-full relative">
            <h4 className="uppercase text-[16px] font-normal text-center mb-5">
              Accumulated heroes all the time
            </h4>
            <div className="flex justify-end absolute right-6">
              <div className="text-left">
                <h5 className="font-medium text-[#e8aa39]">
                  {accumulatedHeroes?.summary?.borrowed_heros} Borrowed (
                  {roundToOneDecimalPlaces(
                    parseFloat(
                      accumulatedHeroes?.summary?.borrowed_avg_per_player
                    )
                  )}
                  /player)
                </h5>
                <h5 className="font-medium text-[#4b4a50]">
                  {accumulatedHeroes?.summary?.reguar_heros} Regular (
                  {roundToOneDecimalPlaces(
                    parseFloat(
                      accumulatedHeroes?.summary?.regular_avg_per_player
                    )
                  )}
                  /player)
                </h5>
                <h5 className="font-medium text-[#357e37]">
                  {accumulatedHeroes?.summary?.fused_heros} Fused (
                  {roundToOneDecimalPlaces(
                    parseFloat(accumulatedHeroes?.summary?.fused_avg_per_player)
                  )}
                  /player)
                </h5>
                <h5 className="font-medium text-[#4c7d8c]">
                  {accumulatedHeroes?.summary?.limited_heros} Limited (
                  {roundToOneDecimalPlaces(
                    parseFloat(
                      accumulatedHeroes?.summary?.limited_avg_per_player
                    )
                  )}
                  /player)
                </h5>
                <h5 className="font-medium text-[#373739]">
                  {accumulatedHeroes?.summary?.total_heros} total (
                  {roundToOneDecimalPlaces(
                    parseFloat(accumulatedHeroes?.summary?.total_avg_per_player)
                  )}
                  /player)
                </h5>
              </div>
            </div>
            <Chart accumulatedChart={accumulatedChart} />
          </div>
          <div className="p-6 rounded-[2px] border bg-white w-full">
            <h4 className="uppercase text-[16px] font-normal text-center mb-5">
              Items mechanics performance all the time
            </h4>
            {mechanics && (
              <>
                <div className="flex items-center mb-2">
                  <div className="w-[42%] pr-2">
                    <h4 className="truncate text-[14px]">
                      Supply of Loot boxes
                    </h4>
                  </div>
                  <div className="w-[58%]">
                    <div className="relative h-7 bg-gray-200 flex items-center justify-between px-3">
                      <div
                        className="absolute left-0 h-full bg-yellow-400"
                        style={{
                          width: `${mechanics.supply_of_lootboxes.percentage}`,
                        }}
                      ></div>
                      <h5 className="z-10 text-[14px] font-normal truncate">
                        {mechanics.supply_of_lootboxes.percentage}% revealed
                      </h5>
                      <h5 className="z-10 text-[14px] font-normal truncate">
                        {mechanics.supply_of_lootboxes.total_items} items
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="flex items-center mb-2">
                  <div className="w-[42%] pr-2">
                    <h4 className="truncate text-[14px]">
                      Supply of Cylinders
                    </h4>
                  </div>
                  <div className="w-[58%]">
                    <div className="relative h-7 bg-gray-200 flex items-center justify-between px-3">
                      <div
                        className="absolute left-0 h-full bg-yellow-400"
                        style={{
                          width: `${mechanics.supply_of_cylinders.percentage}`,
                        }}
                      ></div>
                      <h5 className="z-10 text-[14px] font-normal truncate">
                        {mechanics.supply_of_cylinders.percentage}% assembled
                      </h5>
                      <h5 className="z-10 text-[14px] font-normal truncate">
                        {mechanics.supply_of_cylinders.total_items} items
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="flex items-center mb-2">
                  <div className="w-[42%] pr-2">
                    <h4 className="truncate text-[14px]">Supply of Crafters</h4>
                  </div>
                  <div className="w-[58%]">
                    <div className="relative h-7 bg-gray-200 flex items-center justify-between px-3">
                      <div
                        className="absolute left-0 h-full bg-yellow-400"
                        style={{
                          width: `${mechanics.supply_of_crafters.percentage}`,
                        }}
                      ></div>
                      <h5 className="z-10 text-[14px] font-normal truncate">
                        {mechanics.supply_of_crafters.percentage}% decoded
                      </h5>
                      <h5 className="z-10 text-[14px] font-normal truncate">
                        {mechanics.supply_of_crafters.total_items} items
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="flex items-center mb-2">
                  <div className="w-[42%] pr-2">
                    <h4 className="truncate text-[14px]">
                      Players w Gamer station
                    </h4>
                  </div>
                  <div className="w-[58%]">
                    <div className="relative h-7 bg-gray-200 flex items-center justify-between px-3">
                      <div
                        className="absolute left-0 h-full bg-yellow-400"
                        style={{
                          width: `${mechanics.players_with_gamer_station?.percentage}%`,
                        }}
                      ></div>
                      <h5 className="z-10 text-[14px] font-normal truncate">
                        {mechanics.players_with_gamer_station?.percentage}% own
                      </h5>
                      <h5 className="z-10 text-[14px] font-normal truncate">
                        {mechanics.total_players} players
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="flex items-center mb-2">
                  <div className="w-[42%] pr-2">
                    <h4 className="truncate text-[14px]">
                      Players w Capsule Bases
                    </h4>
                  </div>
                  <div className="w-[58%]">
                    <div className="relative h-7 bg-gray-200 flex items-center justify-between px-3">
                      <div
                        className="absolute left-0 h-full bg-yellow-400"
                        style={{
                          width: `${mechanics.players_with_capsule_base?.percentage}%`,
                        }}
                      ></div>
                      <h5 className="z-10 text-[14px] font-normal truncate">
                        {mechanics.players_with_capsule_base?.percentage}% min.
                        1u.
                      </h5>
                      <h5 className="z-10 text-[14px] font-normal truncate">
                        {mechanics.total_players} players
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="flex items-center mb-2">
                  <div className="w-[42%] pr-2">
                    <h4 className="truncate text-[14px]">
                      Players w Max Power Gen.
                    </h4>
                  </div>
                  <div className="w-[58%]">
                    <div className="relative h-7 bg-gray-200 flex items-center justify-between px-3">
                      <div
                        className="absolute left-0 h-full bg-yellow-400"
                        style={{
                          width: `${mechanics.players_with_max_power_gen?.percentage}%`,
                        }}
                      ></div>
                      <h5 className="z-10 text-[14px] font-normal truncate">
                        {mechanics.players_with_max_power_gen.players}% max
                        level
                      </h5>
                      <h5 className="z-10 text-[14px] font-normal truncate">
                        {mechanics.total_players} players
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="flex items-center mb-2">
                  <div className="w-[42%] pr-2">
                    <h4 className="truncate text-[14px]">
                      Players w Max Lucky Foun.
                    </h4>
                  </div>
                  <div className="w-[58%]">
                    <div className="relative h-7 bg-gray-200 flex items-center justify-between px-3">
                      <div
                        className="absolute left-0 h-full bg-yellow-400"
                        style={{
                          width: `${mechanics.players_with_max_fountain?.percentage}%`,
                        }}
                      ></div>
                      <h5 className="z-10 text-[14px] font-normal truncate">
                        {mechanics.players_with_max_fountain.players}% max level
                      </h5>
                      <h5 className="z-10 text-[14px] font-normal truncate">
                        {mechanics.total_players} players
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="flex items-center mb-2">
                  <div className="w-[42%] pr-2">
                    <h4 className="truncate text-[14px]">
                      Players w Max Gym SPA.
                    </h4>
                  </div>
                  <div className="w-[58%]">
                    <div className="relative h-7 bg-gray-200 flex items-center justify-between px-3">
                      <div
                        className="absolute left-0 h-full bg-yellow-400"
                        style={{
                          width: `${mechanics.players_with_max_gym_spa?.percentage}%`,
                        }}
                      ></div>
                      <h5 className="z-10 text-[14px] font-normal truncate">
                        {mechanics.players_with_max_gym_spa.players}% max level
                      </h5>
                      <h5 className="z-10 text-[14px] font-normal truncate">
                        {mechanics.total_players} players
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="flex items-center mb-2">
                  <div className="w-[42%] pr-2">
                    <h4 className="truncate text-[14px]">
                      Equipment (crafter dec){" "}
                    </h4>
                  </div>
                  <div className="w-[58%]">
                    <div className="relative h-7 bg-gray-200 flex items-center justify-between">
                      <h5
                        className="z-10 text-[14px] h-full leading-[28px] px-1 font-normal truncate bg-[#b5b5b5]"
                        style={{ width: `25%` }}
                      >
                        {formatAmount(mechanics.equipments.rarity1.amount)}
                      </h5>
                      <h5
                        className="z-10 text-[14px] h-full leading-[28px] px-1 font-normal truncate bg-[#90d0eb]"
                        style={{ width: `20%` }}
                      >
                        {formatAmount(mechanics.equipments.rarity2.amount)}
                      </h5>
                      <h5
                        className="z-10 text-[14px] h-full leading-[28px] px-1 font-normal truncate bg-[#a9f0ac]"
                        style={{ width: `15%` }}
                      >
                        {formatAmount(mechanics.equipments.rarity3.amount)}
                      </h5>
                      <h5
                        className="z-10 text-[14px] h-full leading-[28px] px-1 font-normal truncate bg-[#e0b45b]"
                        style={{ width: `12%` }}
                      >
                        {formatAmount(mechanics.equipments.rarity4.amount)}
                      </h5>
                      <h5
                        className="z-10 text-[14px] h-full leading-[28px] px-1 font-normal truncate bg-[#e179d8]"
                        style={{ width: `16%` }}
                      >
                        {formatAmount(mechanics.equipments.rarity5.amount)}
                      </h5>
                      <h5
                        className="z-10 text-[14px] h-full leading-[28px] px-1 font-normal truncate bg-[#95abe5]"
                        style={{ width: `12%` }}
                      >
                        {formatAmount(mechanics.equipments.rarity6.amount)}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="flex items-center mb-2">
                  <div className="w-[42%] pr-2">
                    <h4 className="truncate text-[14px]">
                      Items in the marketplace
                    </h4>
                  </div>
                  <div className="w-[58%]">
                    <div className="relative h-7 bg-gray-200 flex items-center justify-between">
                      <h5
                        className="z-10 text-[14px] h-full leading-[28px] px-1 font-normal truncate bg-[#a9f0ac]"
                        style={{ width: `80%` }}
                      >
                        {mechanics.items_in_marketplace.capsules} Cap
                      </h5>
                      <h5
                        className="z-10 text-[14px] h-full leading-[28px] px-1 font-normal truncate bg-[#90d0eb]"
                        style={{ width: `20%` }}
                      >
                        {mechanics.items_in_marketplace.gamer_stations} GS
                      </h5>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="p-6 rounded-[2px] border bg-white w-full">
            <h4 className="uppercase text-[16px] font-normal text-center mb-5">
              Circulating supply of active players
            </h4>
            <CirculatingTable circulateSupply={circulateSupply} />
          </div>
        </div>
        <div className="p-6 rounded-[2px] border bg-white w-full">
          <div className="grid xl:grid-cols-6 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 w-full">
            <MiniChart estimatedWorth={estimatedWorth} />
          </div>
        </div>
        <CirculateStatusTable
          sortStatusTable={sortStatusTable}
          circulantPlayers={circulantPlayers?.content}
          handlePageClick={handlePageClick}
          pagination={circulantPlayers?.pagination}
          loader={loader}
          sortBy={sortBy}
          sortDir={sortDir}
        />
      </div>
    </>
  );
}

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BiLoaderCircle } from "react-icons/bi";

export default function Login() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/dashboard");
  }, []);
  return (
    <div className="h-screen flex items-center justify-center">
      <BiLoaderCircle
        size={100}
        className="animate-[spin_3s_linear_infinite] text-[#419aa5]"
      />
    </div>
  );
}

import SalesFunnelTable from "../../components/SalesFunnelTable";
import SalesRoiChart from "../../components/SalesRoiChart";
import AccountsTable from "../../components/AccountsTable";

import { useEffect, useState } from "react";
import { getAccountsTable, getSalesFunnel, getSalesROI } from "../../api";
import moment from "moment";
import { BiLoaderCircle } from "react-icons/bi";

export default function Overview() {
  const [accountsTable, setAccountsTable] = useState([]);
  const [from, setFrom] = useState(
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );
  const [salesRoi, setSalesRoi] = useState([]);
  const [to, setTo] = useState(moment().format("YYYY-MM-DD"));
  const [salesFunnel, setSalesFunnel] = useState([]);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    fetchAccountsTable();
    fetchSalesFunnel();
    fetchSalesROI();
  }, []);

  const fetchAccountsTable = async () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    try {
      setLoader(true);
      let data = await getAccountsTable(
        from,
        to,
        false,
        userData.my_invitation_code
      );
      data = data.map((obj) => ({ ...obj, active: false }));
      setAccountsTable(data);
      setLoader(false);
    } catch (error) {
      console.error(error);
    }
  };

  const updateActiveUser = (accountId) => {
    const updatedAccounts = accountsTable.map((account) => {
      if (account === accountId) {
        return { ...account, active: true };
      }
      return { ...account, active: false };
    });

    setAccountsTable(updatedAccounts);
  };

  const fetchSalesFunnel = async () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const invitationCode = userData.my_invitation_code;
    try {
      let data = await getSalesFunnel(from, to, invitationCode);
      setSalesFunnel(data);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchSalesROI = async (code) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const invitationCode = userData.my_invitation_code;
    try {
      let data = await getSalesROI(from, to, invitationCode);
      setSalesRoi(data);
    } catch (error) {
      console.error(error);
    }
  };

  const filter = async () => {
    setLoader(true);
    await fetchSalesFunnel();
    await fetchSalesROI();
    await fetchAccountsTable();
    setLoader(false);
  };
  return (
    <div className="flex flex-col items-center h-full p-6">
      <div className="p-6 rounded-[2px] border bg-white w-full">
        <p className="text-[16px] font-medium text-black">
          Select the date and reload the dashboard
        </p>
        <p className="text-[16px] font-medium text-black">
          [Current month]{" "}
          <span className="text-[#5468DB]">
            [Same period int the last month] [last 30 days] [last 60 days] [last
            90 days]
          </span>
        </p>
        <div className="flex items-center gap-5 mt-2">
          <label>From date</label>
          <div className="relative">
            <input
              type="text"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              className="h-[40px] px-3 border border-[#E8E7E4] placeholder:text-white"
            />
            <input
              type="date"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              className="absolute inset-0 opacity-0 z-1"
            />
          </div>
          <label>to date</label>
          <div className="relative">
            <input
              type="text"
              value={to}
              onChange={(e) => setTo(e.target.value)}
              className="h-[40px] px-3 border border-[#E8E7E4] placeholder:text-white"
            />
            <input
              type="date"
              value={to}
              onChange={(e) => setTo(e.target.value)}
              className="absolute inset-0 opacity-0 z-1"
            />
          </div>
          <button
            onClick={filter}
            className="h-[40px] px-8 text-[16px] text-white flex items-center justify-center bg-[#0080A6]"
          >
            {loader ? (
              <BiLoaderCircle
                size={22}
                className="animate-[spin_3s_linear_infinite]"
              />
            ) : (
              "Reload"
            )}
          </button>
        </div>
      </div>
      <AccountsTable
        accountsTable={accountsTable}
        fetchSalesFunnel={fetchSalesFunnel}
        updateActiveUser={updateActiveUser}
        fetchSalesROI={fetchSalesROI}
        type={true}
        loader={loader}
      />
      <SalesFunnelTable salesFunnel={salesFunnel} />
      <SalesRoiChart salesRoi={salesRoi} />
    </div>
  );
}

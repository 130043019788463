import Chart from "./Chart";
import IconGold from "assets/images/icon_gold.png";
import IconFood from "assets/images/icon_food.png";
import IconDiamond from "assets/images/icon_diamond.png";
import IconSilverStart from "assets/images/icon_silverstar.png";
import IconSoga from "assets/images/icon_SOGA.png";

const minichart = [
  {
    url: IconGold,
    title: "Gold",
    fillColor: "#fff3ad",
    strokeColor: "#e0d45c",
    key: "gold",
  },
  {
    url: IconFood,
    title: "Food",
    fillColor: "#ffcead",
    strokeColor: "#e18e30",
    key: "food",
  },
  {
    url: IconDiamond,
    title: "Diamonds",
    fillColor: "#ffacb4",
    strokeColor: "#bf1321",
    key: "diamonds",
  },
  {
    url: IconSilverStart,
    title: "Silver Stars",
    fillColor: "#d2dada",
    strokeColor: "#474d4b",
    key: "stars",
  },
  {
    url: IconSoga,
    title: "Pre Soga",
    fillColor: "#aee6e3",
    strokeColor: "#096b6a",
    key: "pre_soga",
  },
  {
    url: "",
    title: "Estimated Worth",
    fillColor: "#b8cde8",
    strokeColor: "#0c2659",
    key: "estimated_worth",
  },
];
export default function MiniChart({ estimatedWorth }) {
  return (
    <>
      {minichart.map((item, key) => (
        <div key={key}>
          <div className="flex items-center justify-center gap-2 mb-4">
            {item.url && <img src={item.url} alt={item.title} width={25} />}
            <p>{item.title}</p>
          </div>
          <Chart
            fillColor={item.fillColor}
            strokeColor={item.strokeColor}
            estimatedWorth={estimatedWorth}
            itemKey={item.key}
            itemTitle={item.title}
          />
        </div>
      ))}
    </>
  );
}

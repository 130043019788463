import React, { useEffect } from "react";

const SalesTable = ({ salesFunnel }) => {
  const findPercentage = (value) => {
    const number = Math.round((value * 100) / salesFunnel?.sales?.new_accounts);
    if (isNaN(number)) {
      return 0;
    } else {
      return Math.round((value * 100) / salesFunnel?.sales?.new_accounts);
    }
  };
  const roundToTwoDecimalPlaces = (input) => {
    const number = parseFloat(input);

    if (typeof number === "number" && !Number.isNaN(number) && number !== 0) {
      const roundedNumber = parseFloat(number.toFixed(2));
      return roundedNumber.toLocaleString();
    }

    if (isNaN(number)) {
      return 0;
    }

    return input;
  };
  return (
    <div className="w-full mt-6 pb-6">
      <div className="bg-white w-full overflow-x-auto">
        <table className="w-full min-w-[800px]">
          <thead>
            <tr>
              <td className="uppercase p-[8px_15px_8px_30px] text-center font-medium text-[16px] whitespace-nowrap"></td>
              <td className="uppercase p-[8px_15px] text-center font-medium text-[16px] whitespace-nowrap">
                Sales Funnel
              </td>
              <td className="uppercase p-[8px_15px] text-center font-medium text-[16px] whitespace-nowrap">
                KPIS
              </td>
              <td className="uppercase p-[8px_15px] text-center font-medium text-[16px] whitespace-nowrap"></td>
              <td className="uppercase p-[8px_15px] text-center font-medium text-[16px] whitespace-nowrap">
                Goal 1
              </td>
              <td className="uppercase p-[8px_15px] text-center font-medium text-[16px] whitespace-nowrap">
                Goal 2
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                New accounts
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap w-[40%]">
                <div className="flex justify-center items-center gap-3">
                  <p className="text-[16px]">
                    {salesFunnel?.sales?.new_accounts} users
                  </p>
                  <div
                    className="bg-[#FFBB44] h-[20px]"
                    style={{
                      width:
                        findPercentage(salesFunnel?.sales?.new_accounts) + "%",
                    }}
                  ></div>
                  <p className="text-[16px]">
                    {findPercentage(salesFunnel?.sales?.new_accounts)}%
                  </p>
                </div>
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                CAC1 - New account
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                {salesFunnel?.kpis?.cac1_new_account > 0
                  ? "$" +
                    roundToTwoDecimalPlaces(salesFunnel?.kpis?.cac1_new_account)
                  : "-"}
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                -
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                -
              </td>
            </tr>
            <tr>
              <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                Active accounts
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap w-[40%]">
                <div className="flex justify-center items-center gap-3">
                  <p className="text-[16px]">
                    {salesFunnel?.sales?.active_accounts} users
                  </p>
                  <div
                    className="bg-[#FFBB44] h-[20px]"
                    style={{
                      width:
                        salesFunnel?.sales?.active_accounts_percentage + "%",
                    }}
                  ></div>
                  <p className="text-[16px]">
                    {salesFunnel?.sales?.active_accounts_percentage}%
                  </p>
                </div>
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                CAC2 - Playing accounts
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                {salesFunnel?.kpis?.cac2_active_account > 0
                  ? "$" + salesFunnel?.kpis?.cac2_active_account
                  : "-"}
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                -
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                -
              </td>
            </tr>
            <tr>
              <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                Minimum 0 challenges
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap w-[40%]">
                <div className="flex justify-center items-center gap-3">
                  <p className="text-[16px]">
                    {salesFunnel?.sales && salesFunnel?.sales["0_challenges"]}{" "}
                    users
                  </p>
                  <div
                    className="bg-[#FFBB44] h-[20px]"
                    style={{
                      width:
                        salesFunnel?.sales &&
                        salesFunnel?.sales["0_challenges_percentage"] + "%",
                    }}
                  ></div>
                  <p className="text-[16px]">
                    {salesFunnel?.sales &&
                      salesFunnel?.sales["0_challenges_percentage"]}
                    %
                  </p>
                </div>
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                ARPPU
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                {salesFunnel?.kpis?.arppu > 0
                  ? "$" + salesFunnel?.kpis?.arppu
                  : "-"}
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                -
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                -
              </td>
            </tr>
            <tr>
              <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                Minimum 1 challenges
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap w-[40%]">
                <div className="flex justify-center items-center gap-3">
                  <p className="text-[16px]">
                    {salesFunnel?.sales?.min_1_challenges} users
                  </p>
                  <div
                    className="bg-[#FFBB44] h-[20px]"
                    style={{
                      width:
                        salesFunnel?.sales?.min_1_challenges_percentage + "%",
                    }}
                  ></div>
                  <p className="text-[16px]">
                    {salesFunnel?.sales?.min_1_challenges_percentage}%
                  </p>
                </div>
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                ARPU
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                {salesFunnel?.kpis?.arpu > 0
                  ? "$" + salesFunnel?.kpis?.arpu
                  : "-"}
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                -
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                -
              </td>
            </tr>
            <tr>
              <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                Minimum 10 challenges
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap w-[40%]">
                <div className="flex justify-center items-center gap-3">
                  <p className="text-[16px]">
                    {salesFunnel?.sales?.min_10_challenges} users
                  </p>
                  <div
                    className="bg-[#FFBB44] h-[20px]"
                    style={{
                      width:
                        salesFunnel?.sales?.min_10_challenges_percentage + "%",
                    }}
                  ></div>
                  <p className="text-[16px]">
                    {salesFunnel?.sales?.min_10_challenges_percentage}%
                  </p>
                </div>
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                All the time investment
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                {salesFunnel?.kpis?.total_cost > 0
                  ? "$" + roundToTwoDecimalPlaces(salesFunnel?.kpis?.total_cost)
                  : "-"}
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                -
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                -
              </td>
            </tr>
            <tr>
              <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                Minimum 25 challenges
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap w-[40%]">
                <div className="flex justify-center items-center gap-3">
                  <p className="text-[16px]">
                    {salesFunnel?.sales?.min_25_challenges} users
                  </p>
                  <div
                    className="bg-[#FFBB44] h-[20px]"
                    style={{
                      width:
                        salesFunnel?.sales?.min_25_challenges_percentage + "%",
                    }}
                  ></div>
                  <p className="text-[16px]">
                    {salesFunnel?.sales?.min_25_challenges_percentage}%
                  </p>
                </div>
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                All the time incomes
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                {salesFunnel?.kpis?.total_income > 0
                  ? "$" +
                    roundToTwoDecimalPlaces(salesFunnel?.kpis?.total_income)
                  : "-"}
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                -
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                -
              </td>
            </tr>
            <tr>
              <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                Minimum 50 challenges
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap w-[40%]">
                <div className="flex justify-center items-center gap-3">
                  <p className="text-[16px]">
                    {salesFunnel?.sales?.min_50_challenges} users
                  </p>
                  <div
                    className="bg-[#FFBB44] h-[20px]"
                    style={{
                      width:
                        salesFunnel?.sales?.min_50_challenges_percentage + "%",
                    }}
                  ></div>
                  <p className="text-[16px]">
                    {salesFunnel?.sales?.min_50_challenges_percentage}%
                  </p>
                </div>
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                All the time ROI
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                {salesFunnel?.kpis?.roi > 0 ? salesFunnel?.kpis?.roi : "-"}
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                -
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                -
              </td>
            </tr>
            <tr>
              <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                Borrow Hero
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap w-[40%]">
                <div className="flex justify-center items-center gap-3">
                  <p className="text-[16px]">
                    {salesFunnel?.sales?.borrow_hero} users
                  </p>
                  <div
                    className="bg-[#FFBB44] h-[20px]"
                    style={{
                      width: salesFunnel?.sales?.borrow_hero_percentage + "%",
                    }}
                  ></div>
                  <p className="text-[16px]">
                    {salesFunnel?.sales?.borrow_hero_percentage}%
                  </p>
                </div>
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                CAC3 - Playing accounts
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                {salesFunnel?.kpis?.cac3_paying_account > 0
                  ? "$" +
                    roundToTwoDecimalPlaces(
                      salesFunnel?.kpis?.cac3_paying_account
                    )
                  : "-"}
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                -
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                -
              </td>
            </tr>
            <tr>
              <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                Minimum 1 purchase with ingame coin
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap w-[40%]">
                <div className="flex justify-center items-center gap-3">
                  <p className="text-[16px]">
                    {salesFunnel?.sales?.min_1_purchase_with_ingame_coin} users
                  </p>
                  <div
                    className="bg-[#FFBB44] h-[20px]"
                    style={{
                      width:
                        salesFunnel?.sales
                          ?.min_1_purchase_with_ingame_coin_percentage + "%",
                    }}
                  ></div>
                  <p className="text-[16px]">
                    {
                      salesFunnel?.sales
                        ?.min_1_purchase_with_ingame_coin_percentage
                    }
                    %
                  </p>
                </div>
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                Retention 30 days
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                {salesFunnel?.kpis?.retension_30_days > 0
                  ? salesFunnel?.kpis?.retension_30_days + "%"
                  : "-"}
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                -
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                -
              </td>
            </tr>
            <tr>
              <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                Minimum 1 purchase with crypto
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap w-[40%]">
                <div className="flex justify-center items-center gap-3">
                  <p className="text-[16px]">
                    {salesFunnel?.sales?.min_1_purchase_with_crypto} users
                  </p>
                  <div
                    className="bg-[#FFBB44] h-[20px]"
                    style={{
                      width:
                        salesFunnel?.sales
                          ?.min_1_purchase_with_crypto_percentage + "%",
                    }}
                  ></div>
                  <p className="text-[16px]">
                    {salesFunnel?.sales?.min_1_purchase_with_crypto_percentage}%
                  </p>
                </div>
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                Retention 60 days
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                {salesFunnel?.kpis?.retension_60_days > 0
                  ? salesFunnel?.kpis?.retension_60_days + "%"
                  : "-"}
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                -
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                -
              </td>
            </tr>
            <tr>
              <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                Purchased min 1 Hero
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap w-[40%]">
                <div className="flex justify-center items-center gap-3">
                  <p className="text-[16px]">
                    {salesFunnel?.sales?.puchased_min_1_hero} users
                  </p>
                  <div
                    className="bg-[#FFBB44] h-[20px]"
                    style={{
                      width:
                        salesFunnel?.sales?.puchased_min_1_hero_percentage +
                        "%",
                    }}
                  ></div>
                  <p className="text-[16px]">
                    {salesFunnel?.sales?.puchased_min_1_hero_percentage}%
                  </p>
                </div>
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                Retention 90 days
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                {salesFunnel?.kpis?.retension_90_days > 0
                  ? salesFunnel?.kpis?.retension_90_days + "%"
                  : "-"}
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                -
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                -
              </td>
            </tr>
            <tr>
              <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                Purchased min 2 Heroes
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap w-[40%]">
                <div className="flex justify-center items-center gap-3">
                  <p className="text-[16px]">
                    {salesFunnel?.sales?.puchased_min_2_heroes} users
                  </p>
                  <div
                    className="bg-[#FFBB44] h-[20px]"
                    style={{
                      width:
                        salesFunnel?.sales?.puchased_min_2_heroes_percentage +
                        "%",
                    }}
                  ></div>
                  <p className="text-[16px]">
                    {salesFunnel?.sales?.puchased_min_2_heroes_percentage}%
                  </p>
                </div>
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                Customer Lifecycle
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                {salesFunnel?.kpis?.customer_lifecycle > 0
                  ? salesFunnel?.kpis?.customer_lifecycle + "%"
                  : "-"}
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                -
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                -
              </td>
            </tr>
            <tr>
              <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                Purchased min 3 Heroes
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap w-[40%]">
                <div className="flex justify-center items-center gap-3">
                  <p className="text-[16px]">
                    {salesFunnel?.sales?.puchased_min_3_heroes} users
                  </p>
                  <div
                    className="bg-[#FFBB44] h-[20px]"
                    style={{
                      width:
                        salesFunnel?.sales?.puchased_min_3_heroes_percentage +
                        "%",
                    }}
                  ></div>
                  <p className="text-[16px]">
                    {salesFunnel?.sales?.puchased_min_3_heroes_percentage}%
                  </p>
                </div>
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                Customer Lifecycle Sales
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                {salesFunnel?.kpis?.customer_lifetime_sales > 0
                  ? salesFunnel?.kpis?.customer_lifetime_sales + "%"
                  : "-"}
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                -
              </td>
              <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                -
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SalesTable;

import { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { FiMenu } from "react-icons/fi";
import { AiOutlineLogout } from "react-icons/ai";
import { MdAccountCircle } from "react-icons/md";
export default function Header({ mobileToggler, toggleSidebar }) {
  const localStorageUSerObj = JSON.parse(localStorage.getItem('userData'));
  
  const logoutUser = async () => {
    localStorage.clear();
    window.location.href = "https://www.socialgames.com/";
  }
  
  return (
    <div className="flex items-center justify-between px-6 h-[50px] border-b border-[#e7eaee] bg-white">
      <div>
        <div onClick={toggleSidebar} className="cursor-pointer md:block hidden">
          <FiMenu size={22} className="fill-[#8390a0]" />
        </div>
        <div onClick={mobileToggler} className="cursor-pointer md:hidden block">
          <FiMenu size={22} className="fill-[#8390a0]" />
        </div>
      </div>
      <div>
        <Menu as="div" className="relative">
          <Menu.Button
            as="div"
            className="p-0 bg-transparent !border-none cursor-pointer"
          >
            <div className="w-[28px] h-[28px] rounded-full flex items-center justify-center bg-blue-500 text-[12px] font-semibold relative text-white">
              {localStorageUSerObj?.nickname.charAt(0).toUpperCase()}
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 bg-white rounded-[8px] mt-2 w-[170px] z-[999] origin-bottom-left min-w-[260px] shadow-[0_4px_8px_rgba(0,0,0,.15)] border border-[#e7eaee]">
              <Menu.Item as="div" className="flex">
                <NavLink
                  onClick={logoutUser}
                  className={({ isActive, isPending }) =>
                    `${
                      isPending ? "pending" : isActive ? "actives" : ""
                    } px-4 py-2`
                  }
                >
                  <p className="text-[14px] text-heading flex items-center gap-2">
                    <AiOutlineLogout size={20} className="text-[#8390A0]" />
                    Sign out
                  </p>
                </NavLink>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}

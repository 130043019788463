import React, { useState, useEffect } from "react";
import Income from "./Income";
import Product from "./Product";

export default function Sales({ salesIncome, salesProduct,fetchSalesIncome }) {
  const [income, setIncome] = useState(true);
  const [activeMatics,setActiveMatics] = useState(true);
  const [activeSogas,setActiveSogas] = useState(true);

  useEffect(() => {
    fetchSalesIncome(activeMatics,activeSogas); // Fetch sales income when activeMatics or activeSogas changes
  }, [activeMatics, activeSogas]);

  const formatPrice = (price) => {
    if (typeof price === "string") {
      price = parseFloat(price);
    }
    if (!isNaN(price)) {
      return price.toLocaleString();
    }
    return price;
  };
  return (
    <div className="p-6 bg-white w-full mt-6">
      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-9 border-r pr-5 pb-4">
          <div className="flex">
            <h4 className="text-[20px] text-black font-semibold">
              Sales{" "}
              <span
                className={`${
                  !income && "text-[#5468DB]"
                } inline-block cursor-pointer`}
                onClick={() => setIncome(true)}
              >
                Income
              </span>{" "}
              <span
                className={`${
                  income && "text-[#5468DB]"
                } inline-block cursor-pointer`}
                onClick={() => setIncome(false)}
              >
                Amount 
              </span>
              , Including transaction with
            </h4>
            <div className="ps-3 flex items-center">
              <label
                htmlFor="matics"
                className="cursor-pointer flex items-center text-[15px] font-normal"
              >
                <input
                  id="matics"
                  type="checkbox"
                  className="hidden peer"
                  value={activeMatics}
                  checked={activeMatics}
                  onChange={() => setActiveMatics(!activeMatics)}
                />
                <span className="mr-2 peer-checked:bg-[#0080A6] peer-checked:border-[#0080A6] bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5.55px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border-[1.5px] border-[#ccc] rounded-sm"></span>
                Matics
              </label>
            </div>
            <div className="ps-3 flex items-center">
              <label
                htmlFor="sogas"
                className="cursor-pointer flex items-center text-[15px] font-normal"
              >
                <input
                  id="sogas"
                  type="checkbox"
                  className="hidden peer"
                  value={activeSogas}
                  checked={activeSogas}
                  onChange={() => setActiveSogas(!activeSogas)}
                />
                <span className="mr-2 peer-checked:bg-[#0080A6] peer-checked:border-[#0080A6] bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5.55px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border-[1.5px] border-[#ccc] rounded-sm"></span>
                Sogas
              </label>
            </div>
          </div>      
        </div>
        <div className="col-span-3 pb-4">
          <h4 className="text-[20px] text-black font-semibold">
            Sales Ranking by product
          </h4>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-9 border-r pr-5 flex flex-col">
          <Income salesIncome={salesIncome} income={income} />
        </div>
        <div className="col-span-3">
          <div className="grid grid-cols-8 gap-2 w-full">
            <div className="col-span-4">
              <div className="flex items-center gap-2">
                <div className="w-[10px] h-[10px] bg-[#5468DB]"></div>
                <h6 className="text-[15px] text-black ">Heroes</h6>
              </div>
            </div>
            <div className="col-span-2 text-right">
              <p>{formatPrice(salesProduct[0]?.amount) || 0}u</p>
            </div>
            <div className="col-span-2 text-right">
              <p>${formatPrice(salesProduct[0]?.income) || 0}</p>
            </div>
          </div>
          <div className="grid grid-cols-8 gap-2 w-full">
            <div className="col-span-4">
              <div className="flex items-center gap-2">
                <div className="w-[10px] h-[10px] bg-[#4BC18F]"></div>
                <h6 className="text-[15px] text-black ">VPLs</h6>
              </div>
            </div>
            <div className="col-span-2 text-right">
              <p>{formatPrice(salesProduct[1]?.amount) || 0}u</p>
            </div>
            <div className="col-span-2 text-right">
              <p>${formatPrice(salesProduct[1]?.income) || 0}</p>
            </div>
          </div>
          <div className="grid grid-cols-8 gap-2 w-full">
            <div className="col-span-4">
              <div className="flex items-center gap-2">
                <div className="w-[10px] h-[10px] bg-[#FFBB44]"></div>
                <h6 className="text-[15px] text-black ">Supermarket</h6>
              </div>
            </div>
            <div className="col-span-2 text-right">
              <p>{formatPrice(salesProduct[2]?.amount) || 0}u</p>
            </div>
            <div className="col-span-2 text-right">
              <p>${formatPrice(salesProduct[2]?.income) || 0}</p>
            </div>
          </div>
          <div className="grid grid-cols-8 gap-2 w-full">
            <div className="col-span-4">
              <div className="flex items-center gap-2">
                <div className="w-[10px] h-[10px] bg-[#ff7400]"></div>
                <h6 className="text-[15px] text-black ">Marketplace</h6>
              </div>
            </div>
            <div className="col-span-2 text-right">
              <p>{formatPrice(salesProduct[3]?.amount) || 0}u</p>
            </div>
            <div className="col-span-2 text-right">
              <p>${formatPrice(salesProduct[3]?.income) || 0}</p>
            </div>
          </div>
          <div className="grid grid-cols-8 gap-2 w-full">
            <div className="col-span-4">
              <div className="flex items-center gap-2">
                <div className="w-[10px] h-[10px] bg-[#b501ef]"></div>
                <h6 className="text-[15px] text-black ">Rental</h6>
              </div>
            </div>
            <div className="col-span-2 text-right">
              <p>{formatPrice(salesProduct[4]?.amount) || 0}u</p>
            </div>
            <div className="col-span-2 text-right">
              <p>${formatPrice(salesProduct[4]?.income) || 0}</p>
            </div>
          </div>
          <Product salesProduct={salesProduct} income={income} />
        </div>
      </div>
    </div>
  );
}

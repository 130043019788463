import { useState, useEffect } from "react";
import WorkersChart from "./components/WorkersChart";
import SalesByRanking from "./components/SalesByRanking";
import moment from "moment";
import { BiLoaderCircle } from "react-icons/bi";
import CirculateStatusTable from "./components/CirculateStatusTable";
import ListBoxSelect from "./components/ListBoxSelect";
import SalesBySeller from "./components/SalesBySeller";

import {
  getTokenomicMarketplace,
  getSalesRankingByProduct,
  getSalesRankingByCoins,
  getSalesRankingBySeller,
} from "../../api";

const categoryList = [
  { title: "Category", value: "" },
  { title: "NFT Hero", value: "1" },
  { title: "NFT VPL", value: "2" },
  { title: "Gamer Station", value: "3" },
  { title: "Body Part", value: "4" },
  { title: "Mech Part", value: "5" },
  { title: "Equipment Card", value: "6" },
];



export default function Overview() {
  const [filterBy, setFilterBy] = useState("created_at");
  const [owner, setOwner] = useState("");
  const [purchaser, setPurchaser] = useState("");
  const [status, setStatus] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(categoryList[0]);
  const [salesList, setSalesList] = useState([]);
  const [sortBy, setSortBy] = useState("offer_date");
  const [sortDir, setSortDir] = useState("asc");
  const [page, setPage] = useState("1");
  const itemPage = useState("20")[0];
  


  const handlePageClick = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
  };
  
  const sortStatusTable = (sortVal) => {
    if (sortVal === sortBy) {
      sortDir === "asc" ? setSortDir("desc") : setSortDir("asc");
    } else {
      setSortDir("asc");
      setSortBy(sortVal);
    }
  };
  
  const statusList = [
    { title: "statuses", value: "" },
    { title: "ready for sale", value: "1" },
    { title: "locked for selling process", value: "9" },
    { title: "sold", value: "999" },
  ];
  
  const [from, setFrom] = useState(
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );
  
  const [to, setTo] = useState(moment().format("YYYY-MM-DD"));
  
  const fetchTokenomicSales = async () => {
    try {
      setLoader(true);
      let data = await getTokenomicMarketplace(
        filterBy,
        from,
        to,
        owner,
        purchaser,
        selectedCategory.value,
        status.value ?? "",
        page,
        itemPage,
        sortBy,
        sortDir,
      );
      setSalesList(data);

      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchTokenomicSales();
  }, [page, sortBy, sortDir]);

  useEffect(() => {
    fetchWorkersSummary();
    fetchSalesRankingByCoins();
    fetchSalesRankingBySell();
  }, []);
  
  
  const [workersSummary, setWorkersSummary] = useState([]);
  const [loader, setLoader] = useState(false);
  const [filterLoader, setFilterLoader] = useState(false);
  const [coinRanking, setCoinRanking] = useState([]);
  const [sellerRanking, setSellerRanking] = useState([]);


  const fetchWorkersSummary = async () => {
    try {
      let data = await getSalesRankingByProduct(
        filterBy,
        from,
        to,
        owner,
        purchaser,
        selectedCategory.value,
        status.value ?? "",
        );
      setWorkersSummary(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSalesRankingByCoins= async () => {
    try {
      let data = await getSalesRankingByCoins(
        filterBy,
        from,
        to,
        owner,
        purchaser,
        selectedCategory.value,
        status.value ?? "",
        );
        setCoinRanking(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSalesRankingBySell= async () => {
    try {
      let data = await getSalesRankingBySeller(
        filterBy,
        from,
        to,
        owner,
        purchaser,
        selectedCategory.value,
        status.value ?? "",
        );
        setSellerRanking(data);
    } catch (error) {
      console.error(error);
    }
  };



  const filter = async () => {
    setLoader(true);
    // await fetchAccountsTable();
    await fetchTokenomicSales();
    await fetchWorkersSummary();
    await fetchSalesRankingByCoins();
    await fetchSalesRankingBySell();
    setLoader(false);
  };

  return (
    <div className="flex flex-col items-center h-full p-6">
      <div className="p-6 rounded-[2px] border bg-white mb-6 w-full">
        <div className="flex items-center gap-5 mt-2">
          <div>
            <div className="flex items-center mb-2">
              <input
                className="relative mr-1 min-w-4 min-h-4 appearance-none rounded-full border-2 border-solid border-gray-400 before:pointer-events-none before:absolute before:h-3 before:w-3 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_10px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-3 after:w-3 after:rounded-full after:content-[''] checked:border-black checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.5rem] checked:after:w-[0.5rem] checked:after:rounded-full checked:after:border-black checked:after:bg-black checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_10px_rgba(0,0,0,0.6)]"
                type="radio"
                checked={filterBy === "created_at"}
                onClick={() => setFilterBy("created_at")}
                name="flexRadioDefault"
                id="bracket"
              />
              <label
                className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                htmlFor="bracket"
              >
                Offers created between
              </label>
            </div>
            <div className="flex items-center mb-2">
              <input
                className="relative mr-1 min-w-4 min-h-4  appearance-none rounded-full border-2 border-solid border-gray-400 before:pointer-events-none before:absolute before:h-3 before:w-3 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_10px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-3 after:w-3 after:rounded-full after:content-[''] checked:border-black checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.5rem] checked:after:w-[0.5rem] checked:after:rounded-full checked:after:border-black checked:after:bg-black checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_10px_rgba(0,0,0,0.6)]"
                type="radio"
                checked={filterBy === "purchased_at"}
                onClick={() => setFilterBy("purchased_at")}
                name="flexRadioDefault"
                id="login"
              />
              <label
                className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                htmlFor="login"
              >
                Purchases between
              </label>
            </div>
          </div>
          <div className="flex items-center gap-5 mt-2">
            <div className="relative">
              <input
                type="text"
                value={from}
                onChange={(e) => setFrom(e.target.value)}
                className="h-[40px] px-3 border border-[#E8E7E4] placeholder:text-white"
              />
              <input
                type="date"
                value={from}
                onChange={(e) => setFrom(e.target.value)}
                className="absolute inset-0 opacity-0 z-1"
              />
            </div>
            <label>and</label>
            <div className="relative">
              <input
                type="text"
                value={to}
                onChange={(e) => setTo(e.target.value)}
                className="h-[40px] px-3 border border-[#E8E7E4] placeholder:text-white"
              />
              <input
                type="date"
                value={to}
                onChange={(e) => setTo(e.target.value)}
                className="absolute inset-0 opacity-0 z-1"
              />
            </div>
            <div className="w-[140px]">
              <input
                type="text"
                value={owner}
                onChange={(e) => setOwner(e.target.value)}
                placeholder="Owner"
                className="h-[40px] px-3 border w-full outline-0 border-[#E8E7E4] placeholder:text-black"
              />
            </div>
            <div className="w-[140px]">
              <input
                type="text"
                value={purchaser}
                onChange={(e) => setPurchaser(e.target.value)}
                placeholder="Purchaser"
                className="h-[40px] px-3 border w-full outline-0 border-[#E8E7E4] placeholder:text-black"
              />
            </div>
            <div className="w-[170px]">
              <ListBoxSelect
                options={statusList}
                selected={status}
                setSelected={setStatus}
                placeholder={"Status"}
              />
            </div>
            <div className="w-[160px]">
              <ListBoxSelect
                options={categoryList}
                selected={selectedCategory}
                setSelected={setSelectedCategory}
                placeholder={"Category"}
              />
            </div>
            <button
              onClick={filter}
              className="h-[40px] px-8 text-[16px] text-white flex items-center justify-center bg-[#0080A6]"
            >
              {loader || filterLoader ? (
                <BiLoaderCircle
                  size={22}
                  className="animate-[spin_3s_linear_infinite]"
                />
              ) : (
                "Reload"
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-5 w-full">
        <div className="col-span-9">
          <CirculateStatusTable
            list={salesList?.content}
            loader={loader}
            handlePageClick={handlePageClick}
            sortStatusTable={sortStatusTable}
            pagination={salesList?.pagination}
            sortBy={sortBy}
            sortDir={sortDir}
          />
        </div>
        <div className="col-span-3 p-6 bg-white">
          <WorkersChart
            workersSummary={workersSummary}
          />
          <SalesByRanking
            workersSummary={coinRanking}
          />
          <SalesBySeller 
            workersSummary={sellerRanking}
          />
        </div>
      </div>
    </div>
  );
}
